import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next"
import LeadContact from "../LeadContact";
import Settings from "@/settings";
import { FC } from "react";
import { LeadContactSubmitData } from "@/types/models/services/leads";


type LeadContactFormData = LeadContactSubmitData;

interface IPowerCalculatorResult {
	maxPower: number;
	leadSubmit: (personalData: LeadContactFormData) => void;
}

const Result: FC<IPowerCalculatorResult> = ({
	maxPower, leadSubmit
}) => {
	const { i18n, t } = useTranslation();
	
	return <Box className="result">
		{/* {!helpRequested && ( */}
			<div>
				<Typography variant="h4" align="center">
					{t('common:text.lead_power_optimal')}
				</Typography>
				<div className="value">
					{maxPower.toLocaleString(i18n.language)} kW
				</div>
				<div>
					<Typography variant="body2" align="center">
						{t('common:text.lead_power_text1')}
					</Typography>
					<ul>
						<li>
							<Typography variant="body2">
								{t('common:text.lead_power_text2')}
							</Typography>
						</li>
						<li>
							<Typography variant="body2">
								{t('common:text.lead_power_text3')}
							</Typography>
						</li>
						<li>
							<Typography variant="body2">
								{t('common:text.lead_power_text4')}
							</Typography>
						</li>
					</ul>
					<p>
						{t('common:text.lead_power_essential')}
					</p>
					<p>
						{t('common:text.lead_power_aclariment_canvi_potencia')}
					</p>
					<p>
						{t('common:text.lead_power_aclariment', 
							{ companyName: Settings.organization.name }
						)}
					</p>
				</div>
			</div>
			<LeadContact
				changePowerHelp={true}
				leadSubmit={leadSubmit}
			/>
	</Box>
}

export default Result;