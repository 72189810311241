import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Paper, Box, Typography } from "@mui/material"

import {colors} from "../../constants";
import {hex2rgba} from "../../utils/misc";

export class SmartTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            table: props.data,
        };
    }

    render() {

        const headers = this.props.header.map((h) => <th key={h.title}>{h.title}</th>);
        const content = this.props.data.map((i, idx) => {
            const color = idx % 2 ? hex2rgba(colors[1], 0.4) : null;
            const row = i.map((el, idx) =>{
             return <td key={idx} style={color && { backgroundColor: color }}>
                    <div className="label">{this.props.header[idx].title}</div> {el}
                </td>
            });
            return (<tr key={idx+"innerTable"}>{row}</tr>);
        });

        return  (
            <Paper>
                {this.props.title &&
                    <Box>
                        <Typography variant={'h6'}>{this.props.title}</Typography>
                    </Box>
                }
                <table className="smart">
                    <thead>
                        <tr>
                            {headers}
                        </tr>
                    </thead>
                    <tbody>
                        {content}
                    </tbody>
                </table>
            </Paper>
        );
    }
}

SmartTable.propTypes = {
    header: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    title: PropTypes.string,
};
