import React from 'react';
import { connect } from 'react-redux';
import { setCessionSIPS } from "../../../actions/contracts";
import { data_create_api_resource } from '../../../utils/http_functions';
import ErrorMessage from "../../ErrorMessage";
import SignatureProcess from "../../SignatureProcess";
import _ from 'lodash';
import { i18n } from "../../../config";

import {
    CircularProgress, Button, FormHelperText,
    Select, InputLabel, FormControl, MenuItem,
    Dialog, DialogActions, DialogContent, DialogTitle
} from '@mui/material';


const mapStateToPros = (state) => ({
   token: state.auth.token,
});

class ContractNoCesionDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            status: props.contract.no_cession_sips,
            errors: {},
            noCesionSelection: 'all',
            submitting: false,
            waiting_for_signature: false
        };
    }

    handleClose = () => {
        this.props.changeDialogSipsOpen(false)
    };

    handleSend = async () => {
        let status = 'requested';
        let dialogOpen = this.state;
        let waiting_for_signature = false;
        this.setState({ submitting: true });
        try {
            const response = await data_create_api_resource(
                this.props.token,
                `contracts/${this.props.contract.name}/no_cession_sips/`,
                {noCesionSelection: this.state.noCesionSelection},
            );
            const  result  = response.data;
            waiting_for_signature = result.waiting_for_signature;
            if (waiting_for_signature) {
                dialogOpen = true;
            }
        } catch(e) {
            status = 'unactive';
            dialogOpen = true;
            if (e.response && e.response.status === 400 && e.response.data.errors) {
                this.setState({ errors: e.response.data.errors });
            }
        } finally {
            this.setState({
                dialogOpen,
                status,
                submitting: false,
                waiting_for_signature
            });
            this.props.setCessionSIPS(status);
        }
    };

    handleChangeNoCesionSelection = (event, index, value) => {
        this.setState({
            noCesionSelection: event.target.value,
        });
    };

    render() {
        const { errors } = this.state;
        let actions = [];

        if (_.isEmpty(this.state.errors) && !this.state.waiting_for_signature) {
            actions = [
                <Button
                    key={'close'}
                    color={'primary'}
                    variant={'contained'}
                    onClick={this.handleClose}
                >
                    {i18n.t('common:text.sips_cancel')}
                </Button>,
                <Button
                    key={'send'}
                    color={'primary'}
                    variant={'contained'}
                    disabled={this.state.submitting}
                    onClick={this.handleSend}
                >
                    {this.state.submitting ? <CircularProgress size={14} /> : null}
                    {i18n.t('common:text.sips_send')}
                </Button>,
            ];
        } else {
            actions = [
                <Button
                    color={'primary'}
                    variant={'contained'}
                    onClick={this.handleClose}
                >
                    {i18n.t('common:text.sips_close')}
                </Button>,
            ];
        }

        if (!this.state.status) {
            return <div></div>;
        }

        return (
            <div>
                <Dialog
                    open={this.props.open}
                    disableBackdropClick={true}
                    onClose={this.handleClose}
                >
                    <DialogTitle>
                        {i18n.t('common:text.sips_title')}
                    </DialogTitle>
                    <DialogContent>
                        {_.isEmpty(this.state.errors) && !this.state.waiting_for_signature &&
                            <div>
                                {i18n.t('common:text.sips_description')}
                                <FormControl>
                                    <InputLabel shrink htmlFor="">
                                        {i18n.t('common:text.sips_contracts')}
                                    </InputLabel>
                                    <Select
                                        onChange={this.handleChangeNoCesionSelection}
                                        value={this.state.noCesionSelection}
                                        error={!!(errors.noCesionSelection)}
                                    >
                                        <MenuItem key="all" value="all">{i18n.t('common:text.sips_all')}</MenuItem>
                                        <MenuItem key="actual" value="actual">{i18n.t('common:text.sips_current')}</MenuItem>
                                    </Select>
                                </FormControl>
                                {!!(errors.noCesionSelection) &&
                                    <FormHelperText error={true}>
                                        {errors.noCesionSelection}
                                    </FormHelperText>
                                }
                            </div>
                        }
                        {!_.isEmpty(this.state.errors) &&
                            <ErrorMessage />
                        }
                        {this.state.waiting_for_signature &&
                            <SignatureProcess />
                        }
                    </DialogContent>
                    <DialogActions>
                        {actions}
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default connect(mapStateToPros, { setCessionSIPS })(ContractNoCesionDialog);
