import React from "react";
import FlexRow from "../FlexRow";
import { TextField, FormHelperText } from "@mui/material"

const CustomTextField = ({ input, label, readOnly = false, meta, ...rest }) => {
  return (
    <FlexRow style={{ flexWrap: "wrap", flexDirection: "column" }}>
      <TextField
        {...input}
        label={label}
        disabled={readOnly}
        {...rest}
        error={!!(meta.error) && meta.touched }
      />
      {!!(meta.error) && meta.touched &&
        <FormHelperText error={true}>
          {meta.error}
        </FormHelperText>
      }
    </FlexRow>
  );
};

export default CustomTextField;
