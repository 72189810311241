import React from "react";
import { i18n } from "../../config";

import { CircularProgress, Box, Typography } from "@mui/material";


export const LoadingAnimation = () => {
  return (
    <Box sx={{
      textAlign: "center",
      width: "100%",
    }}>
      <Typography variant={"h5"} mb={2}>
        {i18n.t('common:text.loading')}
      </Typography>
      <CircularProgress size={80} thickness={3} />
    </Box>
  );
};
