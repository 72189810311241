import useAuth from "@/hooks/useAuth";
import { RootState } from "@/store";
import { Button } from "@gisce/oficina-virtual-components";
import { ArrowOutward } from "@mui/icons-material";
import { 
  Box,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle 
} from "@mui/material"
import { t } from "i18next";
import { useSelector } from "react-redux";

export const AuthTimeout = () => {
  const { isTokenExpired, logout } = useAuth();
	const { isAuthenticated } = useSelector((store: RootState) => store.auth);

	return <Dialog
    open={isAuthenticated && isTokenExpired}
  >
    <Box sx={{padding: 2}}>

      <DialogTitle>{t("common:text.login_view_session_expired")}</DialogTitle>
      
      <DialogContent>
        <DialogContentText>
          {t("common:text.login_view_session_expired_detail")}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button 
          color="primary" 
          variant="contained" 
          onClick={() => logout()}
          startIcon={<ArrowOutward />}
        >
          {t("common:text.login_view_button_exit")}
        </Button>
      </DialogActions>

    </Box>

  </Dialog>
}