import { data_fetch_api_resource } from "../../utils/http_functions";
import { INavigationLinkInfo, IResponseServerElement, IServerElement } from "./types";

const DINAMIC_ROUTES_PATH = "ov_customize/html_dinamic_routes";
const DINAMIC_ELEMENTS_PATH = "ov_customize/html_dinamic_elements";

const useServerElements = () => {
  const groupByGroupName = (array: IServerElement[]) => {
    /* Returns a dictionary {groupName.ToUpperCase: array sorted by priority}*/
    const groups: {[key:string]: IServerElement[]} = {};

    // Iterate through the original array
    for (const item of array) {
      const groupName = item.groupName.toUpperCase();

      // Create a new array for the group if it doesn't exist
      if (!groups[groupName]) {
        groups[groupName] = [];
      }

      // Push the item into its corresponding group array
      groups[groupName].push(item);
    }

    // Convert groups object to an array of arrays, sort within each group
    Object.values(groups).forEach((group) => group.sort((a, b) => a.priority - b.priority));
    return groups;
  }

  const secureRoutePath = (routePath: INavigationLinkInfo["routePath"]) => {
    // Replace all '/' except for the one at the beginning with '-'
    const modifiedString = routePath.replace(/(?!^)\//g, '-');
    
    // Ensure the string starts with '/'
    return modifiedString.startsWith('/') ? modifiedString : '/' + modifiedString;
  }

  const fetchServerActions = async () => {
    /*Returns the server actions BUT will return a list if the route_path not duplicated*/
    return new Promise(async (resolve, reject) => {

      const response = await data_fetch_api_resource(null, DINAMIC_ROUTES_PATH, 2);
      const routes = [];
      if (response?.status === 200 && response?.data) {
        const uniqueRoutes: {[key: string]: boolean} = {};
        for (let action of response.data) {
          const securedRoutePath = secureRoutePath(action.route_path);
          if (securedRoutePath && !uniqueRoutes[securedRoutePath]) {
            action.route_path = securedRoutePath;
            routes.push({
              id: action.id,
              routePath: action.route_path,
              btnDescription: action.btn_description,
              btnTitle: action.btn_title
            });
            uniqueRoutes[securedRoutePath] = true;
          }
        }
      }
      resolve(routes);
    })
  };

  const fetchServerElements = async (pageId: IServerElement["id"]) => {
    /*Returns the server actions BUT will return a list if the route_path not duplicated*/
    return new Promise(async (resolve, reject) => {

      const response = await data_fetch_api_resource(null, `${DINAMIC_ELEMENTS_PATH}/${pageId}`, 2);
      let elements = [];
      if (response?.status === 200 && response?.data) {
        console.log("response", response);
        elements = response.data.map((e: IResponseServerElement) => {
          return {
            blockHeight: e.block_height,
            blockWidth: e.block_width,
            groupName: e.group_name,
            htmlBlock: e.html_block,
            id: e.id,
            name: e.name,
            pageId: e.page_id,
            priority: e.priority
          }
        });
      }
      resolve(elements);
    })
  };

  return {
    fetchServerActions,
    fetchServerElements,
    groupByGroupName
  }
}

export default useServerElements;
