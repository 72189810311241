import {
  MoveBatteryDistributionPreview,
} from "../../BatteryDistributionPreview";
import useMoveVirtualBatteryContext from "../useMoveVirtualBatteryContext";

export const MoveVirtualBatteryStep3 = ({ nextStepCallback }) => {
  const {
    selectedBatteryId,
    newPercentage,
  } = useMoveVirtualBatteryContext();

  return (
    <>
      <MoveBatteryDistributionPreview newPercentage={newPercentage} selectedBatteryId={selectedBatteryId}/>
    </>
  );
};

export default MoveVirtualBatteryStep3;
