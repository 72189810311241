import { useTranslation } from "react-i18next";
import Filter from "../Filter/Filter";
import { useSelector } from "react-redux";
import Settings from "../../settings";


const InvoicesFilter = ({ initialFilters, applySearchFilter, initialItemsPage, type }) => {
  const { t } = useTranslation();
  const loaded = useSelector((state) => state.invoices[type].loaded);
  const data = useSelector((state) => state.invoices[type].filters.data);


  const invoiceCategories = [
    { type: "text",         category: "name",     title: t("common:text.filter_invoices_invoice"),      renderText: t("common:text.filter_invoices_invoice"), helper: "F123456", searchField: "number" },
    { type: "autocomplete", category: "contract", title: t("common:text.filter_invoices_contract"),     renderText: t("common:text.filter_invoices_contract"), helper: "12345", additionalData: data?.contracts ?? [], searchField: "polissa_id.name" },
    { type: "date",         category: "date",     title: t("common:text.filter_invoices_date_title"),   renderText: t("common:text.filter_invoices_date_renderText"), searchField: "date_invoice" },
    { type: "date",         category: "period",   title: t("common:text.filter_invoices_period_title"), renderText: t("common:text.filter_invoices_period_renderText"), searchField: ["data_inici", "data_final"] },
    { type: "autocomplete", category: "cups",     title: "CUPS",                                        renderText: "CUPS", additionalData: data?.cups ?? [], searchField: "cups_id.name" },
    { type: "autocomplete", category: "address",  title: t("common:text.filter_invoices_address"),      renderText: t("common:text.filter_invoices_address"), additionalData: data?.addresses ?? [], searchField: "cups_id.direccio" },
    { type: "numeric",      category: "import",   title: t("common:text.filter_invoices_import_title"), renderText: t("common:text.filter_invoices_import_renderText"), units: "€", searchField: "amount_total" },
    { type: "numeric",      category: "pending",   title: t("common:text.filter_invoices_pending_title"), renderText: t("common:text.filter_invoices_pending_renderText"), units: "€", searchField: "residual" },
    // { type: "numeric",      category: "energy",   title: t("common:text.filter_invoices_energy_title"), renderText: t("common:text.filter_invoices_energy_renderText"), units: "kWh", searchField: "energia_kwh" },
    { type: "select",       category: "paid",     title: t("common:text.filter_invoices_state"),        renderText: t("common:text.filter_invoices_state"), searchField: "state", additionalData: [
        {label: t("common:text.invoice_paid"), search: "paid"}, {label: t("common:text.invoice_not_paid"), search: "open"}
      ] 
    }
  ]

  return (
    <Filter
      categories={invoiceCategories}
      applySearchFilter={applySearchFilter}
      disableApply={!loaded}
      initialFilters={initialFilters}
      initialItemsPage={initialItemsPage ?? Settings?.invoices?.itemsPerPage ?? 100}
    />
  )
}

export default InvoicesFilter;
