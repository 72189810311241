import { i18n } from "../config/i18n";
import Settings from "../settings";

export const locale_code = i18n.t('common:locale.code'); // TODO We should remove this
export const login_url = i18n.t('common:url.login');
export const connect_url = i18n.t('common:url.connect');
export const home_url = i18n.t(`common:url.${Settings.homepage ?? "invoices"}`);

// TODO: Això es deu poder fer millor...
export const contraction_url = Settings.contractation?.dual?.enabled ? 
  'common:url.dualContractation' 
: 
  (Settings.contractation?.electricity?.enabled ?
    'common:url.electricityContractation' 
  :
    (Settings.contractation?.gas?.enabled ?
      'common:url.gasContractation' :
      'common:url.invoices'
    )
  );