import { useEffect, useState } from 'react';

import Settings from "../../../settings";
import { generateColorList } from "../../../utils/colors";

export const useChart = ({
  data, 
  components, 
  compoundBars = false,
  ignoreMaxContracts = false
}) => {
  const [fields, setFields] = useState({});
  const [dataAdapted, setDataAdapted] = useState([]);
  const [colors, setColors] = useState([]);

  const toggleBarVisibility = (dataKey) => {
    setFields((prevState)=> {
      return {...prevState, [dataKey]: !prevState[dataKey]};
    });
  };

  const getColors = () => {
    const startingColor = "#61A2A5"; // TODO: Agafa el color primary, posa una prop que es digui bar mainColor per el component
    return generateColorList(startingColor, Object.keys(fields).length);
  };
  
  const splitData = (data) => {
    return Object.entries(data).map(entry => {

      let result = {};
      if (!ignoreMaxContracts && Object.keys(entry).length > (Settings?.invoices?.chart?.maxContracts ?? 10)) {
        result = {
          name: entry.name,
          total: entry.total,
        }
      } else {
        result = entry;
      }

      return result;
    });
  }

  const updateData = () => {
    if (data.length > 0 && components) {
      let newFields = {};
      Object.keys(components).sort().map(component => {
        newFields[component] = true;
      });
      newFields["total"] = true;
      setFields(newFields);

      let newDataAdapted = [];
      if (data) {
        newDataAdapted = data.map((o) => ({...o}));
        newDataAdapted = compoundBars ? data : splitData(newDataAdapted);
      }

      setDataAdapted(newDataAdapted);
    }
  }

  const updateTotal = () => {
    // Recalculate 'Total' line
    const newDataAdapted = data?.map((elm, idx) => {
      let newTotal = 0;
      Object.entries(elm).map((component) => {
        let componentName = component[0];
        //Check if field exists and is not disabled
        if (Object.keys(fields).includes(componentName) && fields[componentName]) {
          if (componentName.includes('exportada')) {
            newTotal -= component[1];
          }
          if (componentName !== 'name' && componentName !== 'total' && !componentName.includes('reactiva')) {
            newTotal += component[1];
          }
        }
      });

      return {...elm, "total": String(Math.round((newTotal + Number.EPSILON) * 100) / 100) }; // 
    });

    setDataAdapted(newDataAdapted);
  };

  useEffect(() => {
    setColors(getColors());
  }, [dataAdapted]);

  useEffect(() => {
    updateData();
  }, [data, components]);

  useEffect(() => {
    updateTotal();
  }, [fields]);
  
  return { colors, fields, dataAdapted, toggleBarVisibility };
}