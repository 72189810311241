import { useState } from 'react';

import ContractsListElectricity from './ContractsListElectricity';
import ContractsListGas from './ContractsListGas';
import { features } from "../../settings";

import { Tabs, Tab, Box } from '@mui/material';
import GasIcon from '../../assets/GasIcon';
import LightIcon from '../../assets/LightIcon';
import { useTranslation } from 'react-i18next';

const ContractsTabs = () => {
  const { t } = useTranslation();
  const [contractType, setContractType] = useState("electricity");

  const handleChangeContractType = (event, value) => {
    setContractType(value);
  };

  return <Box sx={{ width: '100%', marginTop: -2 }}>
    {features.displayTypes.length > 1 ? <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={contractType} onChange={handleChangeContractType}>
          {features.displayTypes.includes('electricity') && <Tab label={t("common:text.contracts_tab_title_electricty")} value="electricity" icon={<LightIcon />}/>}
          {features.displayTypes.includes('gas') && <Tab label={t("common:text.contracts_tab_title_gas")} value="gas" icon={<GasIcon />}/>}
        </Tabs>
      </Box>
      <Box sx={{mt: 2}}>
        {features.displayTypes.includes('electricity') && <div hidden={contractType !== 'electricity'}><ContractsListElectricity/></div>}
        {features.displayTypes.includes('gas') && <div hidden={contractType !== 'gas'}><ContractsListGas/></div>}    
      </Box>
    </> : <>
      {features.displayTypes[0] === 'electricity' && <ContractsListElectricity/>}
      {features.displayTypes[0] === 'gas' && <ContractsListGas/>}
    </>}
  </Box>;

}

export default ContractsTabs;