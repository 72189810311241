// import { TConsumptionGenerationItem, TGetConsumptionGenerationParams } from "../types";

import { FetchCouponsResponse } from "@/types/models/services/coupons";
import API from "./api";
import useI18n from "@/hooks/useI18n";
import { IPublicityBannerProps } from "@gisce/oficina-virtual-components";
import { isCoupon } from "@/types/models/guards";
import { useTranslation } from "react-i18next";

const ENDPOINT = "v1"

// static getUsers(): Promise<User[]> {
// 	return new Promise<User[]>((resolve, reject) => {
// 		API.get(`${ENDPOINT}/users`)
// 			.then((value: User[]) => {
// 				const formataatexmep = values.map()
// 				resolve(formataatexmep)
// 			}).catch((error) => {
// 				reject("oh no")
// 				reject(error)
// 			})
// 	})
// }

const fetchCoupons = (lang: string) => {

	const parseCoupon = (coupon: FetchCouponsResponse[0]) => {
		const parsedCoupon: IPublicityBannerProps["dataProps"] = {
			shareURL: coupon.code_url,
			title: coupon.campaign.name,
			description: coupon.campaign.description,
			infoURL: coupon.campaign.url,
		}
		return parsedCoupon;
	}

	return new Promise<IPublicityBannerProps["dataProps"][]>(async (resolve, reject) => {
		API.get<FetchCouponsResponse>(
			`${ENDPOINT}/user/coupons`,
			'',
			{
				params: {
					lang
				}
			}
		)
		.then(res => {
			// Typeguard
			try {
				resolve(
					res.map(coupon => !isCoupon(coupon) ? parseCoupon(coupon) : coupon)
				);
			} catch (err) {
				reject(err);
			}
		})
		.catch(err => {
			reject(err);
		})
	})
}

export default {
	fetchCoupons
};