import { createContext, MutableRefObject, useRef, useState } from "react";

type NavigationItemsContextProps = {
	currentPath: string;
	currentPathProtection: "private" | "public" | "unprotected";
	setCurrentPath: (path: string) => void;
	setCurrentPathProtection: (path: "private" | "public" | "unprotected") => void;
};

export const NavigationItemsContext = createContext<null | NavigationItemsContextProps>(null);

type NavigationItemsContextProviderProps = {
	children: React.ReactNode;
};

export const NavigationItemsContextProvider = ({
	children
}: NavigationItemsContextProviderProps) => {
	const [currentPath, setCurrentPath] = useState("/");
	const [currentPathProtection, setCurrentPathProtection] = useState<NavigationItemsContextProps["currentPathProtection"]>("public");

	const handleSetCurrentPath = (path: string) => {
		setCurrentPath(path);
	};

	const handleSetCurrentPathProtection = (protection: NavigationItemsContextProps["currentPathProtection"]) => {
		setCurrentPathProtection(protection);
	};

	return <NavigationItemsContext.Provider value={{
		currentPath: currentPath,
		currentPathProtection: currentPathProtection,
		setCurrentPath: handleSetCurrentPath,
		setCurrentPathProtection: handleSetCurrentPathProtection
	}}>
		{children}
	</NavigationItemsContext.Provider>
}