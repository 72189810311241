import { UserProfileMenu as UserProfileMenuComponent, UserInfo } from '@gisce/oficina-virtual-components';
import useAuth from '../../hooks/useAuth';

import {
	VpnKey,
	Email,
	Logout
} from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Settings from '@/settings';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';

export const UserProfileMenu = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const profile = useSelector((state: RootState) => state.profile);
  const { logout } = useAuth();

	const navigateChangePassword = () => {
		if (Settings.features?.oidc) {
			window.location.href = Settings.oidc.changePasswordUrl;
		} else {
			navigate(t('common:url.changePassword'))
		}
	}

  return (
    <UserProfileMenuComponent 
      headerComponent={profile?.data && <UserInfo 
        name={profile.data.name}
        email={profile.data.email}
        avatarPosition="left" 
      />} 
      actions={[
        {
          label: t("common:text.change_password_view_title"),
          icon: <VpnKey />,
          onClick: () => navigateChangePassword()
        },
        // {
        //   label: 'Manage CRMs',
        //   icon: <Email />,
        //   onClick: () => console.log("Navigate to CRMs")
        // },
        {
          label: t("common:text.logout_title"),
          icon: <Logout />,
          onClick: () => logout(),
        }
      ]}
    />
  );
}