import { useEffect } from "react";
import { useSelector } from "react-redux";
import { generateHash } from '../utils/misc';
import Settings from '../settings';
import cookiesUtils from "../utils/cookies";
import useAuth from "../hooks/useAuth";
import Cookies from "js-cookie";
import useConnectQueryParams from "@/hooks/useConnectQueryParams";
import { RootState } from "@/store";
import { useNavigationItemsContext } from "./NavigationMenu/useNavigationItemsContext";

interface IAuthenticationProvider {
  children: React.ReactNode;
}

// This provider checks the url and the current cookies for authentication
export const AuthenticationProvider = ({
  children
}: IAuthenticationProvider) => {
  const { 
    token, clientId, secret, code, state, redirect, promocode
  } = useConnectQueryParams();
  const auth = useAuth();
  const isAuthenticated = useSelector((store: RootState) => store.auth.isAuthenticated);
  const { currentPathProtection } = useNavigationItemsContext();
  const cookiesToken = Cookies.get("token");

  useEffect(() => {
    if (!isAuthenticated) {

      // Token
      if ( token ) {
        auth.login({type: "Token", authParams: {token}})
      // ClientID & Secret
      } else if ( clientId && secret ) {
        cookiesUtils.acceptConsentCookies();
        auth.login({type: "ClientID", authParams: {clientId, secret}});
      // Already logged in
      } else if ( !isAuthenticated && cookiesToken ) {
        auth.loginRefresh(cookiesToken);
      // Code, Nonce & Secret
      } else if ( !isAuthenticated && Settings.features?.oidc && (!code || !state) && currentPathProtection === "private" ) {
        cookiesUtils.acceptConsentCookies();
        auth.login({type: "OpenIDRedirect", authParams: {redirect, promocode}});
      } else if ( Settings.features?.oidc && code && state ) {
        const nonce = generateHash(Cookies.get('openid_nonce')) as string | null;
        if (nonce) {
          auth.login({type: "OpenIDSubmit", authParams: {code, state, nonce}});
        } else {
          auth.loginNotMade({statusCode: 403, statusType: "error", statusText: "Nonce not present"});
        }

      // Not logged in
      } else {
        auth.loginNotMade();
      }
    }
    
  }, [currentPathProtection, isAuthenticated]);

  return children
}