import { Box, Card, CardContent, Typography } from "@mui/material";
import { i18n } from "../../config";
import AttachmentsList from "../Attachments/AttachmentsList";


// Expected data: attachmentsList: {category_code, category_name, created_date, filename, id, name}[]
const AttachmentsCard = ({attachmentsList}) => {
  attachmentsList = attachmentsList ?? [];

  const attachments = {};
  for (let attch of attachmentsList) {
    if (!attachments.hasOwnProperty(attch.category_name))
      attachments[attch.category_name] = [];
    attachments[attch.category_name].push(attch);
  }
  
  const attachmentsQty = Object.values(attachments).reduce((acumulator, currentValue) => acumulator + currentValue.length, 0);


  return (
    <Card>
      <CardContent>
        <Box>
          <Typography variant="h5">
            { i18n.t('common:text.attachments_title') } ({attachmentsQty})
          </Typography>
        </Box>
        <Box>
          { 
          Object.keys(attachments).map((category) => (
            <Box marginBottom={2} key={category}>
              <AttachmentsList title={category} 
                attachments={attachments[category]} 
                downloadURL="contract/download_attachment/:attachmentID"/>
            </Box>
          )) 
          }
        </Box>
      </CardContent>
    </Card>
  )
}


export default AttachmentsCard;