import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import './style/index.scss';
import App from './App';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <HelmetProvider>
      <ScopedCssBaseline>
        <App />
      </ScopedCssBaseline>
    </HelmetProvider>
  </React.StrictMode>
)