const ibansReducer = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_IBANS':
      return action.payload;
    case 'RECEIVE_IBANS':
      return action.payload;
    case 'SET_IBAN':
      return [action.payload, ...state];
    default:
      return state;
  }
};

export default ibansReducer;
