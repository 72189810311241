import {
  Button,
  CircularProgress
} from '@mui/material';
import { Create } from '@mui/icons-material';
import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { i18n } from "../../../config";
import ChangePercentageVirtualBatteryDialog from './ChangePercentageVirtualBatteryDialog';


const ChangePercentageVirtualBatteryDialogOpenButton = ({bateriaVirtual}) => {
  const sending = useSelector((state) => state.bateriaVirtual.waitingChangeRequest);
  const [ dialogOpen, setDialogOpen ] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  return (
    <div>
      <span>
        <Button variant={'contained'} color={'primary'} onClick={handleDialogOpen}>
          {(sending) ? <CircularProgress color='secondary' style={{ marginRight: 10 }} size={25} /> : <Create style={{marginRight: 10}}/>}
          { i18n.t('common:text.virtual_battery_modify_percentage') }
        </Button>
      </span>
        <ChangePercentageVirtualBatteryDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          bateriaVirtual={bateriaVirtual}
          />
    </div>
  );

}


export default ChangePercentageVirtualBatteryDialogOpenButton;
