import { 
	REGISTER_USER_REQUEST, 
	REGISTER_USER_SUCCESS, 
	REGISTER_USER_FAILURE
} from "@/constants";
import { 
	create_user,
	define_token, 
	undefine_token 
} from "@/utils/http_functions";
import { Dispatch } from "redux";

// TODO: Aquest arxiu no es crida

export const registerUserRequest = () => {
	return {
			type: REGISTER_USER_REQUEST,
	};
}

export const registerUserSuccess = (token: string) => {
	define_token(token);
	return {
			type: REGISTER_USER_SUCCESS,
			payload: {
					token,
			},
	};
}

export const registerUserFailure = ({statusCode = 403, statusText = '', statusType = "error"}) => {
		undefine_token();
		return {
				type: REGISTER_USER_FAILURE,
				payload: {
						statusCode,
						statusText,
						statusType
				},
		};
}

export const registerUser = (email: string, password: string) => {
	return (dispatch: Dispatch) => {
			dispatch(registerUserRequest());
			return create_user(email, password)
				.then(({data}) => {
						try {
								dispatch(registerUserSuccess(data.token));
						} catch (e) {
								dispatch(registerUserFailure({}));
						}
				})
				.catch(error => {
						dispatch(registerUserFailure({
								statusText: error.response.data.message,
						}));
				});
	};
}
