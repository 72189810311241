import React from "react";
import LegacyAddressField, { validarReferenciaCatastral } from "../Address";

const AddressField = ({ input, meta, ...rest }) => (
  <LegacyAddressField
    {...input}
    {...rest}
    errors={meta.touched ? meta.error : {}}
  />
);

export default AddressField;
export { validarReferenciaCatastral };