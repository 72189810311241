import _ from "lodash";
import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { data_fetch_api_resource } from "../../../utils/http_functions";
import { dayjs } from "../../../config";
import { regional_settings } from "../../../constants"

import { CircularProgress, Grid } from "@mui/material"

import { BarChart, AreaChart } from "../../Chart/v1";
import DateSelector from "./../DateSelector";

const defaults = {
  type: 'monthly',
  startDate: dayjs().date(1),
  endDate: dayjs().month(dayjs().month()+1).date(0)
};

const CCHChart = ({contractId}) => {
  const { t } = useTranslation();
  const token = useSelector((state) => state.auth.token);

  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState(null);
  const [dates, setDates] = useState({...defaults});

  useEffect(() => {
    fetchChartSpec();
  }, [dates, contractId]);

  const handleDatesChange = (newDates) => {
    setDates(newDates);
  }

  const fetchChartSpec = () => {
    const spec_url = `contracts/${contractId}/cch/chart/${dates.type}/` +
      (dates.type !== 'monthly' ? `?start=${dates.startDate.toISOString()}&end=${dates.endDate.toISOString()}` : '');

    setLoading(true);
    data_fetch_api_resource(token, spec_url, 2)
      .then((response) => {
        if (response.status === 200 && !_.isEmpty(response.data)) {
          setChartData(parseChartData(response.data));
        } else {
          setChartData(null);
        }
      }).finally(() => {
        setLoading(false);
      });
  }

  const parseChartData = (contracts) => {
    //{date: 'yyyy-MM-dd', period: String, activa: Double, reactiva: Double, exportada: Double}

    let data = [];
    let periods = [];
    let components = {};
    const energyTypes = [
      { devName: 'activa', renderName: t('common:text.contract_cch_active') },
      { devName: 'exportada', renderName: t('common:text.contract_cch_exported') },
      { devName: 'reactiva', renderName: t('common:text.contract_cch_reactive') },
    ];
    let renderedEnergyTypes = [];
    const number_of_months = 12;

    const current_year = dayjs().year();
    let init_year = current_year;
    //find the earliest year
    contracts.forEach((contract) => {
      let contract_year = dayjs(contract.date).year();

      if (contract_year < init_year) {
        init_year = contract_year;
      }
    })

    if (dates.type === 'monthly') {
      let energy_per_year = {};
      for (let year = init_year; year <= current_year; year++) {
        energy_per_year[year] = [];

        // Assign the 12 months
        for (let i = 0; i < number_of_months; i++) {
          energy_per_year[year].push({ 'name': i, 'total': 0 });
        }
      }

      contracts.forEach((contract) => {
        const end_date = dayjs(contract.date);

        // Extract the date
        const month = end_date.month();
        const year = end_date.year();

        //const energy = (parseFloat(contract.consumption));

        let energy = {};

        energyTypes.forEach(energyType => {
          if (contract[energyType.devName] && contract[energyType.devName] > 0) {
            energy[energyType.renderName] = contract[energyType.devName];
            !renderedEnergyTypes.includes(energyType.renderName) && renderedEnergyTypes.push(energyType.renderName);
          }
        });

        const period = contract.period;

        if (period && !periods.includes(period)) {
          //make sure all periods are saved for the component var (used in the graph legend)
          periods.push(period);
        }

        let titlePeriod = (period ?? '');
        // Set the energy and amount


        for (const [energyName, energyValue] of Object.entries(energy)) {
          energy_per_year[year][month][energyName + titlePeriod] = energyValue;
        }

        // Override title by default by shorted month and the year
        energy_per_year[year][month]['name'] = `${end_date.format("MMM")}'${end_date.format("YY")}`;
      });

      if (periods.length > 0) {
        periods.forEach((period) => {
          renderedEnergyTypes.forEach(
            energyType => components[energyType + period] = { 'title': energyType + period }
          );
        });
      } else {
        renderedEnergyTypes.forEach(
          energyType => components[energyType] = { 'title': energyType }
        );
      }

      let final_energy = [];
      for (let year = init_year; year <= current_year; year++) {
        for (let month = 0; month < number_of_months; month++) {
          //Select just non-empty elements
          energy_per_year[year][month].total = 0;
          // Calculate totals for each month
          _.forEach(Object.keys(energy_per_year[year][month]), (k) => {
            if (k !== 'total' && k !== 'name') {
              energy_per_year[year][month].total += energy_per_year[year][month][k];
            }
          });

          // Format decimals
          if (typeof energy_per_year[year][month].name === 'string') {
            energy_per_year[year][month].total = Number(energy_per_year[year][month].total).toFixed(0);
            const the_energy = Object.assign({}, energy_per_year[year][month]);
            final_energy.push(the_energy);
          }
        }
      }
      data = final_energy;
    } else if (dates.type === 'daily' || dates.type === 'hourly') {

      let aux_data = [];
      let energy = {};

      contracts.forEach((contract) => {
        energy = {};
        energyTypes.forEach(energyType => {
          if (contract[energyType.devName] && contract[energyType.devName] > 0) {
            energy[energyType.renderName] = contract[energyType.devName];
            !renderedEnergyTypes.includes(energyType.renderName) && renderedEnergyTypes.push(energyType.renderName);
          }
        });

        let date = dayjs(contract.date);
        let period = dates.type === 'hourly' ? "" : contract.period;

        if (!periods.includes(period)) {
          //make sure all periods are saved for the component var (used in the graph legend)
          periods.push(period);
        }

        // Indicates whether we found the same day
        let trobat = false;

        // We group the data by days
        aux_data.forEach(el => {
          if (el.name.valueOf() === date.valueOf()) {
            for (const [energyName, energyValue] of Object.entries(energy)) {
              (el[energyName + period] === undefined) ?
                el[energyName + period] = energyValue : el[energyName + period] += energyValue;
            }
            trobat = true;
          }
        });

        if (!trobat) {
          let newAuxData = {
            name: date,
          }

          for (const [energyName, energyValue] of Object.entries(energy)) {
            newAuxData[energyName + period] = energyValue;
          }

          aux_data.push(newAuxData)
        }

        periods.forEach((period) => {
          for (const [energyName, energyValue] of Object.entries(energy)) {
            components[energyName + period] = { 'title': energyName + period }
          }
        });
      })

      aux_data.sort(function (a, b) { return (a.name - b.name); });
      aux_data.forEach(el => {
        const date = dayjs(el.name);
        const day = date.date();
        const month = date.month();
        const year = date.year();

        //we compose the date as a readable thing like 12'Ene'22 or 3:00-12'Ene
        if (dates.type === 'hourly') {
          const hour = date.hour();
          el.name = hour + "h-" + day;
        } else {
          el.name = day + "'" + `${date.format("MMM")}'${date.format("YY")}`;
        }

        //we add all the consumptions into the total
        _.forEach(Object.keys(el), (k) => {
          el.total = 0;
          if (k !== 'total' && k !== 'name') {
            el.total += el[k];
          }
        });

      });
      data = aux_data;
    }

    return {
      data,
      components,
    }
  }

  return (
    <>
      <DateSelector onDatesChange={handleDatesChange} contractId={contractId} defaults={defaults} loading={loading} endpointVersion={1} />

      <Grid container justifyContent="center" style={{ paddingTop: 20 }}>
        {loading &&
          <Grid item xs={12} align="center">
            <CircularProgress />
          </Grid>
        }
        {!loading &&
          <Grid item xs={12}>
            {chartData && <>
              {dates.type === 'hourly' &&
                <AreaChart
                  data={chartData.data}
                  components={chartData.components}
                  unit={regional_settings.energy_unit}
                  stacked={false}
                  ignoreMaxContracts={true}
                />
              }
              {dates.type !== 'hourly' &&
                <BarChart
                  data={chartData.data}
                  components={chartData.components}
                  unit={regional_settings.energy_unit}
                  stacked={false}
                  ignoreMaxContracts={true}
                />
              }
            </>}
            {!chartData &&
              <div style={{ textAlign: "center" }}>{t('common:text.contract_cch_chart_no_data')}</div>
            }
          </Grid>
        }
      </Grid>
    </>
  )
}

export default CCHChart;