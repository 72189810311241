import { InputBaseComponentProps } from '@mui/material';
import { forwardRef } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';


export interface NumericFormatCustomProps extends Omit<InputBaseComponentProps,"onChange">{
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  readonly: boolean;
}

const NumericFormatCustom = forwardRef<NumericFormatProps, NumericFormatCustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
      />
    );
  },
);

export default NumericFormatCustom;