import { FC } from "react";
import { Typography, Paper, Box, SxProps, useTheme } from "@mui/material"
import { Button } from "@gisce/oficina-virtual-components";
import { useTranslation } from 'react-i18next';
import { grey } from "@mui/material/colors";

interface IHelpPanel {
  onClick?: () => void;
  sx?: SxProps;
}

const HelpPanel: FC<IHelpPanel> = ({onClick, sx}) => {
  const { t } = useTranslation();
  const theme = useTheme();

    return (
        <Box
          sx={{
            p: "2.5rem",
            borderRadius: `${theme.custom?.constants?.borderRadius}px`,
            backgroundColor: grey[200],
            height: "fit-content",
            textAlign: "center",
            ...sx
          }}
          >
          <Typography variant="h5" sx={{mb: 1}}>
            {t('common:text.help_panel_title')}
          </Typography>
          <Typography variant="body1" sx={{mb: 1}}>
            {t('common:text.help_panel_text')}
          </Typography>
          <Button
            color={'secondary'}
            variant={'contained'}
            onClick={onClick}
          >
            {t('common:text.help_panel_button')}
          </Button>
        </Box>
    );
}

export default HelpPanel;