import { filterContractsInvoicesRequest, viewContract, fetchContracts } from '../../actions/contractsGas';
import { locale_code } from '../../constants/i18n';
import Settings from "../../settings";

import { ContentHeader } from '../ContentHeader';
import { Notification } from '../Notification';
import { SmartTable } from '@gisce/oficina-virtual-components'
import { LoadingAnimation } from '../LoadingAnimation';
import { Button, Box } from '@mui/material';
import { ArrowForward, ArrowBack, Receipt, ZoomIn } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const style = {
  buttonAdd: {
    marginRight: 20,
  },
  buttonPosition: {
    textAlign: 'right',
  },
  table: {
    marginTop: 20,
  },
};

const ContractsListGas = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const { 
    items: contracts = [], 
    loading, 
    isFetching, 
    filtered, 
    total_items: totalItems = 0 
  } = useSelector((state) => state.contractsGas);
  const { t } = useTranslation();

  const [messageText, setMessageText] = useState(null);
  const [messageOpen, setMessageOpen] = useState(null);
  const [offset, setOffset] = useState(contracts?.offset || 0);
  const [pageItems, setPageItems] = useState(
    contracts?.page_items || Settings?.contracts?.itemsPerPage || 100);

  useEffect(() => {
    if (loading === 'idle' || filtered) {
      fetchData();
    }
  }, []);

  const fetchData = (initial=true, offset=0, filter=null) => {
    dispatch(fetchContracts(token, initial, offset, filter));
  };

  const refreshData = () => {
    fetchData(false);
    setMessageOpen(true);
  };

  const addContracts = () => {
    navigate(t('common:url.gasContractation')); // TODO Add logic here
  }

  const goToInvoice = (contract) => {
    dispatch(filterContractsInvoicesRequest(contract, contract.name));
    navigate(t('common:url.contracts_invoices', {contractName: contract?.name}));
  };

  const goToContract = (id) => {
    const contract = contracts.filter((c) => c.name === id)[0];
    dispatch(viewContract(contract));
    navigate(t('common:url.contracts_detail_gas'));
  };

  const previousPage = () => {
    const newOffset = offset - pageItems;
    setOffset(newOffset);
    fetchData(false, newOffset);
  };

  const nextPage = () => {
    const newOffset = offset + pageItems;
    setOffset(newOffset);
    fetchData(false, newOffset);
  };

  const onSearch = (searchText) => {
    setSearchText(searchText);
    fetchData(false, 0, searchText);
  };

  const JSON_to_arrays = () => {
    const showProduct = Settings?.contracts?.showProduct;

    let columns = [
      {
        title: t('common:text.contracts_contract'),
        key: "name",
      },
      {
        title: t('common:text.contracts_cups'),
        key: "cups",
      },
      {
        title: t('common:text.contracts_direction'),
        key: "address",
      },
      {
        title: t('common:text.contracts_product'),
        key: "product",
      },
      {
        title: t('common:text.contracts_tariff'),
        key: "tariff",
        removeSelf: !showProduct
      },
      {
        title: t('common:text.contracts_state'),
        key: "state",
      },
      {
        title: t('common:text.contracts_payment'),
        key: "payment",
      },
      {
        title: t('common:text.contracts_actions'),
        key: "actions",
      },
    ];

    // Remove any item with removeSelf set to true
    columns = columns.filter(item => !item.removeSelf);

    const contractsSource = contracts ? contracts.map((contract, index) => {

      const invoices_button = (
        <Button
          key={"invoices"}
          variant={'contained'}
          color={'primary'}
          disabled={!contract.name}
          title={t("common:text.contract_related_invoices")}
          onClick={() => goToInvoice(contract)}
          style={{ marginTop: '5px', marginLeft: '5px', marginRight: '5px', overflow: 'hidden' }}
        >
          {<Receipt style={style.iconBefore} />}
          {t('common:text.contracts_view_invoices_button_button')}
        </Button>
      );

      const contract_button = (
        <Button
          key={"contract"}
          variant={'contained'}
          color={'primary'}
          disabled={!contract.name}
          title={t("common:text.contract_view_detail")}
          onClick={() => goToContract(contract.name)}
          style={{ marginTop: '5px', marginLeft: '5px', marginRight: '5px', overflow: 'hidden' }}
        >
          {<ZoomIn />}
          {t('common:text.contracts_view_contracts_button_button')}
        </Button>
      );

      let contractStatus;
      switch (contract.state) {
        case 'esborrany':
          contractStatus = t('common:text.contract_status_draft');
          if (!contract.sign_date) {
            contractStatus = t('common:text.contract_status_signature_pending');
          }
          break;
        case 'activa':
          contractStatus = t('common:text.contract_status_open');
          break;
        case 'baixa':
          contractStatus = t('common:text.contract_status_closed');
          break;
        default:
          contractStatus = t('common:text.contract_status_closed');
          break;
      }

      const hasDebt = contract.amount_debt > 0;
      const contractPayment = hasDebt ? `${t('common:text.contract_debt')}  (${contract.amount_debt.toLocaleString(locale_code)} €)` : '';

      const values = {
        id: index,
        name: contract.name,
        cups: contract.cups.name,
        address: `${contract.cups.street} - ${contract.cups.city}`,
        product: (contract.pricelist && contract.pricelist.name) ? contract.pricelist.name : "",
        tariff: contract.tariff.name,
        state: contractStatus,
        payment: contractPayment,
        actions: <div> {invoices_button} {contract_button} </div>
      };

      return values;
    }) : [];

    return {
      columns,
      contractsSource
    };
  }

  const getStatusForItem = (item) => {
    if (item?.hasDebt) {
      return <ErrorOutline style={{ color: '#FF0000' }} />
    }
  }

  const renderContractList = () => {

    const adaptedData = (loading === 'succeeded') && JSON_to_arrays();

    const start_items = contracts.offset_items;
    const end_items = Math.min(start_items + contracts.page_items, contracts.total_items);

    return (
      <>
        <Notification
          message={messageText}
          open={messageOpen}
        />

        <ContentHeader
          title={t('common:text.contracts_view_title_gas')}
          addButton={Settings.features?.gasContractationation}
          addClickMethod={() => addContracts()}

          refreshButton={true}
          refreshClickMethod={() => refreshData()}
          onSearch={onSearch}
        />
        {
          <Box mt={3}>
            <SmartTable
              columns={adaptedData?.columns ?? []}
              dataSource={adaptedData?.contractsSource ?? []}
              loading={loading === 'pending'}
              onRowStatus={getStatusForItem}
              loadingComponent={<LoadingAnimation />}
            />
          </Box>
        }
        {(contracts.page_items < contracts.total_items) &&
          <div style={{ textAlign: "center" }}>
            {(start_items > 0) &&
              <Button
                variant={'text'}
                disabled={contracts.isFetching}
                onClick={() => previousPage()}
              >
                {<ArrowBack />}
                {"Anterior"}
              </Button>
            }
            <span>Mostrando de {start_items} a {end_items} de {data.total_items}</span>
            {(end_items <= data.total_items) &&
              <Button
                variant={'text'}
                disabled={contracts.isFetching}
                onClick={() => nextPage()}
              >
                {"Siguiente"}
                {<ArrowForward />}
              </Button>
            }
          </div>
        }
      </>
    );
  }

  if (loading === 'succeeded') {
    return renderContractList();
  }
  return <LoadingAnimation/>;
}

export default ContractsListGas;