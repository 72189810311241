import Cookies from "./cookies";
import * as Sentry from "@sentry/react";

export const executeHooks = (htmlContent) => {
  var div = document.createElement("div");
  div.innerHTML = htmlContent.trim();
  const arr = div.getElementsByTagName("script");
  for (let n = 0; n < arr.length; n++) {
    executeHook(arr[n]);
  }
};

const executeHook = (hookElement) => {
  const isMarketingScript =
    hookElement.className.indexOf("marketing-script") !== -1;
  const isStatisticScript =
    hookElement.className.indexOf("statistic-script") !== -1;
  const isPreferencesScript =
    hookElement.className.indexOf("preference-script") !== -1;
  const isOtherScript =
    !isMarketingScript && !isStatisticScript && !isPreferencesScript;

  if (
    (isMarketingScript && Cookies.marketingConsentGiven()) ||
    (isStatisticScript && Cookies.statisticsConsentGiven()) ||
    (isPreferencesScript && Cookies.preferenceConsentGiven()) ||
    isOtherScript
  ) {
    try {
      // eslint-disable-next-line
      eval(hookElement.innerHTML); // Run script inside div
    } catch (e) {
      Sentry.captureException(e);
      if (e instanceof SyntaxError) {
        console.log('Syntax Error on hook script: ', e.message);
      }
    }
  }
};
