import { Component } from 'react';
import PropTypes from 'prop-types';
import adBlocker from 'just-detect-adblock'

const { node } = PropTypes;

export class AdBlockDetect extends Component {

    static propTypes = {
        children: node
    };

    state = {
        detected: false
    };

    componentDidMount = () => {
        if(adBlocker.isDetected()) {
            this.setState({
                detected: true
            });
        }
    };

    render = () => this.state.detected ? this.props.children : null
}

