import {
    RECEIVE_CONTRACTS_GAS,
    FETCH_CONTRACTS_REQUEST_GAS,
    FILTER_CONTRACTS_REQUEST_GAS,
    EXPORT_CONTRACT_REQUEST_GAS,
    EXPORT_CONTRACT_DONE_GAS,
    MESSAGE_CONTRACT_GAS
} from '../constants';
import { createReducer } from '../utils/misc';

const initialState = {
  data: null,
  isFetching: false,
  loading: 'idle', // 'idle' | 'pending' | 'succeeded' | 'failed'
  downloading_contract: false,
  filterContract: null,
  editFormOpen: false,
  message: null,
  view_contract: null,
  items: []
};

export default createReducer(initialState, {
    [RECEIVE_CONTRACTS_GAS]: (state, payload) => {
      return Object.assign({}, state, {
        name_view_contract: payload.showContract,
        items: payload.response.items,
        total_items: payload.response.n_items,
        offset_items: payload.response.offset,
        page_items: payload.response.limit,
        isFetching: false,
        loading: 'succeeded',
        message_text: payload.message,
      })
    },
    [EXPORT_CONTRACT_REQUEST_GAS]: state =>
      Object.assign({}, state, {
        downloading_contract: true,
    }),
    [EXPORT_CONTRACT_DONE_GAS]: state =>
      Object.assign({}, state, {
        downloading_contract: false,
    }),
    [MESSAGE_CONTRACT_GAS]: (state, payload) => {
      return Object.assign({}, state, {
            message: payload.message,
        })
    },
    [FILTER_CONTRACTS_REQUEST_GAS]: (state, payload) =>
        Object.assign({}, state, {
          filterContract: payload.contract
        }),
    [FETCH_CONTRACTS_REQUEST_GAS]: (state, payload) =>
      Object.assign({}, state, {
        isFetching: true,
        loading: 'pending',
        message_text: payload.message,
    }),
    BIND_CONTRACT: (state, payload) =>
      Object.assign({}, state, {
        contractId: payload.contractId
    }),
    FETCH_ADDRESSES_GAS: (state, payload) => {
      return {
        ...state,
        availableAddresses: payload
      }
    },
    EDIT_CONTRACT_GAS: (state, payload) => {
      return {
        ...state,
        editFormOpen: payload
      }
    },
    VIEW_CONTRACT_GAS: (state, payload) =>
    Object.assign({}, state, {
      view_contract: payload
    }),
    SET_IBAN_GAS: (state, payload) => {
      const { view_contract } = state;
      const c = Object.assign({}, view_contract, {
        bank_account: payload
      });
      const items = state.items.map((contract) => {
        if (contract.name === view_contract.name) {
          return Object.assign({}, contract, {
            bank_account: payload
          })
        } else {
          return contract
        }
      });
      return Object.assign({}, state, {
        view_contract: c,
        items
      });
    },
    SET_ADDRESS_REQUEST: (state, payload) => {
      return {
        ...state,
        sending: true
      }
    },
    SET_EMAIL_GAS: (state, payload) => {
      const { view_contract } = state;
      const { notification_address } = view_contract;
      const c = Object.assign({}, notification_address, {
        email: payload
      });
      const items = state.items.map((contract) => {
        if (contract.name === view_contract.name) {
          return Object.assign({}, contract, {
            notification_address: c
          })
        } else {
          return contract
        }
      });
      return Object.assign({}, state, {
        view_contract: Object.assign({}, view_contract, {notification_address: c}),
        items
      });
    },
    SET_INVOICE_ONLINE_GAS: (state, payload) => {
      return {
        ...state,
        items: {
          ...state.items,
          //Assignació dinàmica dins l'objecte
          [payload.contractId]: {
            ...state.items[payload.contractId],
            invoice_online: payload
          }
        }
      }
    },
    SET_PHONE_GAS: (state, payload) => {
      const { view_contract } = state;
      const { notification_address } = view_contract;
      const c = Object.assign({}, notification_address, {
        mobile: payload
      });
      const items = state.items.map((contract) => {
        if (contract.name === view_contract.name) {
          return Object.assign({}, contract, {
            notification_address: c
          })
        } else {
          return contract
        }
      });
      return Object.assign({}, state, {
        view_contract: Object.assign({}, view_contract, {notification_address: c}),
        items
      });
    },
});