import { LOGIN_NOT_MADE } from "@/constants";
import { LoginNotMadeError } from "@/types/models/hooks";
import { Dispatch } from "redux";

export const loginNotMade = (err?: LoginNotMadeError) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: LOGIN_NOT_MADE,
      payload: err
    });
  };
}