import { 
  LOGIN_TOKEN_SUCCESS,
  LOGIN_TOKEN_FAILURE,
  LOGIN_TOKEN_REQUEST
 } from "@/constants";
import { LoginRequestResponse, TokenLoginParam } from "@/types/models/hooks";
import { 
  define_token, 
  undefine_token, 
  validate_token
} from "@/utils/http_functions";
import { Dispatch } from "redux";

export const loginTokenSuccess = (token: string) => {
  define_token(token);

  // Remove token from search params
  const paramsWithoutToken = new URLSearchParams(window.location.search);
  paramsWithoutToken.delete('token');

  return {
    type: LOGIN_TOKEN_SUCCESS,
    payload: {
      token,
      redirect: window.location.pathname,
      redirectSearchParams: paramsWithoutToken
    }
  };
}

export const loginTokenFailure = ({
  statusCode = 403, 
  statusText = '', 
  statusType = "error"
} = {}) => {
  undefine_token();

  return {
    type: LOGIN_TOKEN_FAILURE,
    payload: {
      statusCode,
      statusText,
      statusType,
    },
  };
}

export const loginTokenRequest = () => {
  return {
    type: LOGIN_TOKEN_REQUEST
  }
}

export const loginToken = ({token}: TokenLoginParam) => {
  return (dispatch: Dispatch) => {
    dispatch(loginTokenRequest());
    undefine_token();

    validate_token(token) // TODO: Type guard
      .then(({data}) => {
        if (data.token_is_valid) {
          dispatch(loginTokenSuccess(token));
        } else {
          dispatch(loginTokenFailure({statusCode: 419}));
        }
      }).catch(err => {
        console.log(err);
        dispatch(loginTokenFailure({
          statusCode: 401,
          statusText: 'common:text.login_view_token_invalid',
          statusType: "error"
        }));
      });
  }
}