import { LOGOUT_REQUEST, LOGOUT_SUCCESS } from "@/constants";
import Settings from "@/settings";
import { openid_logout, undefine_token } from "@/utils/http_functions";
import Cookies from "js-cookie";
import { Dispatch } from "redux";

export function logoutSuccess() {
	return {
			type: LOGOUT_SUCCESS,
	};
}

export function logoutRequest() {
	return {
			type: LOGOUT_REQUEST,
      payload: {
        redirect: "common:url.login"
      }
	};
}

export function logout() {
  return (dispatch: Dispatch) => {
    dispatch(logoutRequest());
    undefine_token();
    if (Settings.features?.oidc) {

      const openid_token = Cookies.get('openid_token');
      if (openid_token) {
        Cookies.remove('openid_token');
        openid_logout(openid_token); // canvi de pagina
      }
    }
    dispatch(logoutSuccess());
  };
}