import { useTranslation } from "react-i18next";
import { dayjs } from "../../config";

const useTypes = () => {
  const { t } = useTranslation();

  const stringTypes = {
    contains: { 
      input: "text", 
      title: t("common:text.filter_contain_value"),
      defaultValue: "",
      render: (val = "", catName, catSearch) => {
        if(!val) {
          return null;
        }
        return {
          inner: val,
          disabled: val === "",
          render: <span><b>{catName}</b> {t("common:text.filter_chip_contains")} <b>{val}</b></span>,
          search: `('${catSearch}', 'like', '${val}')`
        }
      }
    }
  }

  const selectTypes = {
    contains: { 
      input: "text", 
      title: t("common:text.filter_contain_value"),
      defaultValue: '',
      render: (val = '', catName, catSearch) => {
        if(!val) {
          return null;
        }
        return {
          inner: val,
          defaultValue: '',
          disabled: val.length === 0,
          render: <span><b>{catName}</b> {t("common:text.filter_chip_is")} <b>{val.label}</b></span>,
          search: `('${catSearch}', '=', '${val.search}')`
        }
      }
    }
  }

  const dateTypes = {
    before:  { 
      input: "picker", 
      title: t("common:text.filter_before"),
      defaultValue: null,
      render: (val, catName, catSearch) => {
        if(!val || val && !val.isValid()) {
          return null;
        }
        return {
          render: <span><b>{catName}</b> {t("common:text.filter_chip_before")} <b>{val.format("L")}</b></span>,
          search: `('${catSearch}', '<', '${val.format("YYYY-MM-DD")}')`
        }
      }
    },
    after: { 
      input: "picker", 
      title: t("common:text.filter_after"),
      defaultValue: null,
      render: (val, catName, catSearch) => {
        if(!val || val && !val.isValid()) {
          return null;
        }
        return {
          render: <span><b>{catName}</b> {t("common:text.filter_chip_after")} <b>{val.format("L")}</b></span>,
          search: `('${catSearch}', '>', '${val.format("YYYY-MM-DD")}')`
        }
      }
    },
    between: { 
      input: "double-picker", 
      title: t("common:text.filter_between"),
      defaultValue: [null, null],
      render: (val, catName, catSearch) => {
        if ((val[0] === null || val && !val[0].isValid()) || (val[1] === null || val && !val[1].isValid())) {
          return null;
        }
        const [smallVal, bigVal] = [dayjs.min(...val), dayjs.max(...val)];
        if(Array.isArray(catSearch)) {
          return {
            render: <span><b>{catName}</b> {t("common:text.filter_chip_between")} <b>{smallVal.format("L")}</b> {t("common:text.filter_chip_and")} <b>{bigVal.format("L")}</b></span>,
            search: `('${catSearch[0]}', '>=', '${smallVal.format("YYYY-MM-DD")}'), ('${catSearch[1]}', '<=', '${bigVal.format("YYYY-MM-DD")}')`
          }
        } else {
          return {
            render: <span><b>{catName}</b> {t("common:text.filter_chip_between")} <b>{smallVal.format("L")}</b> {t("common:text.filter_chip_and")} <b>{bigVal.format("L")}</b></span>,
            search: `('${catSearch}', '>=', '${smallVal.format("YYYY-MM-DD")}'), ('${catSearch}', '<=', '${bigVal.format("YYYY-MM-DD")}')`
          }
        }
      }
    },
    exact: { 
      input: "picker", 
      title: t("common:text.filter_exact"),
      defaultValue: null,
      render: (val, catName, catSearch) => {
        if(!val || val && !val.isValid()) {
          return null;
        }
        return {
          render: <span><b>{catName}</b> {t("common:text.filter_chip_is")} <b>{val.format("L")}</b></span>,
          search: `('${catSearch}', '=', '${val.format("YYYY-MM-DD")}')`
        }
      }
    }
  }
  
  const numericTypes = {
    exact: { 
      input: "text", 
      title: t("common:text.filter_exact"),
      defaultValue: "",
      render: (val = "", catName, catSearch, units) => {
        if(!val) {
          return null;
        }
        return {
          inner: val,
          defaultValue: "",
          disabled: val === "",
          render: <span><b>{catName}</b> {t("common:text.filter_chip_exactly")} <b>{val}</b>{units}</span>,
          search: `('${catSearch}', '=', ${val.replace(",", ".")})`
        }
      }
    },
    bigger: { 
      input: "text", 
      title: t("common:text.filter_bigger_than"),
      defaultValue: "",
      render: (val = "", catName, catSearch, units) => {
        if(!val) {
          return null;
        }
        return {
          inner: val,
          defaultValue: "",
          disabled: val === "",
          render: <span><b>{catName}</b> {t("common:text.filter_chip_bigger")} <b>{val}</b>{units}</span>,
          search: `('${catSearch}', '>', ${val.replace(",", ".")})`
        }
      }
    },
    smaller: { 
      input: "text", 
      title: t("common:text.filter_smaller_than"),
      defaultValue: "",
      render: (val = "", catName, catSearch, units) => {
        if(!val) {
          return null;
        }
        return {
          inner: val,
          defaultValue: "",
          disabled: val === "",
          render: <span><b>{catName}</b> {t("common:text.filter_chip_smaller")} <b>{val}</b>{units}</span>,
          search: `('${catSearch}', '<', ${val.replace(",", ".")})`
        }
      }
    },
    between: { 
      input: "range", 
      title: t("common:text.filter_between"),
      defaultValue: ["", ""],
      render: (val = ["", ""], catName, catSearch, units) => {
        if (val[0] === "" || val[1] === "") {
          return null;
        }
        const [smallVal, bigVal] = [Math.min(...val), Math.max(...val)];
        return {
          inner: val,
          defaultValue: ["", ""],
          disabled: val[0] === "" || val[1] === "",
          render: <span><b>{catName}</b> {t("common:text.filter_chip_between")} <b>{smallVal}</b>{units} {t("common:text.filter_chip_and")} <b>{bigVal}</b>{units}</span>,
          search: `('${catSearch}', '>=', ${smallVal}), ('${catSearch}', '<=', ${bigVal})`
        }
      }
    }
  }

  const autocompleteTypes = {
    contains: { 
      input: "text", 
      title: t("common:text.filter_select"),
      defaultValue: [],
      render: (val = [], catName, catSearch) => {
        if(!val.length) {
          return null;
        }
        return {
          inner: val,
          disabled: val.length === 0,
          render: <span>
            <b>{catName}</b> {t("common:text.filter_chip_is")} <b>
              {val.map((f, idx)  => ((idx === val.length - 1 ) && (val.length > 1)  ? " o " : "") + f + (idx < val.length - 2  ? ", " : "") )}
            </b>
            </span>,
          search: `('${catSearch}', 'in', [${val.map(f  => "'" + f + "'")}]) `
        }
      }
    }
  }

  return { stringTypes, selectTypes, dateTypes, numericTypes, autocompleteTypes };

}

export default useTypes;