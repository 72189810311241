import Settings from "../settings.yml";

import { StyleVars as _StyleVars } from "../style/index.js";

export default Settings;

export const StyleVars = _StyleVars;

export const organization = Settings.organization;

/*
  Legacy settings
   
  This will be deprecated and removed soon! Instead, exported Settings should be used.
*/
export const features = Settings.features;
