import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { 
  Box, Stepper, Typography, 
  Step, StepContent, StepButton, Alert
} from "@mui/material";
import queryString from "query-string";

import * as electricityContractationActions from "../../actions/electricityContractation";
import Settings from "../../settings";
import HelpMessage from "../HelpMessage";
import useSteps from "./useSteps";


const ElectricityContractation = ({gas}) => {
  const elecContractation = useSelector((state) => state.electricityContractation);
  
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const { steps, completedSteps } = useSteps(gas);

  const phoneLink = (Settings?.organization?.phone_crm ?? "").replace(/\s/g, '');
  const phone = Settings?.organization?.phone_crm ?? "";

  useEffect(() => {
    const { promocode } = queryString.parse(location.search, {arrayFormat: 'comma'});

    if (Settings?.features?.promotionalCode && promocode) {
      dispatch(electricityContractationActions.validateCoupon(promocode));
    }

    dispatch(electricityContractationActions.fetchContractationConfig());
    
    import("../../overrides/hooks/contractation").then(
      ({chatHook}) => {
        if (typeof chatHook === "function" && !!chatHook()) {
          document.body.appendChild(chatHook());
        }  
      }
    );
  }, []);

  useEffect(() => {
    if ( elecContractation.currentStep.name == 'confirmation' ) {
      import("../../overrides/hooks/contractation").then(
        ({trackingHook}) => {
          if (typeof trackingHook === "function" && !!trackingHook()) {
            let content =  trackingHook();
            if (content) {
              content = content.replace("<insert order id value here>", elecContractation.cups);
              document.head.prepend(document.createRange().createContextualFragment(content));
            }
          } 
        }
      );
    }

    // eslint-disable-next-line
  }, [elecContractation.currentStep.index]);

  // TODO: Pass number or string (step index or name)
  const handleGoToStep = (index) => {
    if (
      index <= Object.values(completedSteps).length && 
      elecContractation.latestStep !== steps.length-1 && 
      !elecContractation.submitting
    ) {
      dispatch(
        electricityContractationActions.setLeadCurrentStep({
          index, 
          name: steps[index].name
        }));
    }
  }
  
  return (
    <>
      <Box mb={2}>
        <Typography variant="h4" style={{marginBottom: 10}}>
          {gas ? 
            t('common:text.contractation_dual_title') : 
            t('common:text.contractation_title')
          }
        </Typography>

        {Settings?.contractation?.helpMessage &&
          <HelpMessage
            message = {
              t('common:text.contractation_help_message', 
                { phone: phone, phoneLink: phoneLink, })
            }
          />
        }
      </Box>

      <Stepper 
        nonLinear 
        activeStep={elecContractation.currentStep.index} 
        orientation="vertical"
      >

        {steps.map(({label, content}, idx) => <Step 
            key={idx} 
            completed={completedSteps[idx]}
            sx={elecContractation.latestStep === idx && {'& .MuiStepIcon-root': {color: 'primary.main'}}}
          >
            <StepButton onClick={() => handleGoToStep(idx)}>
                {t(label)}
            </StepButton>
            <StepContent>
              {content}
            </StepContent>
          </Step>
        )}

      </Stepper>
      
      { elecContractation.leadErrors.genericError &&
        <Alert severity={"error"} variant="filled">
          {t("common:text.contractation_leads_generic_error")}
        </Alert>
      }
      
    </>
  );
};

export default ElectricityContractation;
