import { createElement } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { captureException } from "@sentry/react";

export const BoundaryComponent = (props, components = [], FallbackComponent = null) => {

  const fallbackRender = ({ nextComponentIndex, error, resetErrorBoundary }) => {
    let renderedFallback = (<div></div>);

    captureException(error);

    if (nextComponentIndex < components.length) {
      renderedFallback = (
        <ErrorBoundary fallbackRender={(props) => fallbackRender({ nextComponentIndex: nextComponentIndex + 1, ...props })}>
          {createElement(components[nextComponentIndex], props)}
        </ErrorBoundary>
      )
    }

    return renderedFallback;
  }

  return <ErrorBoundary fallbackRender={(props) => fallbackRender({ nextComponentIndex: 1, ...props })}>
    {createElement(components[0], props)}
  </ErrorBoundary>

}