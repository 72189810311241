import { useSelector } from "react-redux";
import { BatterySelector } from "../BatterySelector";
import useMoveVirtualBatteryContext from "../useMoveVirtualBatteryContext";

export const MoveVirtualBatteryStep1 = ({nextStepCallback}) => {
  const {
    bateriaVirtualToMove,
    selectedBatteryId,
    setSelectedBatteryDistribution,
  } = useMoveVirtualBatteryContext();
  const {
    isFetchingBateriaVirtualPercentages,
    bateriaVirtualPercentages,
  } = useSelector((state) => state.bateriaVirtual);


  const handleSelectedBattery = (batteryDistribution) => {
    setSelectedBatteryDistribution(batteryDistribution);
    // nextStepCallback();
  }

  let renderedSelector = (

    <BatterySelector
      selectedBatteryId={selectedBatteryId}
      onSelected={handleSelectedBattery}
      excludeBatteryId={bateriaVirtualToMove.bateria.id}
    />
  )

  if (!isFetchingBateriaVirtualPercentages && !bateriaVirtualPercentages || !bateriaVirtualToMove) {
    renderedSelector = "No batteries available";
  }

  return renderedSelector;
};

export default MoveVirtualBatteryStep1;
