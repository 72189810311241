import useI18n from "@/hooks/useI18n";
import { ListItem } from "@gisce/oficina-virtual-components"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux";
import { LanguageListItem } from "../../LanguageSelect";
import { Logout } from "@mui/icons-material";
import useAuth from "@/hooks/useAuth";
import { RootState } from "@/store";

export const BottomListItems = () => {
	const { t } = useTranslation();
	const { logout } = useAuth();
	const { getContractationURL } = useI18n();
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

	return <>
		<LanguageListItem />
		{isAuthenticated && <ListItem 
			label={t("common:text.logout_title")}
			icon={<Logout />} 
			onClick={() => logout()}
		/>}
	</>
}
