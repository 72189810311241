import { LanguageListItem as LanguageListItemComponent } from '@gisce/oficina-virtual-components';
import useI18n from '@/hooks/useI18n';

export const LanguageListItem = () => {
  const { availableLangs, currentLang, changeLang } = useI18n();

  return (
    <LanguageListItemComponent 
      availableLanguages={availableLangs}
      currentLanguage={currentLang}
      handleLanguageSelect={changeLang}
    />
  );
}