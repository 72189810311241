import { legacy_createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { appReducer } from '../reducers';

const store = legacy_createStore(
  appReducer,
  composeWithDevTools(
    applyMiddleware(
      thunkMiddleware
    )
  ),
);

export type RootState = ReturnType<typeof appReducer>;
export type AppDispatch = typeof store.dispatch;

export default store;