import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useServerElements from "./useServerElements";
import ServerElement from "./ServerElement";
import { Box } from "@mui/material";
import { LogoBar } from "../LogoBar";
import { IServerElement } from "./types";
import { RootState } from "@/store";

/**
 * Fetches HTML server elements based on the provided `pageId` url parameter.
 * Renders the elements grouped by their `group_name`.
 * Utilizes the `ServerElement` component to render individual segments.
**/
const DinamicServerPage = ({ }) => {
  const { fetchServerElements, groupByGroupName } = useServerElements();
  const params = useParams();
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const [elements, setElements] = useState<IServerElement[]>([]);
  const elementByGroupName = groupByGroupName(elements);

  useEffect(() => {
    if (isAuthenticated && params.pageId) {
      fetchServerElements(params.pageId).then((response) => {
        setElements(response as IServerElement[]);
      })
    } else {
      setElements([]);
    }
  }, [isAuthenticated, params.pageId]);


  const renderListOfElements = (arrayOfElements:IServerElement[]) => {
    return arrayOfElements.map((serverElement) => {
      const flexBasisValue = (serverElement["blockWidth"]) ? serverElement["blockWidth"]+"px": "100%";
      return (
        <Box key={serverElement.id} style={{flex: 1, textAlign: "center", flexBasis: flexBasisValue}}>
          <ServerElement htmlElement={serverElement} />
        </Box>
      )
    })
  }

  const renderGroups = () => {
    const groups = [];
    for (let key in elementByGroupName) {
      groups.push(
        <Box key={key} style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", gap: 4 }}>
          {renderListOfElements(elementByGroupName[key])}
        </Box>
      )
    }
    return groups;
  }

  return (
    <div>
      { 
        Object.values(elementByGroupName).length ? (
          renderGroups()
        ) : (
          <LogoBar />
        )
      }
    </div>
  )
}

export default DinamicServerPage;
