import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";
import { i18n } from "../../../../config";
import Settings from "../../../../settings";

export const BatteryOriginWarning = ({ onAccept }) => {
  const handleContinue = () => {
    onAccept?.();
  };

  return (
    <Dialog open={true}>
      <DialogTitle>
        {i18n.t("common:text.battery_origin_warning_title")}
      </DialogTitle>
      <DialogContent style={{ textAlign: "justify" }}>
        <Typography paragraph>
          {i18n.t("common:text.battery_origin_warning_content", {
            comerEmail: Settings.organization?.email,
          })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleContinue}
          color="primary"
          autoFocus
          variant="contained"
        >
          {i18n.t("common:text.battery_origin_warning_continue")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BatteryOriginWarning;
