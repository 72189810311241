

// import { TConsumptionGenerationItem, TGetConsumptionGenerationParams } from "../types";

import { ChangePasswordData, ChangePasswordResponse, IChangePassword } from "@/types/models/services/changePassword";
import API from "./api";

const ENDPOINT = "v1"

// static getUsers(): Promise<User[]> {
// 	return new Promise<User[]>((resolve, reject) => {
// 		API.get(`${ENDPOINT}/users`)
// 			.then((value: User[]) => {
// 				const formataatexmep = values.map()
// 				resolve(formataatexmep)
// 			}).catch((error) => {
// 				reject("oh no")
// 				reject(error)
// 			})
// 	})
// }

const changePassword = ({
	currentPassword,
	newPassword
}: IChangePassword) => {
	return new Promise<ChangePasswordResponse>(async (resolve, reject) => {
		API.put<ChangePasswordResponse, ChangePasswordData>(
			`${ENDPOINT}/user/password`,
			{
				current: currentPassword, 
				password: newPassword
			}
		)
		.then(res => {
			if(res.status === "ERROR") {
				reject(res);
			} else {
				resolve(res);
			}
		})
		.catch(err => {
			reject(err);
		})
	})
}

export default {
	changePassword
};