import React, { useState, useEffect, useRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreators from "../../actions/gasContractation";
import _ from "lodash";

import { Step, Stepper, StepLabel, Box, Typography } from "@mui/material";

import { i18n } from "../../config";

import FormAboutInstallation from "./FormAboutInstallation";
import FormAboutYou from "./FormAboutYou";
import FormPayment from "./FormPayment";
import FormAboutContract from "./FormAboutContract";
import Confirm from "./Confirm";
import Confirmed from "./Confirmed";

function mapStateToProps(state) {
  return {
    authType: state.auth.type,
    isAuthenticated: state.auth.isAuthenticated,
    ...state.gasContractation,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

const GasContractation = (props) => {

  const stepLimit = 6;
  const [index, setIndex] = useState(1);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const updateWindowDimensions = () => {
    setScreenWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  const stepTabs = (
    <Stepper activeStep={index - 1} orientation={screenWidth > 996 ? "horizontal" : "vertical" }>
      <Step>
        <StepLabel>{i18n.t('common:text.contractation_about_installation')}</StepLabel>
      </Step>
      <Step>
        <StepLabel>{i18n.t('common:text.contractation_about_you')}</StepLabel>
      </Step>
      <Step>
        <StepLabel>{i18n.t('common:text.contractation_about_gas_contract')}</StepLabel>
      </Step>
      <Step>
        <StepLabel>{i18n.t('common:text.contractation_about_payment')}</StepLabel>
      </Step>
      <Step>
        <StepLabel>{i18n.t('common:text.contractation_confirmation')}</StepLabel>
      </Step>
      <Step>
        <StepLabel>{i18n.t('common:text.contractation_confirmed')}</StepLabel>
      </Step>
    </Stepper>
  );

  const handleSubmit = () => {
    if (index < stepLimit) {
      setIndex(index + 1);
    }
  };

  const handleGoBack = () => {
    if (index !== 1) {
      setIndex(index - 1);
    }
  };

  const steps = [
    { leadIndex: 1, content: <FormAboutInstallation onSubmit={handleSubmit} /> },
    {
      leadIndex: 2,
      content: (
        <FormAboutYou
          onGoBack={handleGoBack}
          onSubmit={handleSubmit}
        />
      ),
    },
    {
      leadIndex: 3,
      content: (
        <FormAboutContract onGoBack={handleGoBack} onSubmit={handleSubmit} />
      ),
    },
    {
      leadIndex: 4,
      content: <FormPayment onGoBack={handleGoBack} onSubmit={handleSubmit} />,
    },
    {
      leadIndex: 5,
      content: <Confirm onGoBack={handleGoBack} onSubmit={handleSubmit} />,
    },
    { leadIndex: 5, content: <Confirmed onGoBack={handleGoBack} /> },
  ];

  useEffect(() => {
    props.fetchContractationConfig();
    // eslint-disable-next-line
  }, []);

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };


  const stepContent = () => {
    return steps[index - 1].content;
  };

  return (
    <div>
      <Box>
        <Typography variant="h4">
          {i18n.t('common:text.contractation_gas_title')}
        </Typography>
      </Box>
      {stepTabs}
      {stepContent()}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(GasContractation);
