import { SmartTable } from '@gisce/oficina-virtual-components'
import useTable from "./useTable"


const Table = ({data, columns, loaded, onRowStatus}) => {
    const { rows } = useTable({loaded, columns, data});

  return (
    <SmartTable
      columns={columns}
      dataSource={rows ?? []}
      loading={false}
      onRowStatus={onRowStatus}
      loadingComponent={rows}
    />
  )
}

export default Table;

