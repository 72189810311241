import React, { useState } from "react";
import { connect } from 'react-redux';
import {
  data_create_api_resource,
} from "../../utils/http_functions";
import _ from "lodash";
import { Form, Field, FormSpy } from "react-final-form";
import FileUploadField from "../form-fields/FileUploadField";
import { i18n } from "../../config";
import TextField from "../form-fields/TextField";

import { Button, Typography, CircularProgress } from '@mui/material';

const mapStateToProps = (state) => ({
  token: state.auth.token,
});

const UnsignedCRM = (token) => {

  const [caseId, setCaseId] = useState(null);
  const [sent, setSent] = useState(false);
  const [formValues, setFormValues] = useState({
    email: '',
    mobile: '',
    subject: '',
    description: '',
    errors: {},
    attachment: [],
  });

  const submit = async (values) => {
    if (_.isEmpty(values.errors)) {
      setFormValues({errors: {}});
      const postData = {
        email: values.email,
        mobile: values.mobile,
        subject: values.subject,
        attachment: values.attachment,
        description: values.description
      };
      const result = await data_create_api_resource(null, `crm/unsigned/`, postData);
      if (result.status === 200) {
        let res = result.data
        setCaseId(_.get(result, "data.result"));
        setSent(true);
      } else {
        throw new Error(result);
      }
    }
  };

  const renderDone = () => {
    return (
      <div>
        <p>
          { i18n.t('common:text.unsigned_contact_success') } <code>#{caseId}</code>
        </p>
      </div>
    );
  }

  const renderForm = () => {
    return(
      <Form
        onSubmit={submit}
        initialValues={formValues}
        validate={(values) => {
          const errors = {};
          const email_regex = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');

          if (!values.email) {
            errors.email = i18n.t("common:text.required_field");
          } else if (!email_regex.test(values.email)) {
            errors.email = i18n.t("common:text.validation_email_name_not_valid");
          } 

          if (!values.mobile) {
            errors.mobile = i18n.t("common:text.required_field");
          }

          if (!values.subject) {
            errors.subject = i18n.t("common:text.required_field");
          }

          if (!values.description) {
            errors.description = i18n.t("common:text.required_field");
          }

          return Object.keys(errors).length && errors;
        }}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => (
          <form onSubmit={handleSubmit} style={{width: "50vw"}}>
            <div style={{ marginBottom: "1em" }}>
              <label>{i18n.t("common:text.unsigned_contact_email")}</label>
              <Field name="email" component={TextField} type="text" />
            </div>
            <div style={{ marginBottom: "1em" }}>
              <label>{i18n.t("common:text.unsigned_contact_mobile")}</label>
              <Field name="mobile" component={TextField} type="text" />
            </div>
            <div style={{ marginBottom: "1em" }}>
              <label>{i18n.t("common:text.unsigned_contact_subject")}</label>
              <Field name="subject" component={TextField} type="text" />
            </div>
            <div style={{ marginBottom: "1em" }}>
              <label>{i18n.t("common:text.unsigned_contact_description")}</label>
              <Field
                name="description"
                component={TextField}
                type="text"
                multiline
              />
            </div>
            <div style={{ marginTop: 12, marginBottom: 15 }}>
              <Field
                name="attachment"
                component={FileUploadField}
                min={1}
                max={6}
                label={i18n.t("common:text.attachment")}
                anotherLabel={i18n.t("common:text.attachment_add")}
                removeLabel={i18n.t("common:text.remove")}
              />
            </div>
            <Button
              type="submit"
              color={'primary'}
              variant={'contained'}
              disabled={submitting}
            >
              {submitting ? <CircularProgress size={25} /> : null}
              {i18n.t('common:text.unsigned_contact_send')}
            </Button>
            <FormSpy onChange={(state) => setFormValues(state.values)} />
          </form>
        )}
      />
    )
  }

  return (
    <>
      <Typography variant="h4">
        {i18n.t('common:text.unsigned_contact_title')}
      </Typography>
      { sent ? renderDone() : renderForm() }
    </>
  );

}

export default connect(mapStateToProps)(UnsignedCRM);
