import { i18n } from "../config";
import validate from 'validate.js';

// Default validator messages

validate.validators.length.tooLong = i18n.t('common:text.length_too_long');
validate.validators.presence.options = {
  message: i18n.t('common:text.contractation_required_field'),
};
validate.validators.email.options = {
  message: i18n.t('common:text.validation_email_not_valid'),
};