import authActions from "../actions/auth";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { decodeJwt, decodeProtectedHeader } from 'jose'
import { 
  IToken, 
  LoginNotMadeError, 
  LoginParams
} from "@/types/models/hooks";
import { RootState } from "@/store";
import { useTranslation } from "react-i18next";

const useAuth = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const auth = useSelector((store: RootState) => store.auth);
  const [tokenValues, setTokenValues] = useState<IToken>();
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const tokenExpiration = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (auth.token) {
      const decodedToken = decodeJwt<IToken>(auth.token);
      const expiration = decodeProtectedHeader(auth.token).exp as number;

      setTokenValues(decodedToken);
      
      if ( tokenExpiration.current ) {
        clearTimeout(tokenExpiration.current);
        tokenExpiration.current = undefined;
      }

      tokenExpiration.current = setTimeout(() => {
        setIsTokenExpired(true);
      }, (expiration * 1000) - Date.now());
    } else {
      setIsTokenExpired(false);
    }

    return () => {
      if ( tokenExpiration.current ) {
        clearTimeout(tokenExpiration.current);
      }
    }
  }, [auth.token])

  // Single implementation using a generic
  const login = async ({type, authParams}: LoginParams) => {

    switch (type) {
      case "Credentials":
        dispatch(authActions.loginCredentials(authParams));
        break;
      case "Token":
        dispatch(authActions.loginToken(authParams));
        break;
      case "ClientID":
        dispatch(authActions.loginClientId(authParams));
        break;
      case "OpenIDRedirect":
        authActions.redirectOpenId(authParams);
        break;
      case "OpenIDSubmit":
        dispatch(authActions.loginOpenId(authParams))
        break;
    }

  }

  const logout = () => {
    dispatch(authActions.logout())
  };

  const loginRefresh = (token: string, redirect=null) => {
    // Fer time out
    dispatch(authActions.loginRefresh(token));
  }

  const loginNotMade = (err?: LoginNotMadeError) => {
    dispatch(authActions.loginNotMade(err));
  }

  const getErrorMessage = () => {
    if(auth.statusType === "error") {
      switch (auth.type) {
        case "Credentials":
          if (auth.statusCode === 401)
            return t('common:text.login_view_session_expired');
          if (auth.statusCode === 403)
            return t('common:text.login_view_message_error_login');
          break;
        case "Token":
          if (auth.statusCode === 419)
            return t('common:text.errorTokenExpired');
          break;
        case "ClientID":
          break;
        case "OpenIDSubmit":
          break;
      }

      if (auth.statusCode === 500)
        return t('common:text.errorInternError');

      if (auth.statusCode === 502)
        return t('common:text.errorInternError');

      return t('common:text.login_view_message_error_login');
    }
  }

  return {
    login,
    loginRefresh,
    logout,
    loginNotMade,
    tokenValues,
    isTokenExpired,
    getErrorMessage
  };
};

export default useAuth;