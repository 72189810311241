import React from "react";
import SignUp from "../SignUp";

import { Box, Typography } from "@mui/material"
import { useTranslation } from "react-i18next";

const SignUpView = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant="h4">
        {t('common:text.signup_view_title')}
      </Typography>
      <SignUp />
    </Box>
  );
}

export default SignUpView;