import {data_fetch_api_resource} from "../utils/http_functions";

export const fetchATCTipus = () => async (dispatch, getState) => {
  const { token } = getState().auth;
  const result = await data_fetch_api_resource(token, 'atc/tipus');
  return dispatch({
    type: 'FETCH_ATC_TIPUS',
    payload: result.data,
  });
};

export const fetchATCSubtipus = (tipus) => async (dispatch, getState) => {
  const { token } = getState().auth;
  const result = await data_fetch_api_resource(token, `atc/tipus/${tipus}/subtipus`);
  return dispatch({
    type: 'FETCH_ATC_SUBTIPUS',
    payload: result.data
  });
};
