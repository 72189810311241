import React from 'react';
import { connect } from 'react-redux';
import ContractElectronicNotificationDialog from "./Dialog";
import {
  data_create_api_resource,
  data_update_api_resource
} from "../../../utils/http_functions";
import {setElectronicNotification} from "../../../actions/contracts";
import ErrorMessage from "../../ErrorMessage";
import SignatureProcess from "../../SignatureProcess";
import { i18n, dayjs } from "../../../config";
import { locale_code } from "../../../constants/i18n";

import { Button, Grid } from "@mui/material"
import { Check, Close } from "@mui/icons-material"

const mapStateToProps = (state) => ({
    token: state.auth.token,
    contract: state.contracts.view_contract,
});

class ContractElectronicNotification extends React.Component {

    static getElecNotifStatus(contract) {
        if (contract.electronic_notification && contract.date_start_electronic_notification) {
            return 'active';
        }
        else {
            if (contract.date_request_electronic_notification) {
                return 'requested';
            }
            else {
                return 'unactive';
            }
        }

    }

    constructor(props) {
        super(props);
        this.state = {
            sending: false,
            dialogOpen: false,
            error: null,
            submitted: false
        }
        dayjs.locale(locale_code);
    }

    handleDialogOpen = () => {
      this.setState({
        dialogOpen: true,
        error: null,
      });
    };

    handleDialogClose = () => {
        this.setState({dialogOpen: false})
    };

    handleSubmit = async (email, mobile) => {
        const { contract } = this.props;
        let error = false;
        this.setState({sending: true});
        try {
          const newData = { mobile, email };
          /* First we update the email and mobile */
          await data_update_api_resource(
            this.props.token, 'users/', newData
          )
          /* Then we request the activation of electronic notification */
          await data_create_api_resource(
              this.props.token,
            `contracts/${contract.name}/electronic_notification/`, {}
          );
        } catch (e) {
          error = true;
        } finally {
          if (!error) {
            this.props.setElectronicNotification({
              date_request_electronic_notification: dayjs()
            });
          }
          this.setState({
            sending: false,
            error,
            dialogOpen: !!error,
            submitted: true
          })
        }
    };

    render() {
        const { contract } = this.props;
        const { dialogOpen, error, submitted, sending } = this.state;
        const currentElecNotifStatus = ContractElectronicNotification.getElecNotifStatus(contract);
        const CustomErrorMsg = () => <ErrorMessage>{typeof error === 'string' && error}</ErrorMessage>;
        return (
          <div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <ContractElectronicNotificationDialog
                open={dialogOpen}
                submitted={submitted}
                sending={sending}
                handleClose={this.handleDialogClose}
                handleSubmit={this.handleSubmit}
              >
                {error ? <CustomErrorMsg /> : submitted && <SignatureProcess />}
              </ContractElectronicNotificationDialog>
              <Grid
                container
                direction="row"
                justifycontent="flex-start"
                alignItems="center"
              >
                <div style={{ display: "flex" }}>
                  <div>
                    {currentElecNotifStatus === "active" ? (
                      <Check
                        color={"primary"}
                        style={{ verticalAlign: "middle", marginRight: "10px" }}
                      />
                    ) : (
                      <Close
                        color={"primary"}
                        style={{ verticalAlign: "middle", marginRight: "10px" }}
                      />
                    )}
                  </div>
                  <div style={{ fontSize: "1.5rem", fontColor: "black" }}>
                    {currentElecNotifStatus === "active"
                      ? i18n.t('common:text.electronic_notification_enabled_title')
                      : i18n.t('common:text.electronic_notification_disabled_title')}
                  </div>
                </div>
                {currentElecNotifStatus === "unactive" && (
                  <div>
                    <Button
                      variant={"text"}
                      color={"primary"}
                      title={i18n.t('common:text.electronic_notification_disabled')}
                      onClick={() => this.handleDialogOpen()}
                    >
                      {i18n.t('common:text.electronic_notification_disabled')}
                    </Button>
                  </div>
                )}
              </Grid>
            </div>
            {currentElecNotifStatus === 'requested' && (
              <div style={{ fontSize: "12px" }}>
                <em>{i18n.t('common:text.electronic_notification_process_already')}</em>
              </div>
            )}
          </div>
        );
    }
}

export default connect(mapStateToProps, { setElectronicNotification })(ContractElectronicNotification);
