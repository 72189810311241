const style = {
  container: {
    marginTop: '12px'
  },
  label: {
    fontSize: '12px',
    fontWeight: 'normal',
    color: 'rgba(0, 0, 0, 0.3)',
    marginBottom: '2px'
  }
};

const TextFieldReadOnly = (props) => {
  return (
    <div style={style.container}>
      <label style={style.label}>{props.label}</label>
      <div>{props.children}</div>
    </div>
  )
};

export default TextFieldReadOnly;
