import React from 'react';
import { SvgIcon } from '@mui/material';

const GasIcon = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 50 50">
            <path className="cls-1" d="M30.63,48.54a1.21,1.21,0,0,1-1-.5,1.22,1.22,0,0,1,.06-1.54c3.74-4.26,2.26-10.56.93-14.15a4,4,0,0,1-2.09,1.35,1.18,1.18,0,0,1-.95-.19,1.21,1.21,0,0,1-.51-.84A9.73,9.73,0,0,0,20.75,25c.47,2.11.24,5.5-2.4,10.73S17.18,44.33,18,46.16l.15.34a1.23,1.23,0,0,1-1.69,1.57C10.65,45.13,7.17,41.32,6.1,36.74c-1.92-8.2,4.42-16.68,7.12-20.31A21.36,21.36,0,0,0,17.13,2.82a1.23,1.23,0,0,1,.45-1.08,1.2,1.2,0,0,1,1.15-.22c.45.15,10.5,3.5,13.36,13.28.71-.86,1.5-2.54,1.47-5.75a1.23,1.23,0,0,1,.81-1.17,1.25,1.25,0,0,1,1.38.4c.45.58,11,14.27,8,26.66C42.27,40.79,38,45.33,31.13,48.43A1.25,1.25,0,0,1,30.63,48.54Zm-11-44A23.39,23.39,0,0,1,15.19,17.9c-3.9,5.21-8.17,12-6.7,18.28.71,3.07,2.8,5.77,6.22,8a13.73,13.73,0,0,1,1.45-9.65c4.18-8.26,1.42-10.62,1.4-10.64a1.26,1.26,0,0,1-.38-1.46,1.23,1.23,0,0,1,1.29-.74c.09,0,8.26,1.28,10.57,8.51a3.7,3.7,0,0,0,.23-1.3,1.24,1.24,0,0,1,.94-1.16,1.22,1.22,0,0,1,1.37.6c.22.41,4.68,8.72,2.24,15.82a16.34,16.34,0,0,0,7.51-9.85c2-8.16-2.81-17.35-5.64-21.83-1,4.61-3.92,5.44-4.07,5.48a1.19,1.19,0,0,1-1-.15,1.23,1.23,0,0,1-.55-.81C28.76,9.74,22.63,6,19.63,4.55Z"/>
        </SvgIcon>   
    );
}

export default GasIcon;