import {
    Avatar,
    CircularProgress,
    ListItem,
    ListItemAvatar,
    ListItemText,
    useTheme,
} from "@mui/material";
import { i18n } from "../../config";

/**
 * UI component
 *
 * Responsible for rendering the provided percentage data.
 */
const VirtualBatteryPercentageCard = ({ percentageData, active, style }) => {
    style = style ?? {};
    const theme = useTheme();

    return (
        <>
            {percentageData && (
                <ListItem style={{ width: "50%", ...style }} selected={active}>
                    <ListItemAvatar>
                        <Avatar>
                            <CircularProgress
                                style={{
                                    backgroundColor:
                                        theme.palette.grey[
                                        theme.palette.type === "light" ? 200 : 700
                                        ],
                                }}
                                variant="determinate"
                                value={parseFloat(percentageData.percentage)}
                            />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={`${i18n.t("common:text.bateria_virtual_contract")}: ${percentageData.polissa_name
                            }`}
                        secondary={`${percentageData.percentage}%`}
                    />
                </ListItem>
            )}
        </>
    );
};

export default VirtualBatteryPercentageCard;
