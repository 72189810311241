import _ from "lodash";
import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as actionCreators from "../../actions/gasContractation";
import { i18n } from "../../config";
import { LoadingAnimation } from '../LoadingAnimation';
import RichContent from "../RichContent";
import Settings from "../../settings";

import { red } from '@mui/material/colors';
import { Grid } from "@mui/material"
import { SelectCard } from "@gisce/oficina-virtual-components";

function mapStateToProps(state) {
  return {
    ...state.gasContractation
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

class GasProductSelect extends React.Component {

  fetchAvailableProducts = async () => {
    if (this.props.cups && this.props.accessTariff.id) {
      this.props.fetchAvailableGasProducts(this.props.cups, this.props.accessTariff.id);
    }
  };

  componentDidMount() {
    this.fetchAvailableProducts();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.cups !== prevProps.cups){
      this.fetchAvailableProducts();
    }
  }

  handleChange = (event, value) => {
    const selected = this.props.availableProducts.filter((t) => t.id === Number(value));
    const product = selected[0];
    this.props.selectGasProduct(product);
    if (typeof this.props.onChange === "function") {
      this.props.onChange(null, product);
    }
  };

  renderProduct = (t) => {
    return <SelectCard
      id={t.id}
      name={t.name}
      description={t.ov_description}
      onChange={this.handleChange}
      selected={this.props?.selectedProduct?.id === t.id}
    />
  };

  renderAvailableProducts = () => {
    let products = [];
    if (!_.isEmpty(this.props.availableProducts)) {
      products = this.props.availableProducts.map((t) => {
        return (
          <Grid item xs={12} md={6} key={t.id}>
            {this.renderProduct(t)}
            {t.ov_link && (
              <p>
                <a target="_blank" rel="noreferrer" href={t.ov_link}>
                  + info
                </a>
              </p>
            )}
          </Grid>
        );
      });
    }
    return products;
  };

  render() {
    const { selectedProduct } = this.props;
    const noAvailProductsTemplate = _.template(
      i18n.t('common:text.contractation_no_available_tariffs')
    );
    return this.props.isFetching ? (
      <LoadingAnimation />
    ) : (
      <div>
        <Grid container spacing={3}>
          {this.renderAvailableProducts()}
        </Grid>
        {_.isEmpty(this.props.availableProducts) && (
            <div style={{ color: red[500] }}>
              <RichContent
                content={noAvailProductsTemplate({
                  phone: Settings.organization.phone_crm,
                  phoneLink: Settings.organization.phone_crm.replace(/\s/g, ''),
                })}
              />
            </div>
          )}
      </div>
    );
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(GasProductSelect);
