import {
  FETCHING_VIRTUAL_BATTERY_TYPES,
  FETCH_BATERIA_VIRTUAL_PERCENTAGES,
  RECEIVE_BATERIA_VIRTUAL_PERCENTAGES,
  RECEIVE_VIRTUAL_BATTERY_TYPES,
  SET_BATERIA_PERCENTATGE_DONE,
  SET_BATERIA_PERCENTATGE_ERROR,
  SET_BATERIA_PERCENTATGE_REQUEST,
  SET_MOVE_BATERIA_DONE,
  SET_MOVE_BATERIA_ERROR,
  SET_MOVE_BATERIA_REQUEST,
  UPDATED_VIRTUAL_BATTERY_TYPES,
  UPDATING_VIRTUAL_BATTERY_TYPES,
  EXPORT_VIRTUAL_BATTERIES_REQUEST,
  EXPORT_VIRTUAL_BATTERIES_DONE,
  MESSAGE_VIRTUAL_BATTERIES,
} from '../constants/index';
import {
  data_fetch_api_resource,
  data_update_api_resource,
  data_download_api_resource
} from '../utils/http_functions';
import Settings from '../settings';
import saveAs from 'file-saver';
import { i18n } from "../config";
import { syncBateriaVirtuals, setContractItems } from './contact';
import { syncSingleContract } from './contracts';

export function setVirtualBatteryPercentage(contract_name, bateria_id, percentatge) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { token } = getState().auth;
      dispatch({ type: SET_BATERIA_PERCENTATGE_REQUEST, payload: null });
      data_update_api_resource(
          token,
          `contract/${contract_name}/change_bateria_pes_percentatge/${bateria_id}`,
          { pes_percentatge: percentatge },
          2
        )
      .then((response) => {
        dispatch({type: SET_BATERIA_PERCENTATGE_DONE, payload: null});
        resolve(true);
      })
      .catch ((error) => {
        const msgError = error.message || "An error occurred"
        dispatch({type: SET_BATERIA_PERCENTATGE_ERROR, payload: msgError});
        reject(msgError);
      })
    });
  }
}

export function moveVirtualBatteries(contractName, bateriaOrigenId, bateriaDestiId, percentatge) {
  return (dispatch, getState) => {

    return new Promise((resolve, reject) => {
      const { token } = getState().auth;
      dispatch({ type: SET_MOVE_BATERIA_REQUEST, payload: null });
      data_update_api_resource(
          token,
          `contract/${contractName}/bateria_virtual_move_virtual_battery/${bateriaOrigenId}`,
          { pes_percentatge: percentatge,
            bateria_desti: bateriaDestiId
          },
          2
        )
      .then((response) => {
        const updatedContract = response.data;
        dispatch(syncSingleContract(updatedContract));

        dispatch({type: SET_MOVE_BATERIA_DONE, payload: null});
        resolve(true);
      })
      .catch ((error) => {
        const msgError = error.message || "An error occurred"
        dispatch({type: SET_MOVE_BATERIA_ERROR, payload: msgError});
        reject(msgError);
      })
    });
  }

}

export const fetchVirtualBatteryTypes = () => async (dispatch, getState) => {
  const { token } = getState().auth;
  dispatch({
    type: FETCHING_VIRTUAL_BATTERY_TYPES,
    payload: null
  });

  return data_fetch_api_resource(token, 'contract/bateria/types', 2)
    .then( response => {
      dispatch({
        type: RECEIVE_VIRTUAL_BATTERY_TYPES,
        payload: response.data
      });
    });
}

export function createBateriaVirtualPercentagesRequest() {
  return {
    type: FETCH_BATERIA_VIRTUAL_PERCENTAGES,
    payload: null
  }
}

export function receiveBateriaVirtualPercentages(data) {
  return {
    type: RECEIVE_BATERIA_VIRTUAL_PERCENTAGES,
    payload: data
  }
}

export function fetchBateriaVirtualPercentages(bateria_id) {
  return (dispatch) => {
    dispatch(createBateriaVirtualPercentagesRequest());
    data_fetch_api_resource(null, `contract/bateria_virtual_percentages/${bateria_id}`, 2)
     .then( response => {
        if (response.status === 200) {
          dispatch(syncBateriaVirtuals(response.data)) // WORKAROUND: Quan tinguem el refactor de pre-alpha fet modifquem aixo, se li ha de donar una volta, si es modifica la bateria virtual sha de forçar un update? o al canviar de contracte sha de descarregar de nou amb les noves dades(per mi millor aproeach crec, entrar a contract/id i que aquest actualitzi de la llista el contract ja quehi som? s'ha de mantenir una action per fer que actulitzi tots els contractes?
          dispatch(receiveBateriaVirtualPercentages(response.data));
        } else {
          dispatch(receiveBateriaVirtualPercentages([]));
        }
      });
  }
}

export const updateVirtualBatteryTypes = (selectedValue, bateria_id) => async (dispatch, getState) => {
  //TODO: Aquesta funcio s'hauria de repassar(a la pre-alpha, es pot reordenar millor i afegir el control d'error, no nomes un print)
  const { token } = getState().auth;
  const { view_contract, items } = getState().contracts;
  dispatch({
    type: UPDATING_VIRTUAL_BATTERY_TYPES,
    payload: {
      loading: true
    }
  });

  try{
    data_update_api_resource(token, `contract/${view_contract.name}/bateria/${bateria_id}`, {valor_descomptes: selectedValue}, 2)
    .then(response => {
      dispatch({
        type: UPDATED_VIRTUAL_BATTERY_TYPES,
        payload: {
          loading: false,
          bateria_id: bateria_id,
          change: selectedValue
        }
      });

      let syncedItems = [...items];
      let contract2update = syncedItems.find((c) => c.id === view_contract?.id);
      if (contract2update) {
        for (let bateria_virtual of contract2update.bateria_virtual) {
          if ( bateria_virtual.bateria.id === bateria_id) {
            bateria_virtual.gestio_descomptes = selectedValue;
          }
        }
      }
      dispatch(setContractItems(syncedItems));

    })
  } catch (e){
      console.log('Error updateVirtualBatteryTypes:' + e)
  }
}

export function exportVirtualBatteries(token, batteries) {
  return (dispatch) => {

      dispatch(exportVirtualBatteriesRequest(batteries));
      if (Settings?.invoices?.downloadInNewTab ?? false) {
          let newTab = window.open(i18n.t('common:url.loading'), '_blank');
          return data_download_api_resource(token, "contracts/virtual_batteries/" + batteries)
              .then(response => response.data)
              .then(blob => URL.createObjectURL(blob))
              .then(anUrl => {
                  newTab.location.href = anUrl;
                  URL.revokeObjectURL(i18n.t('common:url'));
              })
              .catch(error => {
                  // if (error.status === 401) {
                  //     dispatch(logoutAndRedirect(error));
                  // }
              })
              .finally(() => {
                  dispatch(exportVirtualBatteriesDone(batteries));
              });
      } else {
          data_download_api_resource(token, `contracts/virtual_batteries/${ batteries }`)
            .then(response => {
                const filename = response.headers["content-disposition"].split("=");
                dispatch(messageVirtualBatteries(i18n.t('common:text.invoices_download_ready')));
                saveAs(response.data, filename[1]);
            })
            .catch(error => {
              // if (error.status === 401) {
              //       dispatch(logoutAndRedirect(error));
              //   }
            })
            .finally(() => {
                dispatch(exportVirtualBatteriesDone(batteries));
            });
      }
  };
}

export function exportVirtualBatteriesRequest(batteries) {
  return {
      type: EXPORT_VIRTUAL_BATTERIES_REQUEST,
      payload: {
          batteries,
          message: null,
      },
  };
}

export function exportVirtualBatteriesDone(batteries) {
  return {
      type: EXPORT_VIRTUAL_BATTERIES_DONE,
      payload: {
        batteries
      },
  };
}


export function messageVirtualBatteries(message) {
  return {
      type: MESSAGE_VIRTUAL_BATTERIES,
      payload: {
          message
      }
  }
}
