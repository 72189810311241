import useVirtualBattery from "../useVirtualBattery";

export const useBatteryDistributionPreview = () => {
  const bateriaVirtualTool = useVirtualBattery();

  const getExpectedDistributionWhenChangedPercentage = ({contracts, editedBatteryId, contractUpdated, newPercentage, forceCurrentName}) => {
    let newExpectedDistribution = [];
    if (editedBatteryId) {
      const newBatteryDistribution = bateriaVirtualTool.getBatteryDistribution({
          contracts,
          batteryId: editedBatteryId
      });

      // INFO: Remove from the distribution the contract being updated to avoid conflict with the new percentage
      newBatteryDistribution.distribution = newBatteryDistribution.distribution.filter((x) => {
        return x.contractId !== contractUpdated
      });

      // INFO: Compute the expected distribution including the newPercentage
      newExpectedDistribution = computeExpectedDistribution({
        currentBatteryDistribution: newBatteryDistribution,
        newPercentage,
        includeCurrent: true
      });

      const contractVirtualBattery = newExpectedDistribution["distribution"].find((x) => x.contractName === "current");
      if (contractVirtualBattery)
        contractVirtualBattery.contractName = forceCurrentName
    }
    return newExpectedDistribution;
  };

  const getExpectedDistributionWhenMovedBattery = ({contracts, movedBatteryId, newPercentage, forceCurrentName}) => {
    let newExpectedDistribution = [];
    if (movedBatteryId) {

      // INFO: Get the info for the future "new" battery
      const newBatteryDistribution = bateriaVirtualTool.getBatteryDistribution({
          contracts,
          batteryId: movedBatteryId
      });

      newExpectedDistribution = computeExpectedDistribution({
        currentBatteryDistribution: newBatteryDistribution,
        newPercentage,
        includeCurrent: true
      });

      const contractVirtualBattery = newExpectedDistribution["distribution"].find((x) => x.contractName === "current");
      if (contractVirtualBattery)
        contractVirtualBattery.contractName = forceCurrentName
    }
    // INFO: Return a new object combining the original battery distribution with the updated expected distribution.
    return newExpectedDistribution;
  };


  const getRemovedExepectedDistribution = ({contracts, targetBatteryId, removedContractId}) => {
    let newExpectedDistribution = [];
    if (targetBatteryId && removedContractId) {
      // INFO: Get the info for the future "new" battery
      const newBatteryDistribution = bateriaVirtualTool.getBatteryDistribution({
          contracts,
          batteryId: targetBatteryId,
      });

      // INFO: Remove from the distribution the removed contract
      newBatteryDistribution.distribution = newBatteryDistribution.distribution.filter((x) => {
        return x.contractId !== removedContractId
      });

      newExpectedDistribution = computeExpectedDistribution({
        currentBatteryDistribution: newBatteryDistribution,
        newPercentage: 0,
        includeCurrent: false
      });
    }
    // INFO: Return a new object combining the original battery distribution with the updated expected distribution.
    return newExpectedDistribution;
  };


  const getExpectedDistribution = ({expectedAction,  ...rest}) => {
    let batteryDistribution;
    if (expectedAction === "move") {
      batteryDistribution = getExpectedDistributionWhenMovedBattery(rest);
    } else if (expectedAction === "changePercentage") {
      batteryDistribution = getExpectedDistributionWhenChangedPercentage(rest);
    } else if (expectedAction === "removed") {
      batteryDistribution =  getRemovedExepectedDistribution(rest);
    } else {
      throw new Error(`The expectedAction: ${expectedAction} provided is not implemented`);
    }

    if (batteryDistribution) {
      batteryDistribution["distribution"] = bateriaVirtualTool.sortDistribution({distribution: batteryDistribution["distribution"]});
    }
    return batteryDistribution;
  };



  const computeExpectedDistribution = ({currentBatteryDistribution, newPercentage, includeCurrent=true}) => {
    const newExpectedDistribution = [];
    // INFO: Compute the future 100% percentage of current battery weights compared to the new percentage.
    const otherBVNewPercentage = 100 - newPercentage;
    //
    // INFO: Calculate the sum of weights from the current battery distribution.
    const otherBVWeightSum = currentBatteryDistribution["distribution"].reduce(
      (acumulator, distribution) => distribution["pes"] + acumulator,
      0
    );

    // INFO: Compute the new weight based on the new percentage and the sum of existing weights.
    const newWeight = (otherBVWeightSum * newPercentage) / otherBVNewPercentage;

    // INFO: Calculate the total weight including the new weight.
    const newWeightSum = otherBVWeightSum + newWeight;

    // INFO:Iterate through each distribution entry to compute the new expected distribution percentages.
    for (const distribution of currentBatteryDistribution["distribution"]) {
      newExpectedDistribution.push({
        contractName: distribution["contractName"],
        pes: distribution["pes"],
        pesPercentage: ((distribution["pes"] / newWeightSum) * 100).toFixed(2),
      });
    }
    // INFO: Add an entry for the new weight with its percentage to the expected distribution.
    if (includeCurrent) {
      newExpectedDistribution.push({
        contractName: "current",
        pes: newWeight,
        pesPercentage: newPercentage.toFixed(2),
        current: true
      });
    }
    // INFO: Return a new object combining the original battery distribution with the updated expected distribution.
    return {
      ...currentBatteryDistribution,
      distribution: newExpectedDistribution,
    };
  };

  return {
    getExpectedDistribution
  };
};

export default useBatteryDistributionPreview;
