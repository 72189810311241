import { useForm, Controller } from "react-hook-form"
import { z } from "zod";
import { zodResolver } from '@hookform/resolvers/zod';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import { MuiFileInput } from 'mui-file-input'
import Settings from "../../settings";
import Cookies from '../../utils/cookies';
import { marketingHook } from "../../overrides/hooks/lead-contact";

import { Button, TextField, InputLabel,
  FormControl, Select, MenuItem, Grid, Typography,
  FormControlLabel, Checkbox, FormHelperText, 
  styled
} from "@mui/material";
import { RichContent } from "@gisce/oficina-virtual-components";
import TelephoneIcon from "./icons/telephone.svg";
import EmailIcon from "./icons/email.svg";
import { AttachFile, Close } from "@mui/icons-material";
import { contraction_url } from '../../constants/i18n';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FC } from "react";
import { LeadContactSubmitData } from "@/types/models/services/leads";

export type LeadContactFormData = LeadContactSubmitData;

interface ILeadContactProps {
  changePowerHelp?: boolean;
  attachInvoice?: boolean;
  leadSubmit: (personalData: LeadContactFormData) => void;
}

const LeadContact: FC<ILeadContactProps> = ({
  changePowerHelp = true, 
  attachInvoice = false, 
  leadSubmit
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const schema = z.object({
    name: z.string().min(1, t('common:text.required_field')),
    surname1: z.string().min(1, t('common:text.required_field')),
    surname2: z.string(),
    email: z.string()
      .min(1, t('common:text.required_field'))
      .email({ message: t('common:text.contractation_email_not_valid') }),
    telephone: z.string()
      .min(4, t('common:text.required_field'))
      .refine(
        (tel) => matchIsValidTel(tel), 
        {message: t('common:text.lead_contact_phone_not_valid')}
      ),
    contactHours: z.string().min(1, t('common:text.required_field')),
    ...(attachInvoice && 
      {
        invoice: z.instanceof(File, {message: t('common:text.required_field')})
        .refine((file) => {
          return file.size <= 15000000;
        }, 'File size must be less than 15MB')
      }
    ),
    consent: z.boolean().refine((val) => val === true, {
      message: t('common:text.lead_contact_consent_required'),
    })
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LeadContactFormData>({
    resolver: zodResolver(schema)
  });

  const contactHours = [
    { value: '9-13h', name: t('common:text.morning') + ' (9-13h)' },
    { value: '13-15h', name: t('common:text.noon') + ' (13-15h)' },
    { value: '15-19h', name: t('common:text.afternoon') + ' (15-19h)' },
    { value: '19-21h', name: t('common:text.evening') + ' (19-21h)' },
  ];

  const handleLeadContactSubmit = (personalData: LeadContactFormData) => {
    leadSubmit(personalData);

    if (Cookies.marketingConsentGiven()) {
      marketingHook();
    }
  };

  const handleGoToContract = () => {
    navigate(t(contraction_url));
  };

  const handleGoToDashboard = () => {
    navigate('/');
  };

  const NoFlagMuiTelInputStyled = styled(MuiTelInput)`
    .MuiTelInput-IconButton {
      display: none;
    }
  `
  return (
    <>
      <div className="lead-contact-form">
        { changePowerHelp &&
          <div className="left-column">
            <div>
              <p>
                <strong>
                  {t('common:text.lead_contact_chpower_already_client')}
                </strong>
              </p>
              <Button
                color={'primary'}
                variant={'contained'}
                onClick={handleGoToDashboard}
              >
                {t('common:text.lead_contact_dashboard')}
              </Button>
            </div>
            <div>
              <p>
                {t('common:text.lead_contact_chpower_no_client')}
              </p>
              <Button
                variant={'contained'}
                color="secondary"
                className="fancy-secondary-btn"
                onClick={handleGoToContract}
              >
                {t('common:text.lead_contact_contract')}
              </Button>
            </div>
          </div> 
        }
        <form className="form" onSubmit={handleSubmit(handleLeadContactSubmit)}>
          <div>
            <TextField
              label={t('common:text.lead_contact_name')}
              {...register("name")}
              autoComplete="given-name"
              fullWidth={true}
              error={!!errors.name}
            />
            {errors.name?.message && 
              <FormHelperText error={true}>
                {errors.name.message}
              </FormHelperText>
            }
          </div>
          <div>
            <TextField
              label={t('common:text.lead_contact_surname1')}
              {...register("surname1")}
              autoComplete="family-name"
              fullWidth={true}
              error={!!errors.surname1}
            />
            {errors.surname1?.message && 
              <FormHelperText error={true}>
                {errors.surname1.message}
              </FormHelperText>
            }
            <TextField
              label={t('common:text.lead_contact_surname2')}
              {...register("surname2")}
              autoComplete="family-name"
              fullWidth={true}
            />
          </div>
          <div>
            <TextField
              label={t('common:text.lead_contact_email')}
              {...register("email")}
              fullWidth={true}
              error={!!errors.email}
            />
            {errors.email?.message &&
              <FormHelperText error={true}>
                {errors.email.message}
              </FormHelperText>
            }
          </div>
          <div>
            <Controller
              name="telephone"
              control={control}
              render={({ field }) => (
                <NoFlagMuiTelInputStyled
                  {...field}
                  disableDropdown={true}
                  label={t('common:text.lead_contact_telephone')}
                  forceCallingCode={true}
                  defaultCountry="ES"
                  helperText={errors.telephone?.message}
                  fullWidth={true}
                  error={!!errors.telephone?.message}
                />
              )}
            />
          </div>
          <div>
            <FormControl fullWidth={true}>
              <InputLabel shrink htmlFor="">
                {t('common:text.lead_contact_hours') + "*"}
              </InputLabel>
              <Select
                {...register("contactHours")}
                defaultValue={""}
                className="select-field"
                error={!!errors.contactHours?.message}
              >
                {contactHours.map((c, idx) => {
                  return (
                    <MenuItem key={idx} value={c.value}>{c.name}</MenuItem>
                  );
                })}
              </Select>
              {errors.contactHours?.message &&
                <FormHelperText error={true}>
                  {errors.contactHours.message}
                </FormHelperText>
              }
            </FormControl>
          </div>
          { attachInvoice &&
            <div className="attachment">
              <Controller
                name="invoice"
                control={control}
                render={({ field }) => (
                  <MuiFileInput
                    {...field}
                    helperText={errors.invoice?.message}
                    label={t('common:text.lead_contact_invoice_attachment')}
                    fullWidth
                    InputProps={{
                      startAdornment: <AttachFile />
                    }}
                    clearIconButtonProps={{
                      title: "Remove",
                      children: <Close fontSize="small" />
                    }}
                    error={!!errors.invoice?.message}
                  />
                )}
              />
            </div>
          }
          <div style={{ textAlign: "left" }}>
            <FormControlLabel
              control={
                <Checkbox
                  {...register("consent")}
                />
              }
              label={
                <RichContent
                  content={t(
                    'common:text.lead_contact_consent',
                    {
                      link: Settings?.links?.calculatorConditions,
                    }
                  )}
                />
              }
            />
            {errors.consent?.message && 
              <FormHelperText error={true}>
                {errors.consent.message}
              </FormHelperText>
            }
          </div>
          <div className="submit-btn">
            <Button
              variant={'contained'}
              color="secondary"
              className="fancy-secondary-btn"
              type="submit"
            >
              {t('common:text.lead_contact_submit')}
            </Button>
          </div>
        </form>
      </div>
      <Grid container spacing={1} direction="row" alignItems="center" sx={{p: 3, pb: 0}}>
        <Grid item xs={4} sm={2} md={1}>
          <svg style={{height: 60, width: 60}}><TelephoneIcon /></svg>
        </Grid>
        <Grid item xs={8} sm={4} md={5}>
          <Typography variant="body1" style={{fontWeight: "bold", color: "gray"}}>
            {Settings.organization.phone_crm || ''}
          </Typography>
          <Typography variant="body2" style={{color: "gray"}}>
            {Settings.organization.schedule || ''}
          </Typography>
        </Grid>
        <Grid item xs={4} sm={2} md={1}>
          <svg style={{height: 60, width: 60}}><EmailIcon /></svg>
        </Grid>
        <Grid item xs={8} sm={4} md={5}>
          <Typography variant="body1" style={{fontWeight: "bold", color: "gray"}}>
            {Settings.organization.email || ''}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default LeadContact;
