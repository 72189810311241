import { Field, Form } from "react-final-form";
import { useSelector } from "react-redux";
import { Typography, Grid, Box, Divider } from "@mui/material";
import { Button } from "@gisce/oficina-virtual-components"; 
import CheckboxField from "../form-fields/CheckboxField";
import IBANField, { IBANvalid } from "../form-fields/IBANField";
import { useTranslation } from "react-i18next";


const FormPayment = ({onGoBack, onSubmit}) => {
  const { t } = useTranslation();
  const elecContractration = useSelector((state) => state.electricityContractation);

  const handleSubmit = async (values) => {
    values = Object.assign({}, elecContractration, values)
    await onSubmit(values)
  };

  return (
    <div>
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          iban: elecContractration.iban,
          isOwner: elecContractration.isOwner,
        }}
        validate={(values) => {
          const errors = {};

          if (!values.iban) {
            errors.iban = t('common:text.required_field');
          } else if (!IBANvalid(values.iban)) {
            errors.iban = t('common:text.iban_field_invalid');
          }

          if (!values.isOwner) {
            errors.isOwner = t('common:text.required_field');
          }

          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <Grid container sx={{mr: {md: 5}, ml: {md: 5}}}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  {t('common:text.contractation_iban_full_name')}
                </Typography>
                <Grid item xs={12} md={6}>
                  <Field
                    name="iban"
                    component={IBANField}
                    style={{width: "100%"}}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="isOwner"
                  label={
                    <Typography variant="body1" style={{color: "black"}}>
                      {t('common:text.contractation_owner_confirmation')}
                    </Typography>
                  }
                  component={CheckboxField}
                />
              </Grid>
            </Grid>

            <Divider sx={{mt: 3, mb: 3}}/>
            <Button
              variant={'text'}
              onClick={onGoBack}
              style={{ marginRight: 12 }}
              disabled={submitting}
            >
              {t('common:text.contractation_previous')}
            </Button>
            <Button
              type="submit"
              color={'primary'}
              variant={'contained'}
              loading={submitting}
            >
              {t('common:text.contractation_next')}
            </Button>

          </form>
        )}
      />
    </div>
  );
};

export default FormPayment;
