import React from 'react';
import { i18n } from "../../config";
import _ from "lodash";

import { TextField, FormHelperText } from '@mui/material';

const CHECKSUM_TABLE = 'TRWAGMYFPDXBNJZSQVHLCKE';
export const cupsValid = (cups) => {
  if (!cups || (cups.length !== 20 && cups.length !== 22)) {
    return false;
  }
  const cups_number = cups.slice(2, 18);
  const rest0 = parseInt(cups_number) % 529;
  const cof = parseInt(rest0 / 23);
  const rest1 = rest0 % 23;
  const valid_cups = `ES${cups_number}${CHECKSUM_TABLE[cof]}${CHECKSUM_TABLE[rest1]}`;
  
  if (valid_cups !== cups.slice(0, 20)) {
    return false;
  }

  // If CUPS has the optional 2 last digits, validate it.
  if (cups.length === 22) {
    const [pnumber, ptype] = cups.slice(20, 22).split('');
    if (!Number.isInteger(parseInt(pnumber))) {
      // N-Digit is not a number.
      return false;
    }
    if ('FPRCXYZ'.indexOf(ptype) < 0) {
      // T-Digit is not valid.
      return false;
    }
  }

  return true;
};

class CUPSField extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      error: this.props.error,
      cups: this.props.value,
    };
  }

  handleOnChange = (event, newValue) => {
    const value = event.target.value
      .replace(/\s/g, "")
      .replace(/o|O/g, "0")
      .toUpperCase();

    if (this.props.onChange) {
      this.props.onChange(event, value);
    }
    if (this.props.onUpdate) {
      this.props.onUpdate(value);
    }
    // this.setState({cups: value});
  };

  // TODO: Check why getDerivedStateFromProps now isn't being called WTF? Ah maybe it does. idk anymore
  static getDerivedStateFromProps(props, state) {
    if (state.cups !== props.value) {
      return { cups: props.value };
    };
    if (state.error !== props.error) {
      return {
        error: props.error || false
      };
    };

    return null;
  }

  render() {
    return (
      <div>
        <TextField
          label={this.props.label || i18n.t('common:text.contractation_cups')}
          style={this.props.style}
          placeholder={this.props.placeholder || i18n.t('common:text.contractation_cups_placeholder')}
          onChange={this.handleOnChange}
          error={this.state.error}
          value={this.state.cups}
        />
        { this.state.error &&
          <FormHelperText error={true} id="reclam-tipus-error-text">
            {this.props.error}
          </FormHelperText>
        }
      </div>
    );
  }
}

export default CUPSField;

CUPSField.defaultProps = {
  label: '',
  placeholder: '',
  style: {},
};
