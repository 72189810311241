import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import _ from 'lodash';
import { getLang, resources } from "../../i18n";
import Settings from "../../settings";
import 'dayjs/locale/ca';
import 'dayjs/locale/es';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import minMax from 'dayjs/plugin/minMax';
import dayjs from "dayjs";


i18n
  .use(initReactI18next)
  .init({
    resources,
    debug: import.meta.env.DEV,
    lng: getLang(),
    ns: [
      'common', 'validation'
    ],
    defaultNs: 'common',
  });

document.documentElement.lang = i18n.language;

dayjs.locale(i18n.t("common:locale.code")); // TODO: Change on lang change & check that everything works
dayjs.extend(localizedFormat);
dayjs.extend(minMax);

export {i18n, dayjs};
