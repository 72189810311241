
import { Button } from "@gisce/oficina-virtual-components";
import { useDispatch } from "react-redux";
import { GetApp } from "@mui/icons-material";
import { exportInvoice as exportInvoiceElectricity } from "../../actions/invoices";
import { exportInvoice as exportInvoiceMultipoint } from "../../actions/invoicesMultipoint";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const DownloadButton = ({ invoiceId, type }) => {
  const dispatch = useDispatch();
  const downloadStatus = useSelector((state) => state.invoices[type].download_status[invoiceId]);
  const token = useSelector(state => state.auth.token);
  const { t } = useTranslation();

  const handleDownload = () => {
    if (type === "multipoint") {
      dispatch(exportInvoiceMultipoint(token, invoiceId));
    }
    if (type === "electricity") {
      dispatch(exportInvoiceElectricity(token, invoiceId));
    }
  }

  return (
    <Button
      title={"Download invoice"}
      startIcon={<GetApp />}
      color={'primary'}
      variant={'contained'}
      onClick={() => handleDownload()}
      style={{ marginTop: '5px', marginLeft: '5px', marginRight: '5px', overflow: 'hidden' }}
      loading={downloadStatus}
    >
      {t('common:text.invoices_view_download_button')}
    </Button>
  )
}