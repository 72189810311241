import React from "react";
import { useSelector } from "react-redux";
import { Button } from "@mui/material"
import _ from "lodash";
import { i18n } from "../../config";
import { LoadingAnimation } from "../LoadingAnimation";
import RichContent from "../RichContent";
import { useTranslation } from "react-i18next";


const Confirmed = ({onGoBack}) => {
  const elecContractation = useSelector((state) => state.electricityContractation);
  const { t } = useTranslation();

  return (
    <>
      {elecContractation?.isConfirming ? (
        <LoadingAnimation />
      ) : (
        elecContractation?.confirmResponse?.status === "OK" ? (
          elecContractation?.confirmResponse?.result?.render?.body ? (
            <RichContent
              content={elecContractation.confirmResponse.result.render.body}
              html={elecContractation.confirmResponse.result.render.format === "html"}
            />
          ) : (
          <div>
            {!elecContractation.company ? t('common:text.contractation_form_sent_business') : t('common:text.contractation_form_sent')}
          </div>
          )
        ) : (
          elecContractation?.confirmResponse?.result?.error && 
            <Button
              variant={'text'}
              onClick={onGoBack}
              style={{ marginRight: 12 }}
            >
              {t('common:text.contractation_previous')}
            </Button>
        )
      )}
    </>
  );
};

export default Confirmed;
