import { 
  IOIDCSettings,
  LoginRequestResponse,
  OIDCLoginParam, 
  OIDCRedirectParam, 
  OIDCState 
} from "@/types/models/hooks";
import { randomString, generateHash } from '../../utils/misc';
import crypto from "crypto";
import * as jose from "jose";
import Cookies from "js-cookie";
import { 
  define_token, 
  openid_login, 
  undefine_token 
} from "@/utils/http_functions";
import { 
  LOGIN_OPENID_FAILURE, 
  LOGIN_OPENID_REQUEST, 
  LOGIN_OPENID_SUCCESS 
} from "@/constants";
import { default as cookiesUtils } from '../../utils/cookies';
import Settings from "@/settings";
import { contraction_url, home_url } from "@/constants/i18n";
import queryString from "query-string";
import { default as openidService } from "@/services/openid";
import { getLang, getLangByToken } from "@/i18n";
import { Dispatch } from "redux";

export function loginOpenIdRequest() {
  return {
    type: LOGIN_OPENID_REQUEST,
  };
}

export type LoginOpenIdSuccessProps = {
  token: string;
  openIdToken: string;
  redirect?: string;
  redirectSearchParams?: string;
  newCustomer?: boolean;
}

export const loginOpenIdSuccess = ({
  token, 
  openIdToken, 
  redirect = "", 
  redirectSearchParams = "",
  newCustomer = false
}: LoginOpenIdSuccessProps
  
) => {
  Cookies.remove('openid_nonce');
  Cookies.set('openid_token', openIdToken, { secure: true, sameSite: 'Lax' });
  define_token(token);

  return{
    type: LOGIN_OPENID_SUCCESS,
    payload: {
      token,
      openIdToken,
      redirect,
      redirectSearchParams,
      newCustomer
    },
  };
}

export const loginOpenIdFailure = ({
  redirect = "", 
  statusCode = 403, 
  statusText = '', 
  statusType = "error" 
}) => {
  Cookies.remove('openid_nonce');
  Cookies.remove('openid_token');
  undefine_token();

  return {
    type: LOGIN_OPENID_FAILURE,
    payload: {
      redirect,
      statusCode,
      statusText,
      statusType
    },
  };
}

export const generateAndSaveState = async (statePayload: OIDCState) => {
  // Per generar una secret key fes córrer: openssl rand -base64 32
  const key = crypto.randomBytes(32).toString('hex');
  const secret = new TextEncoder().encode(key)
  const jwt = await new jose.SignJWT(statePayload)
    .setProtectedHeader({ alg: 'HS256' })
    .sign(secret)
  
  Cookies.set('openid_key', key, { secure: true, sameSite: 'Lax' });

  return jwt;
}

export const jwtVerifyState = async (state: string) => {
  const key = Cookies.get('openid_key');
  const secret = new TextEncoder().encode(key);
  const verifiedJWT = await jose.jwtVerify<OIDCState>(state, secret);
  
  return verifiedJWT;
}

export const generateAndSaveNonce = () => {
  // Get random string
  const nonce = randomString();
  // Save nonce to Cookies
  Cookies.set('openid_nonce', nonce, { secure: true, sameSite: 'Lax' });

  // Return hash of generated nonce
  return generateHash(nonce);
}

export const isNonceValid = (nonceHash: string) =>  {
  // Retrieve nonce from browser cookies
  const storedNonce = Cookies.get('openid_nonce');
  // Generate a hash
  const storedNonceHash = generateHash(storedNonce);

  // Compare if stored nonce is the same of received nonce
  return storedNonceHash === nonceHash;
}

export const redirectOpenId = ({
  redirect, 
  promocode
}: OIDCRedirectParam) => {
  // Redirect to the OpenID provider to register new user or login to get an access code.
  const lang = getLang()?.split('_')[0];
  const { oidc } : { oidc: IOIDCSettings} = Settings;
  const { authorize } = oidc.endpoints;
  let params: {[key: string]: any} = {};
  let statePayload: OIDCState = {"qs": {}};
  if ('lang' in authorize?.extraParamsVars) {
    params[authorize?.extraParamsVars?.lang as string] = lang;
  }
  if (authorize?.extraParams) {
    params = { ...params, ...authorize.extraParams };
  }
  if (redirect) {
    statePayload["qs"]['redirect'] = redirect;
  }
  if (promocode) {
    statePayload["qs"]['promocode'] = promocode;
  }
  const extraParams = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');
  generateAndSaveState(statePayload).then(state => {
    const nonce = generateAndSaveNonce()
    window.location.href = `${oidc.providerUrl}${authorize.resource}?client_id=${oidc.clientId}&response_type=${authorize.response_type}&scope=${authorize.scope}&state=${state}&nonce=${nonce}&redirect_uri=${authorize.redirectUrl}&${extraParams}`;
  });
};

export function loginOpenId({
  code,
  state,
  nonce
}: OIDCLoginParam) {
  return (dispatch: Dispatch) => {
    jwtVerifyState(state)
    .then(({payload}) => {
      Cookies.remove('openid_key');
      dispatch(loginOpenIdRequest());
      openidService.login({code, nonce})
      .then(res => {
        cookiesUtils.saveLang(getLangByToken(res.token));
        delete payload.qs.redirect;
        if (res.new_customer || payload.qs.promocode) {
          dispatch(loginOpenIdSuccess({
            token: res.token, 
            openIdToken: res.openid_token,
            redirect: contraction_url,
            redirectSearchParams: queryString.stringify(payload.qs),
            newCustomer: res.new_customer
          }));
        } else {
          dispatch(loginOpenIdSuccess({
            token: res.token, 
            openIdToken: res.openid_token,
            redirect: home_url,
            redirectSearchParams: queryString.stringify(payload.qs)
          }));
          // Per quan ho fem genèric o quan vagi a la V2
          // dispatch(registerCoupon(state.payload.qs));            
        }
      })
      .catch(err => {
        dispatch(loginOpenIdFailure({
          statusCode: 403,
          statusText: 'Invalid state',
          statusType: "error",
        }));
      });
    })
    .catch(err => {
      dispatch(loginOpenIdFailure({
        statusCode: 403,
        statusText: 'Invalid state',
        statusType: "error",
      }));
    });
  }
};