import { AuthSearchParams } from '@/types/models/hooks';
import { useSearchParams } from 'react-router-dom';

const useConnectQueryParams = () => {
  const [searchParams] = useSearchParams();

  const params: AuthSearchParams = {
    token: searchParams.get('token'),
    clientId: searchParams.get('id_client'),
    secret: searchParams.get('secret'),
    code: searchParams.get('code'),
    state: searchParams.get('state'),
    promocode: searchParams.get('promocode'),
    redirect: searchParams.get('redirect')
  };

  return params;
};

export default useConnectQueryParams;