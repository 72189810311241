import { LanguageSelect as LanguageSelectComponent } from '@gisce/oficina-virtual-components';
import useI18n from '../../hooks/useI18n';

export const LanguageDropdownMenu = () => {
  const { availableLangs, currentLang, changeLang } = useI18n();

  return (
    <LanguageSelectComponent 
      availableLanguages={availableLangs}
      currentLanguage={currentLang}
      handleLanguageSelect={changeLang}
    />
  );
}