import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { fetchATCTipus, fetchATCSubtipus } from "../actions/reclamacions";
import { fetchContracts } from "../actions/contracts";
import { FormControl, InputLabel, Select, TextField, FormHelperText, 
  Button, CircularProgress, MenuItem, Box, Typography } from "@mui/material";
import UploadButton from "./UploadButton";
import validate from 'validate.js';
import {data_create_api_resource} from "../utils/http_functions";
import { i18n } from "../config";




const mapStateToProps = (state) => ({
  token: state.auth.token,
  tipus: state.atcTipus || [],
  subtipus: state.atcSubtipus || [],
  contracts: state.contracts.items || [],
});

const VALIDATIONS = {
  contract: {
    presence: {allowEmpty: false}
  },
  tipus: {
    presence: {allowEmpty: false}
  },
  subtipus: {
    presence: {allowEmpty: false}
  },
  descripcio: {
    presence: {allowEmpty: false}
  }
};

const STATUS = {
  FORM: 0,
  SENDING: 1,
  SENT: 2,
};


class ReclamATC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tipus: {},
      subtipus: {},
      contract: props.value || {},
      descripcio: '',
      status: STATUS.FORM,
      reclamacion_id: null,
      errors: {},
    };
    validate.validators.presence.options = {
      message: i18n.t('common:text.atc_validation'),
    };
  }

  componentDidMount() {
    this.props.fetchATCTipus();
    if (_.isEmpty(this.props.contracts)) {
      this.props.fetchContracts(this.props.token);
    }
  }

  onChangeContract = (event) => {
    const contract = this.props.contracts.filter((c) => c.name === event.target.value)[0];
    this.setState({contract});
  };

  onChangeTipus = (event) => {
    const tipus = this.props.tipus.filter((t) => t[0] === event.target.value)[0];
    this.setState({tipus});
    this.props.fetchATCSubtipus(event.target.value);
  };

  onChangeSubtipus = (event) => {
    const subtipus = this.props.subtipus.filter((st) => st.name === event.target.value)[0];
    this.setState({subtipus});
  };

  handleUpload = (file) => {
    this.setState({attachment: file});
  };

  handleSubmit = async () => {
    this.setState({status: STATUS.SENDING});
    const errors = validate(this.state, VALIDATIONS);
    if (errors) {
      this.setState({errors, status: STATUS.FORM});
    } else {
      this.setState({errors: {}});
      const contract = this.state.contract.name;
      const postData = {
        contract: contract,
        tipus: this.state.tipus[0],
        subtipus: this.state.subtipus.name,
        comments: this.state.descripcio,
        attachment: this.state.attachment
      };
      const result = await data_create_api_resource(this.props.token, `contracts/${contract}/atc/`, postData);
      this.setState({
        status: STATUS.SENT,
        reclamacion_id: result.data.result
      });
    }
  };

  renderDone() {
    return (
      <div>
        { i18n.t('common:text.atc_success') } <code>#{this.state.reclamacion_id}</code>.
      </div>
    );
  }

  renderForm() {

    const contracts = this.props.contracts.map(
      (contract) => (
        <MenuItem 
          key={contract.name} 
          value={contract.name} 
        >
          {`${contract.name} - ${contract.cups.full_address}`}
        </MenuItem>
        ));
    let tipus = [];
    if (this.props.tipus.length >= 0){
      tipus = this.props.tipus.map(
        (t) => (
          <MenuItem 
            key={t[0]}
            value={t[0]}
          >
            {t[1]}
          </MenuItem>
        ));
    }

    const subtipus = this.props.subtipus.map((st) => (
      <MenuItem 
        key={st.name}
        value={st.name}
      >
        {st.desc}
      </MenuItem>
    ));

    const { errors } = this.state;
    const isSending = this.state.status === STATUS.SENDING;

    return (
      <div>
        <div>
          { this.props.value &&
          <div>
            <FormControl style={{marginBottom: 10}} fullWidth={true}>
              <InputLabel shrink htmlFor="">
                { i18n.t('common:text.atc_contract') }
              </InputLabel>
              <Select
                          value={ this.state.contract.name ? this.state.contract.name : ""}
                          onChange={ this.onChangeContract }
                          error={!!(errors.contract) }>
                { contracts }
              </Select>
            </FormControl>
            { (errors.contract) &&
              <FormHelperText error={true} id="reclam-contract-error-text">
                {errors.contract}
              </FormHelperText>
            }
          </div>
          }
        </div>
        <div>
          <FormControl style={{marginBottom: 10}} fullWidth={true}>
            <InputLabel shrink htmlFor="">
              { i18n.t('common:text.atc_contract_type') }
            </InputLabel>
            <Select fullWidth
              value={this.state.tipus[0] ? this.state.tipus[0] : ""}
              onChange={this.onChangeTipus}
              error={!!(errors.tipus)}>
              { tipus }
            </Select>
          </FormControl>
          { (errors.tipus) &&
            <FormHelperText error={true} id="reclam-tipus-error-text">
              {errors.tipus}
            </FormHelperText>
          }
        </div>
        <div>
          <FormControl fullWidth={true}>
            <InputLabel shrink htmlFor="">
              { i18n.t('common:text.atc_contract_subtype')}
            </InputLabel>
            <Select fullWidth
                        value={ this.state.subtipus.name ? this.state.subtipus.name : ""}
                        onChange={ this.onChangeSubtipus }
                        error={!!(errors.subtipus) }>
              { subtipus }
            </Select>
          </FormControl>
          { (errors.subtipus) &&
            <FormHelperText error={true} id="reclam-tipus-error-text">
              {errors.subtipus}
            </FormHelperText>
          }
        </div>
        <div>
          <TextField 
            label={ i18n.t('common:text.atc_description') }  
            onChange={(e) => this.setState({descripcio: e.target.value})} 
            value={this.state.descripcio} 
            multiline={true} 
            minRows={5}
            fullWidth={true}
            error={!!(errors.descripcio)}
          />
          { (errors.descripcio) &&
            <FormHelperText error={true} id="reclam-error-text">
              {errors.descripcio}
            </FormHelperText>
          }
        </div>
        <div style={{marginTop: 12}}>
          <UploadButton label={ i18n.t('common:text.atc_attachment') } onChange={this.handleUpload} value={this.state.attachment}/>
        </div>
        <div style={{marginTop: 24}}>
          <Button 
            color={'primary'}
            variant={'contained'}
            disabled={isSending} 
            onClick={this.handleSubmit}
          >
            {isSending ? 
                <CircularProgress size={24} style={{marginRight: 10}}/> 
              : null
            }
            { i18n.t('common:text.atc_send') }
          </Button>
        </div>
      </div>
    );
  }

  render() {

    const { status } = this.state;

    return (
      <div>
        <Box mb={2}>
          <Typography variant="h5">
            {i18n.t('common:text.atc_title')}
          </Typography>
        </Box>
        {(status === STATUS.FORM || status === STATUS.SENDING) &&
          this.renderForm()}
        {status === STATUS.SENT && this.renderDone()}
      </div>
    );
  }


}

export default connect(mapStateToProps, { fetchATCTipus, fetchATCSubtipus, fetchContracts })(ReclamATC);
