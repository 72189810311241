import { 
  TextField, 
  Stack, 
  Typography, 
  Grid, 
  InputAdornment, 
  IconButton,
  FormHelperText
} from "@mui/material";
import { Close, Done, Info, Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import { Button } from "@gisce/oficina-virtual-components";
import * as z from "zod";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import usePassword from "@/hooks/usePassword";

const ChangePasswordView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { changePasswordAndRedirect, isLoading, errorCode } = usePassword();

  // Zod validation schema
	const schema = z.object({
    currentPassword: z.string().min(6, t('common:text.required_field')),
    newPassword: z.string()
      .regex(/[*.!@#$%^&() {}[\]:;<>,?/~_+\-=|\\A-Za-z0-9]{6,}/,
        {message: t("common:text.change_password_min_char_validation")})
      .regex(/^(?=.*[a-z])(?=.*[A-Z]).*$/, 
        {message: t("common:text.change_password_lower_upper_case_validation")})
      .regex(/(\d)+/, 
        {message: t("common:text.change_password_number_validation")})
      .regex(/([*.!@#$%^&() {}[\]:;<>,?/~_+\-=|\\])+/, 
        {message: t("common:text.change_password_symbol_validation")}),
    repeatPassword: z.string().min(6, t('common:text.required_field'))
  })
  .superRefine(({ newPassword, repeatPassword }, refinementContext) => {
    if (newPassword !== repeatPassword) {
      return refinementContext.addIssue({
        code: z.ZodIssueCode.custom,
        message: t("common:text.change_password_check_validation"),
        path: ['repeatPassword'],
      });
    }
  });

  const {
    handleSubmit,
    watch,
    register,
    formState: { errors, isSubmitted }
  } = useForm({
    resolver: zodResolver(schema),
    criteriaMode: "all",
    mode: "onChange",
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      repeatPassword: ''
    }
  });

  const errorMessages = [
    t("common:text.change_password_min_char_validation"), 
    t("common:text.change_password_lower_upper_case_validation"), 
    t("common:text.change_password_number_validation"),
    t("common:text.change_password_symbol_validation")
  ];

  const newPassword = watch("newPassword");

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const onSubmit = (val) => {
    changePasswordAndRedirect({
      currentPassword: val.currentPassword,
      newPassword: val.newPassword,
      redirect: t("common:url.invoices")
    });
  }
  
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column">
        
        <Typography variant="h4" noWrap textAlign="center">
          {t('common:text.change_password_view_title')}
        </Typography>

        <Grid container alignItems="stretch" direction="column" mt={4} mb={4}>

          <Grid item mb={2}>
            <TextField
              name="current-password"
              {...register("currentPassword")}
              fullWidth
              placeholder={
                t('common:text.change_password_view_field_currentPassword_hint')
              }
              label={
                t('common:text.change_password_view_field_currentPassword_title')
              }
              type={showCurrentPassword ? "text" : "password"}
              variant="outlined"
              disabled={isLoading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                    >
                      {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={isSubmitted && 
                (
                  !!errors.currentPassword ||
                  errorCode?.type === "invalidPassword"
                )
              }
            />
            {isSubmitted && errors.currentPassword?.message && 
              <FormHelperText error>
                {errors.currentPassword.message}
              </FormHelperText>
            }
            {isSubmitted && errorCode?.type === "invalidPassword" &&
              <FormHelperText error>
                {t("common:text.change_password_view_message_error_invalid_pass")}
              </FormHelperText>
            }
          </Grid>

          <Grid item mb={1}>
            <TextField
              name="new-password"
              {...register("newPassword")}
              sx={{mb: 1}}
              fullWidth
              placeholder={
                t('common:text.change_password_view_field_newPassword_hint')
              }
              label={
                t('common:text.change_password_view_field_newPassword_title')
              }
              type={showNewPassword ? "text" : "password"}
              variant="outlined"
              disabled={isLoading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                    >
                      {showNewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={!!errors.newPassword && (!!newPassword.length || isSubmitted)}
            />
            <FormHelperText>
              {errorMessages.map(
                (err, idx) => {
                  const errorFound = errors?.newPassword?.types?.invalid_string?.indexOf(err) > -1;
                  const ShowIcon = (!isSubmitted && !newPassword.length ) ? Info : (errorFound ? Close : Done);
                  const color = (!isSubmitted && !newPassword.length) ? "inherit" : (errorFound ? "error" : "primary");
                  return (
                    <Stack direction="row" alignItems="center" sx={{mb: 1}} key={idx}>
                      <ShowIcon fontSize="small" sx={{marginRight: 1}} color={color}/> 
                      <Typography variant="caption" color={color}>
                        {err}
                      </Typography>
                    </Stack>
                  );
                }
              )}
            </FormHelperText>
          </Grid>

          <Grid item mb={1}>
            <TextField
              name="confirm-password"
              {...register("repeatPassword")}
              fullWidth
              placeholder={
                t('common:text.change_password_view_field_newPassword_again_hint')
              }
              label={
                t('common:text.change_password_view_field_newPassword_again_title')
              }
              type={showRepeatPassword ? "text" : "password"}
              variant="outlined"
              disabled={isLoading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                    >
                      {showRepeatPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={!!errors.repeatPassword && isSubmitted} // TODO
            />
            {errors.repeatPassword?.message && isSubmitted && 
              <FormHelperText error>
                {errors.repeatPassword.message}
              </FormHelperText>
            }
          </Grid>
        </Grid>

        {errorCode?.type === "generic" && 
          <FormHelperText error sx={{marginBottom: 1}}>
            {t("common:text.change_password_view_message_error_generic")}
          </FormHelperText>
        }
        
        <Grid container spacing={2}>
          <Grid item xs>
            <Button
              fullWidth
              color="inherit"
              variant="outlined"
              disabled={isLoading}
              onClick={() => navigate(t("common:url.invoices"))}
            >
              {t('common:text.change_password_view_button_cancel')}
            </Button>
          </Grid>

          <Grid item xs>
            <Button
              startIcon={<Lock/>}
              fullWidth
              type="submit"
              color="primary"
              variant="contained"
              loading={isLoading}
            >
              {t('common:text.change_password_view_button_send')}
            </Button>
          </Grid>
        </Grid>

      </Stack>
    </form>
  );
}

export default ChangePasswordView;