import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCoupons } from "../actions/coupons";

import { PublicityBanner, IPublicityBannerProps } from "@gisce/oficina-virtual-components";
import { RootState } from "@/store";
import useI18n from "@/hooks/useI18n";

export const CouponsView = () => {
  const dispatch = useDispatch();
  const { currentLang } = useI18n();
  const token = useSelector((state: RootState) => state.auth.token);
  const coupons: IPublicityBannerProps["dataProps"][] = useSelector((state: RootState) => state.coupons.data);

  useEffect(() => {
    if (coupons === null && token) {
      dispatch(fetchCoupons(currentLang.value));
    }
  }, [token]); 

  return coupons && Array.isArray(coupons) && <>
      {coupons.map((coupon, idx) => <PublicityBanner dataProps={coupon} key={idx}/>)}
    </>
};
