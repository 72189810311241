import React from 'react';
import { Gesture } from '@mui/icons-material';
import RichContent from "./RichContent";
import { i18n } from "../config";

const SignatureProcess = ({icon = <Gesture/>}) => {
  return (
    <div>
      {icon}
      <span style={{marginLeft: '10px'}}>
        <RichContent style={{fontSize: '32px'}} content={i18n.t('common:text.contracts_signature_process')} inline={true} />
      </span>
    </div>
  );
};

export default SignatureProcess;
