import { useContext } from "react"
import { NavigationItemsContext } from "./NavigationItemsContextProvider"

export const useNavigationItemsContext = () => {
	const context = useContext(NavigationItemsContext);

	if (!context) {
		throw new Error("useNavigationContext outside of NavigationItemsContextProvider");
	}

	return context;
}