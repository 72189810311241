import React, { Component } from "react";
import PropTypes from "prop-types";

import { Snackbar } from "@mui/material";

export class Notification extends Component {
  render() {
    const message_text = this.props.message;
    const open = this.props.open ? this.props.open : false;
    const message_time = this.props.time ? this.props.time : 4000;

    return (
      <div>
        {message_text != null && (
          <Snackbar
            open={open}
            className="notification"
            message={message_text}
            autoHideDuration={message_time}
            onClose={this.props.onRequestClose}
          />
        )}
      </div>
    );
  }
}

Notification.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  time: PropTypes.number,
};
