import { useState } from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip, ComposedChart, Legend, Line, Brush, Bar, ResponsiveContainer } from 'recharts';
import { Tooltip as CustomTooltip } from "../Tooltip";
import { useChart } from './useChart';
import { useTheme } from '@mui/material';

export const BarChart = ({ 
  data,
  unit = 'kWh', 
  groupTitle = "", 
 }) => {
  const theme = useTheme();
  const [hover, setHover] = useState(null);

  const handleLegendMouseEnter = (e) => {
    setHover(e.dataKey);
  };

  const handleLegendMouseLeave = (e) => {
    setHover(null);
  };

  const {colors, adaptedData, hiddenFields, toggleBarVisibility} = useChart( data );
  const createBars = () => {
    return data.magnitudes.elements.map((el, idx) => {
      return <Bar
        legendType="square"
        unit={unit}
        isAnimationActive={true}
        key={idx}
        type='monotone'
        dataKey={el}
        stackId="activa"
        stroke={"#808080"}
        strokeWidth={hover === el ? 1 : 0}
        fill={colors[idx]}
        hide={hiddenFields[el] === true}
        fillOpacity={(hover === el || !hover) ? 1 : 0.5}
      />
    });
  }

  const createTotalLine = () => {
    return <Line
      name={"Total"}
      unit={unit}
      isAnimationActive={true}
      key={"line"}
      type='monotone'
      dataKey={'total'}
      stroke={"black"}
      fill={"white"}
      hide={hiddenFields.total === true}
      strokeOpacity={(hover === 'total' || !hover) ? 1 : 0.5}
      dot={{ opacity: ((hover === 'total' || !hover) ? 1 : 0.5), strokeOpacity: ((hover === 'total' || !hover) ? 1 : 0.5) }}
    />;
  }

  return (
    <ResponsiveContainer height={500}>
      <ComposedChart
        data={adaptedData}
        margin={{ top: 20, left: 20 }}
        stackOffset="sign"
      >
        <XAxis dataKey="name" angle={-45} height={55} dy={15} label={{ value: groupTitle, fill: '#666666', paddingTop: 20 }} />
        <YAxis label={{ value: unit, angle: unit.length > 1 ? -90 : 0, position: 'insideLeft', fill: '#666666' }} />
        <CartesianGrid strokeDasharray="3 3"/>
        {createBars()}
        {createTotalLine()}
        
        <Legend
          wrapperStyle={{ paddingTop: "1vh"}}
          layout="horizontal"
          align="center"
          formatter={(value, entry, index) => {
            return <span style={{ 
              color: entry.inactive ? "gray" : "black", 
              textShadow: entry.value === hover ? `0 2px 10px ${colors[index]}` : "", 
              fontWeight: "400", 
              cursor: "pointer" }}
            >
              {value}
            </span>
          }}
          onClick={e => toggleBarVisibility(e.dataKey)}
          onMouseOver={handleLegendMouseEnter}
          onMouseOut={handleLegendMouseLeave}
        />

        <Tooltip content={<CustomTooltip groupTitle={groupTitle} />} />
        <Brush dataKey="name" height={30} stroke={theme.palette.primary.main} />
      </ComposedChart>
    </ResponsiveContainer>
  );
}