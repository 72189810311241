import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from "@mui/material";
import Category from '@mui/icons-material/Category';
import { useState } from "react";
import { i18n } from "../../config";
import DownloadAttachmentButton from "./DownloadAttachmentButton";


const AttachmentsList = ({title, attachments, downloadURL}) => { 
  /*
  ** title: string
  ** attachments: {created_date, filename, id, name, size_text}[]
  ** downloadURL: string. example: some/random/url/:attachmentID  the :attachmentID will be replaced to the selected attachment id
  */
  const [orderBy, setOrderBy] = useState({
    'id': 'created_date',
    'order': 'desc'
  });
  const headCells = [
    { id: "name", label: i18n.t('common:text.attachment_name') },
    { id: 'created_date', label: i18n.t('common:text.attachment_created_date') },
    { id: 'size_text', label: i18n.t('common:text.attachment_size') },
    { id: 'filename', label: i18n.t('common:text.attachment_filename') },
    { label: i18n.t('common:text.attachment_download') }, // Without 'id' key because I don't want to sort this column
  ];

  // SORTING THE ATTACHMENTS LIST
  attachments = attachments.sort((a, b) => {
    if (orderBy.id === 'created_date') {
      return orderBy.order === 'asc' ? new Date(a.created_date) - new Date(b.created_date) : new Date(b.created_date) - new Date(a.created_date);
    } else if (orderBy.id === 'size_text') {
      const aSize = parseInt(a.size_text); // Getting the size without units
      const bSize = parseInt(b.size_text); 
      const aUnit = a.size_text.slice(-2); // getting units text
      const bUnit = b.size_text.slice(-2);
      
      if (aUnit === bUnit) {
        return orderBy.order === 'asc' ? aSize - bSize : bSize - aSize;
      } else {
        const units = ['KB', 'MB', 'GB']; // **THE UNITS ORDER IS HERE, AD MORE UNITS IF NEEDED(OR REORDER)**
        const aIndex = units.indexOf(aUnit);
        const bIndex = units.indexOf(bUnit);
        
        return orderBy.order === 'asc' ? aIndex - bIndex : bIndex - aIndex;
      }
    } else {
      return orderBy.order === 'asc' ? a[orderBy.id].localeCompare(b[orderBy.id]) : b[orderBy.id].localeCompare(a[orderBy.id]);
    }
  });

  const handleChangeOrder = (headCellId) => {
    const isAsc = orderBy.id === headCellId && orderBy.order === 'asc';
    setOrderBy({
      'id': headCellId,
      'order': isAsc ? 'desc' : 'asc'
    });
  }

  const drawedHeadCells = headCells.map((headCell, idx) => (
    <TableCell key={idx}>
      {
        (headCell.id) ? 
        <TableSortLabel active={orderBy.id === headCell.id}
                        direction={orderBy.id === headCell.id ? orderBy.order : 'asc'}
                        onClick={() => handleChangeOrder(headCell.id)}>
          {headCell.label}
        </TableSortLabel> 
        : 
        <>{ headCell.label }</>
      }
    </TableCell>
  ));

  const drawedValueRows = attachments.map((attachment) => (
    <TableRow key={ attachment.name }>
      <TableCell component="th" scope="row">
        { attachment.name }
      </TableCell>
      <TableCell >{ attachment.created_date }</TableCell>
      <TableCell >{ attachment.size_text }</TableCell>
      <TableCell >{ attachment.filename }</TableCell>
      <TableCell >
        <DownloadAttachmentButton downloadURL={downloadURL.replace(':attachmentID', attachment.id)}/>
      </TableCell>
    </TableRow>
  ));

    
  return (
    <Box marginLeft={5} marginTop={2}>
      <Box display={"flex"} >
        <Category />
        <Typography variant="body1" style={{textTransform: "capitalize", marginBottom: "5px"}}>
          {title} ({attachments.length})
        </Typography>
      </Box>
      <Box >
        <TableContainer component={Paper}>
          <Table aria-label={i18n.t('common:text.attachments_list')}>
            <TableHead>
              <TableRow>
                { drawedHeadCells }
              </TableRow>
            </TableHead>
            <TableBody>
              { drawedValueRows }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}

export default AttachmentsList;