import React, { useState } from "react";
import validate from 'validate.js';
import {data_create_api_resource} from "../../utils/http_functions";
import { useNavigate } from 'react-router-dom';

import { 
  CircularProgress, Button, TextField, 
  FormHelperText, Grid, Typography, Box
} from '@mui/material';
import { LockReset } from '@mui/icons-material';
import { useTranslation } from "react-i18next";

const VALIDATIONS = {
  nif: {
    presence: {allowEmpty: false}
  },
  email: {
    presence: {allowEmpty: false},
    email: true
  }
};


const UserResetPassword = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [nif, setNif] = useState("");
  const [email, setEmail] = useState("");
  const [submitSucceeded, setSubmitSucceeded] = useState(false);
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async () => {
    setSubmitting(true);
    const errors = validate({email: email, nif: nif}, VALIDATIONS);
    setErrors(errors || {});

    if (!errors){
      try {
        const result = await data_create_api_resource(null, 'user/reset/', {email: email, nif: nif});
        setSubmitting(false);
        setSubmitSucceeded(true);
      } catch (e) {
        if (e.response && e.response.status === 400) {
          setErrors(e.response.data.errors);
        }
        setSubmitting(false);
      }
    } else {
      setSubmitting(false);
    }
  }

  return (
    <>
      <Typography variant="h4" mb={1} textAlign="center">
        {t('common:text.password_recovery')}
      </Typography>
      {submitSucceeded &&
        <>
          <Typography mb={2} textAlign="center">
            {t('common:text.password_restored_description')}
          </Typography>
          <Box display="flex" justifyContent="center">
            <Button
              variant={'contained'}
              onClick={() => navigate(t('common:url.login'))}
              sx={{minWidth: 150}}
            >
              {t("common:text.login_title")}
            </Button>
          </Box>
        </>
      }
      { !submitSucceeded &&
      <>
        <Grid container spacing={2} p={1}>
          <Grid item xs={12}>
            <Typography mb={1} variant="subtitle1" textAlign="center">
              {t('common:text.password_restore_info')}
            </Typography>
          </Grid>
          
          <Grid item xs={12}>
            <TextField
              name="nif"
              label={t("common:text.contract_nif")}
              error={!!(errors.nif)}
              style={{width: "100%"}}
              disabled={submitting}
              variant="outlined"
              onChange={(e) => setNif(e.target.value)}
            />
            {!!(errors.nif) &&
              <FormHelperText error={true}>
                {errors.nif.join(", ")}
              </FormHelperText>
            }
          </Grid>
          <Grid item xs={12} mb={2}>
            <TextField
              name="email"
              label={t("common:text.address_email")}
              error={!!(errors.email)}
              disabled={submitting}
              style={{width: "100%"}}
              variant="outlined"
              onChange={(e) => setEmail(e.target.value)}
            />
            {!!(errors.email) &&
              <FormHelperText error={true}>
                {errors.email.join(", ")}
              </FormHelperText>
            }
          </Grid>

          <Grid item xs={12}>
            <Button
                color={'primary'}
                variant={'contained'}
                disabled={submitting}
                onClick={handleSubmit}
                fullWidth={true}
                startIcon={
                  submitting ? 
                  <CircularProgress size={25}/> 
                : 
                  <LockReset />
                }
              >
                {t("common:text.recover")}
              </Button>
          </Grid>
        </Grid>


      </>
      }
    </>
  );
}

export default UserResetPassword;
