import { createContext, useState } from "react";

export const MoveVirtualBatteryContext = createContext();

const MoveVirtualBatteryContextProvider = ({ children }) => {
  const [bateriaVirtualToMove, setBateriaVirtualToMove] = useState();
  const [newPercentage, setNewPercentage] = useState();
  const [selectedBatteryId, setSelectedBatteryId] = useState();
  const [selectedBatteryDistribution, setSelectedBatteryDistribution] = useState();


  const reset = () => {
    setBateriaVirtualToMove(undefined);
    setNewPercentage(undefined);
    setSelectedBatteryId(undefined);
    setSelectedBatteryDistribution(undefined);
  }

  const handleSetVirtualBatteryToMove = (bv) => {
    setBateriaVirtualToMove(bv);
    setSelectedBatteryDistribution(undefined);
    setSelectedBatteryId(undefined);
    setNewPercentage(undefined);
  }

  const handleSetSelectedBatteryDistribution = (newBatteryDistribution) => {
    setSelectedBatteryDistribution(newBatteryDistribution);
    setSelectedBatteryId(newBatteryDistribution?.batteryId)
  }


  return (
    <MoveVirtualBatteryContext.Provider
      value={{
        newPercentage,
        setNewPercentage,
        selectedBatteryId,
        selectedBatteryDistribution,
        setSelectedBatteryDistribution: handleSetSelectedBatteryDistribution,
        bateriaVirtualToMove,
        setBateriaVirtualToMove: handleSetVirtualBatteryToMove,
        reset
      }}
    >
      {children}
    </MoveVirtualBatteryContext.Provider>
  );
};

export default MoveVirtualBatteryContextProvider;
