// import { TConsumptionGenerationItem, TGetConsumptionGenerationParams } from "../types";

import * as Sentry from "@sentry/browser";
import axios, { AxiosRequestConfig } from "axios";

const API_PREFIX = "/api/";

const get = <TResponse,>(url: string, params?: string, config?: AxiosRequestConfig): Promise<TResponse> => {
  return new Promise<TResponse>((resolve, reject) => {
    axios.get<TResponse>([API_PREFIX, url, params].join(''), config)
    .then((res) => {
      resolve(res.data)
    })
    .catch(err => {
      Sentry.captureException(err);
      reject(err);
    })
  });
}

const put = <TResponse, TData>(url: string, data: TData): Promise<TResponse> => {
  return new Promise<TResponse>(async (resolve, reject) => {
    axios.put<TResponse>([API_PREFIX, url].join(''), data)
    .then(res => {
      resolve(res.data)
    })
    .catch(err => {
      Sentry.captureException(err);
      reject(err);
    })
  });
}

const post = <TResponse, TData>(url: string, data: TData): Promise<TResponse> => {
  return new Promise<TResponse>(async (resolve, reject) => {
    axios.post<TResponse>([API_PREFIX, url].join(''), data)
    .then(res => {
      resolve(res.data)
    })
    .catch(err => {
      Sentry.captureException(err);
      reject(err);
    })
  });
}

export default {
  get,
  put,
  post
};