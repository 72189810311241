import React from "react";
import LegacyIBANField, { IBANvalid } from "../IBANField";

const IBANField = ({ input, meta, ...rest }) => (
  <LegacyIBANField
    {...input}
    {...rest}
    errorText={meta.touched ? meta.error : ""}
  />
);

export default IBANField;

export { IBANvalid };