import _ from "lodash";
import {
    RECEIVE_INVOICES_GAS,
    FETCH_INVOICES_REQUEST_GAS,
    EXPORT_INVOICE_REQUEST_GAS,
    EXPORT_INVOICE_DONE_GAS,
    MESSAGE_INVOICE_GAS
} from '../constants';
import { createReducer } from '../utils/misc';
import Settings from "../settings";

const initialState = {
    data: null,
    isFetching: false,
    loaded: false,
    download_status: {},
    page_items: _.get(Settings, "invoices.itemsPerPage", 100),
    filters: {
        loading: false,
        data: {},
        currentFilter: ''
    },
    errors: null
};

export default createReducer(initialState, {
    [RECEIVE_INVOICES_GAS]: (state, payload) =>
    {
        return {
            ...state, 
            items: payload.response.items,
            total_items: payload.response.n_items,
            offset_items: payload.response.offset,
            page_items: payload.response.limit,
            isFetching: false,
            loaded: true,
            message_text: payload.message,
            filters: {
                ...state.filters,
                currentFilter: payload.filters
            }
        }
    },
    [FETCH_INVOICES_REQUEST_GAS]: (state, payload) =>
    {
        return {
            ...state,
            isFetching: true,
            loaded: false,
            message_text: payload.message,
        }
    },
    [EXPORT_INVOICE_REQUEST_GAS]: (state, payload) => {
        const download_status = Object.assign({}, state.download_status);
        download_status[payload.invoice] = true;
        return Object.assign({}, state, {
            download_status,
            message_text: payload.message
        });
    },
    [EXPORT_INVOICE_DONE_GAS]: (state, payload) => {
        const download_status = Object.assign({}, state.download_status);
        download_status[payload.invoice] = false;
        return Object.assign({}, state, {
            download_status
        });
    },
    [MESSAGE_INVOICE_GAS]: (state, payload) => {
        return Object.assign({}, state, {
            message_text: payload.message,
        })
    }
});
