import React, { useState, useEffect } from "react";
import _ from "lodash";
import { data_fetch_api_resource } from "../../utils/http_functions";
import { i18n } from "../../config";

import { FormControl, Select, MenuItem, InputLabel, FormHelperText } from "@mui/material"

const ComerOriginSelectField = ({ input: { onChange, value }, initialValue, label, readOnly, onAvailableComersUpdate, onUpdate, gas, meta, ...rest }) => {

  const [availableItems, setAvailableItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const fetchAvailableItems = async () => {
    const endpoint = gas ? "gas/leads/comers" : "leads/comers";
    const version = gas ? 2 : 1;
    const response = await data_fetch_api_resource(null, endpoint, version);
    if (response.status === 200) {
      const items = response.data;

      setAvailableItems(items);

      if (items && typeof onAvailableComersUpdate === "function") {
        onAvailableComersUpdate();
      }

      let defaultSelectedItem;
      if (value) {
        defaultSelectedItem = value;
        setSelectedItem(defaultSelectedItem);
        onChange(defaultSelectedItem);
        if (typeof onUpdate === "function") {
          onUpdate(value);
        }
      }
    }
  };

  const handleChange = (event, index, value) => {
    setSelectedItem(event.target.value);
    onChange(event.target.value);
    if (typeof onUpdate === "function") {
      onUpdate(event.target.value);
    }
  };

  useEffect(() => {
    fetchAvailableItems();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <FormControl style={{ width: "100%"}}>
        <InputLabel>
          {(label || i18n.t('common:text.contractation_comer_origin')) + "*"}
        </InputLabel>
        <Select
          value={selectedItem ? selectedItem : ''}
          onChange={handleChange}
          autoWidth={true}
          disabled={readOnly}
          {...rest}
          error={!!(meta.touched && !meta.valid)}
        >
          {(!_.isEmpty(availableItems) &&
          availableItems.map((c) => {
            return <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>;
          })) ||
          []}
        </Select>
      </FormControl>
      {!!(meta.touched && !meta.valid) &&
        <FormHelperText error={true}>
          {meta.error}
        </FormHelperText>
      }
    </div>
  );
};

export default ComerOriginSelectField;
