// import { AuthenticationProvider } from "./components/AuthenticationProvider";

import { Provider } from 'react-redux';
import { I18nextProvider } from "react-i18next";

import RouterProvider from './routes';
import { ThemeProvider } from '@mui/material/styles';
import SettingsProvider from './components/SettingsProvider';

import { i18n } from './config';

import theme from './overrides/theme';
import store from './store';


import { 
  NavigationItemsContextProvider 
} from './components/NavigationMenu/NavigationItemsContextProvider';

const App = (props) => {
  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}> 
        <ThemeProvider theme={theme}>
          <NavigationItemsContextProvider>
            <SettingsProvider>
              <RouterProvider />
            </SettingsProvider>
          </NavigationItemsContextProvider>
        </ThemeProvider>
      </I18nextProvider>
    </Provider>
  );
};

export default App;
