import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";


const InvoicesPagination = ({onItemsOffsetChange, type}) => {
  const { t } = useTranslation();

  const {
    isFetching,
    itemsPage,
    itemsTotal,
    itemsOffset
   } = useSelector((state) => state.invoices[type]);
  const itemsEnd = Math.min(itemsOffset + itemsPage, itemsTotal);

  const handlePreviousPage = () => {
    onItemsOffsetChange(itemsOffset - itemsPage);
  };

  const handleNextPage = () => {
    onItemsOffsetChange(itemsOffset + itemsPage);
  };

  return ( itemsTotal > 0 &&
    <div style={{ textAlign: "center" }}>
      {itemsOffset > 0 && (
        <Button
          variant={"text"}
          onClick={handlePreviousPage}
          disabled={isFetching}
        >
          {<ArrowBack />}
          {t("common:text.pagination_back")}
        </Button>
      )}
      <span>
        {t("common:text.pagination_showing",
          { init: itemsOffset, end: itemsEnd, ammount: itemsTotal })}
      </span>
      {itemsEnd < itemsTotal && (
        <Button
          variant={"text"}
          onClick={handleNextPage}
          disabled={isFetching}
        >
          {t("common:text.pagination_next")}
          {<ArrowForward />}
        </Button>
      )}
    </div>
  )
}

export default InvoicesPagination;