import { useSelector } from "react-redux";
import { LanguageDropdownMenu } from "../../../LanguageSelect";
import { UserProfileMenu } from "../../../User/UserProfileMenu";
import { RootState } from "@/store";

export const UpSm = () => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

	return <>	
		<LanguageDropdownMenu />
		{isAuthenticated && <UserProfileMenu />}
	</>
}