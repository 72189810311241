import { 
  Divider, Grid, Box, Typography, Paper, 
  Link, RadioGroup, FormControlLabel, Radio, FormHelperText, FormLabel,
  Alert,
  Stack
} from "@mui/material"
import { Button } from "@gisce/oficina-virtual-components";
import { useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import * as newElecContractActions from "../../actions/electricityContractation";
import Settings from "../../settings";
import FlexRow from "../FlexRow";
import RichContent from "../RichContent";
import AddressField, {
  validarReferenciaCatastral,
} from "../form-fields/AddressField";
import CNAEField from "../form-fields/CNAEField";
import CUPSField, { cupsValid } from "../form-fields/CUPSField";
import CheckboxField from "../form-fields/CheckboxField";
import ComerOriginSelectField from "../form-fields/ComerOriginSelectField";
import CurrentActiveContract from "../form-fields/CurrentActiveContract";
import FileUploadField, {
  validateFileUpload,
} from "../form-fields/FileUploadField";
import ResidenceTypeSelect from "../form-fields/ResidenceTypeSelect";
import { useTranslation } from "react-i18next";


const FormAboutInstallation = ({ selectComerOrigin, gas, onSubmit }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const elecContractation = useSelector((state) => state.electricityContractation);
  const [comerOriginEnabled, setComerOriginEnabled] = useState(selectComerOrigin);

  const handleSubmit = async (values) => {
    await onSubmit(values)
  };

  const handleAvailableComersUpdate = () => {
    setComerOriginEnabled(true);
  };

  const handleUpdateResidenceType = (values) => {
    dispatch(newElecContractActions.updateResidenceType(values));
  };

  const handleUpdateUploadedFiles = (values) => {
    dispatch(newElecContractActions.updateUploadedFiles(values));
  };

  const handleUpdateActiveContract = (values) => {
    dispatch(newElecContractActions.setActiveContract(values));
  };

  const handleCUPSUpdate = (values) => {
    const value = values.cups;
    if (value) {
      dispatch(newElecContractActions.storeCUPSData(value));
      if (cupsValid(value)) {
        dispatch(newElecContractActions.validateCUPSAvailability(values, true));
      }
    }
  };

  // Set leads consent to true by default if user is authenticated
  let leadsConsent = isAuthenticated;

  const leadsNote = (
    <RichContent
      content={t('common:text.contractation_leads_note', {
        client: Settings?.organization.name ?? "",
        privacyPolicy: Settings?.links?.privacyPolicy ?? ""
      })}
    />
  );

  // With keepDirtyOnReinitialize we avoid the re-initialization of values problem in initialValues:
  // https://stackoverflow.com/questions/54635276/react-final-form-set-initialvalues-from-props-form-state-resets-on-props-chang
  // https://final-form.org/docs/final-form/types/Config#keepdirtyonreinitialize
  // This is a temporal solution. A permanent one would be using ReduxForm or avoiding initialsValues altogether.

  return (
    <Form
      onSubmit={(values) => handleSubmit(values)}
      initialValues={{
        leadsConsent: leadsConsent || elecContractation.leadsConsent,
        cups: elecContractation.cups,
        address: elecContractation.address,
        residenceType: elecContractation.residenceType,
        cnae: elecContractation.cnae,
        invoice: elecContractation.invoice,
        comerOrigin: elecContractation.comerOrigin,
        activeContract: elecContractation.activeContract,
        acceptMorosityVerification: false,
      }}
      keepDirtyOnReinitialize={true}
      validate={(values) => {
        const errors = {};

        if (Settings?.newContract?.requireLeadConsent && !values.leadsConsent) {
          errors.leadsConsent = t('common:text.required_field');
        }

        if (!values.cups) {
          errors.cups = t('common:text.required_field');
        } else if (!cupsValid(values.cups)) {
          errors.cups = t('common:text.contractation_cups_not_valid');
        } else if (elecContractation.cupsAvailability.invalid !== null) {
          errors.cups = t('common:text.contractation_cups_not_available');
        }

        if (!values?.address?.zipcode) {
          errors.address = {zipcode: t('common:text.required_field')};
        } else if (values.address.zipcode.length !== 5) {
          errors.address = {
            ...errors.address, 
            zipcode: t('common:text.contractation_postal_code_length')};
        }

        // City
        if (Object.keys(values?.address?.city ?? {}).length === 0) {
          errors.address = {
            ...errors.address, city: t('common:text.required_field')}
        }

        // State
        if (Object.keys(values?.address?.state ?? {}).length === 0) {
          errors.address = {
            ...errors.address, state: t('common:text.required_field')}
        }

        // Street Type
        if (Object.keys(values?.address?.tv ?? {}).length === 0) {
          errors.address = {
            ...errors.address, tv: t('common:text.required_field')}
        }

        // Street Name
        if (!values?.address?.nv) {
          errors.address = {
            ...errors.address, nv: t('common:text.required_field')}
        } else if (values.address.nv.length >= 256) {
          errors.address = {
            ...errors.address, 
            nv: t('common:text.address_validation_nv_length')};
        }

        // Street Number
        if (!values?.address?.pnp) {
          errors.address = {
            ...errors.address, pnp: t('common:text.required_field')};
        } else if (values.address.pnp.length >= 9) {
          errors.address = {
            ...errors.address, 
            pnp: t('common:text.address_validation_pnp_length')};
        }

        // Block
        if (values?.address?.bq && values.address.bq.length >= 4) {
          errors.address = {
            ...errors.address, 
            bq: t('common:text.address_validation_bq_length')};
        }

        // Stair
        if (values?.address?.es && values.address.es.length >= 4) {
          errors.address = {
            ...errors.address, 
            es: t('common:text.address_validation_es_length')
          }
        }

        // Floor
        if (values?.address?.pt && values.address.pt.length >= 10) {
          errors.address = {
            ...errors.address, 
            pt: t('common:text.address_validation_pt_length')
          };
        }

        // Door
        if (values?.address?.pu && values.address.pu.length >= 4) {
          errors.address = {
            ...errors.address, 
            pu: t('common:text.address_validation_pu_length')
          }
        }

        // Referencia Catastral
        if (values?.address?.ref_catastral) {
          if (
            validarReferenciaCatastral(values.address.ref_catastral) !== 1
          ) {
            errors.address = {
              ...errors.address,
              ref_catastral: t('common:text.contractation_not_valid_ref')
            }             
          }
        }

        // Subministrament d'empresa & CNAE
        if ((values?.residenceType === 'business') && !values?.cnae) {
          errors.cnae = t('common:text.required_field');
        }

        // Active Contract (A3)
        if (!values?.activeContract) {
          errors.activeContract = t('common:text.required_field');
        }

        // Tipus de subministrament
        if (!values?.residenceType) {
          errors.residenceType = t('common:text.required_field');
        }

        // Comer Origin
        if (comerOriginEnabled && !values?.comerOrigin) {
          errors.comerOrigin = t('common:text.required_field');
        }
        let minFiles = Settings?.contractation?.invoiceRequired ? 1 : 0;
        if (values?.activeContract === 'no') {
          minFiles = 0;
        }

        const fileErrors = validateFileUpload(values.invoice, {
          min: minFiles,
          minMessage: t('common:text.multiupload_validation_min'),
        });
        if (fileErrors) {
          errors.invoice = fileErrors;
        }

        // Accept Morosity Verification
        if (Settings?.contractation?.mustAcceptMorosityVerification) {
          if (!values?.acceptMorosityVerification) {
            errors.acceptMorosityVerification = t('common:text.required_field');
          }
        }

        return Object.keys(errors).length ? errors : true
      }}
      render={({ 
        handleSubmit, submitting, validating, values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box sx={{ml: {md: 5}, mr: {md: 5}}}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  {t('common:text.contractation_cups_title')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Field
                  name="cups"
                  label={gas ? t('common:text.contractation_cups_electricity') : t('common:text.contractation_cups')}
                  component={CUPSField}
                  style={{ width: "100%" }}
                  onUpdate={(value) => handleCUPSUpdate({ ...values, cups: value })}
                  cupsAvailability={elecContractation.cupsAvailability}
                />
              </Grid>
              <Grid item xs={12}>
                <div className="helper-text">{t('common:text.contractation_cups_helper')}</div>
              </Grid>
              {/* { elecContractation.cupsAvailability.invalid !== null && 
                <Alert severity={"error"} variant="filled" sx={{mt: 1}}>
                  {t("common:text.contractation_cups_not_available")}
                </Alert>
              } */}
            </Grid>
            <Field name="address" component={AddressField}
              showMobile={Settings.contractation?.showMobile} 
              showEmail={Settings.contractation?.showEmail} />
            {Settings.contractation?.showMobile || Settings.contractation?.showEmail &&
              <Divider sx={{ mb: 3, mt: 3 }} />
            }
            <Divider sx={{ mb: 3, mt: 3 }} />
            <Grid container spacing={0}>
              <Grid item xs={12} sx={{ paddingTop: 0, paddingBottom: 0 }}>
                <Typography variant="h6">
                  {t('common:text.contractation_housing_consumption')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={!(values?.residenceType === 'business') ? 6 : 12}>
                <Field
                  name="activeContract"
                  component={CurrentActiveContract}
                  onUpdate={handleUpdateActiveContract}
                />
                <Grid container spacing={{xs:2, md:3}}>
                  <Grid item xs={12} md={!(values?.residenceType === 'business') ? 12 : 4}>
                    <Field
                      name="residenceType"
                      component={ResidenceTypeSelect}
                      onUpdate={handleUpdateResidenceType}
                    />
                  </Grid>
                  {values?.residenceType === 'business' &&
                    <Grid item xs={12} md={8}>
                      <Field
                        name="cnae"
                        component={CNAEField}
                      />
                    </Grid>
                  }
                </Grid>
              </Grid>
            </Grid>
            {comerOriginEnabled &&
              <Grid item xs={12} md={6} sx={{ mb: 3, mt: 3 }}>
                <Field
                  name="comerOrigin"
                  component={ComerOriginSelectField}
                  onAvailableComersUpdate={handleAvailableComersUpdate}
                />
              </Grid>
            }
            {values.activeContract === 'yes' &&
              <>
                <Divider sx={{ mb: 3, mt: 3 }} />
                <Typography variant="h6">
                  {t('common:text.contractation_last_invoice')}
                </Typography>
                <Paper sx={{ padding: 2, mt: 1 }} variant="outlined">
                  {/* TODO: Remove FlowRow and use MUI components instead */}
                  <FlexRow>
                    <Field
                      name="invoice"
                      component={FileUploadField}
                      min={1}
                      max={3}
                      label={
                        t('common:text.contractation_last_invoice') + 
                        `${Settings?.contractation?.invoiceRequired ? "*" : ""}`
                      }
                      hint={t('common:text.contractation_last_invoice_helper')}
                      anotherLabel={t('common:text.contractation_last_invoice_add')}
                      removeLabel={t('common:text.removorosity Verificatione')}
                      onUpdate={handleUpdateUploadedFiles}
                    />
                  </FlexRow>
                </Paper>
              </>
            }
            {
              Settings?.contractation?.mustAcceptMorosityVerification && (
                <Box mt={5}>
                  <Field
                      name="acceptMorosityVerification"
                      render={({input, meta, ref}) => {
                        return (
                          <Box>
                            <FormLabel>
                              {t("common:text.contractation_must_grant_morosity_verification_p1")}
                            </FormLabel>

                            <RadioGroup
                              defaultValue={Boolean(input.value)}
                              value={Boolean(input.value)}
                              {...input}
                              ref={ref}
                              onChange={(event, value) => input.onChange(value==="true")}
                              name="authorization-radio-group"
                              sx={{mb: 1}}
                            >
                              <FormControlLabel value={true} control={<Radio/>} label={t("common:text.contractation_authorize_grant_morosity_verification")}/>
                              <FormControlLabel value={false} control={<Radio />} label={t("common:text.contractation_unauthorize_grant_morosity_verification")}/>
                            </RadioGroup>
                            { meta.error && meta.touched &&
                              <FormHelperText error={true} >
                                {meta.error}
                              </FormHelperText>
                            }
                            <FormLabel>
                              { t("common:text.contractation_must_grant_morosity_verification_p2") }
                              {
                                t("common:text.contractation_must_grant_morosity_verification_link") && (
                                  <Link color="primary" href={t("common:text.contractation_must_grant_morosity_verification_link")} target="_blank">
                                    { t("common:text.contractation_must_grant_morosity_verification_link_text") }
                                  </Link>
                                )
                              }
                              
                            </FormLabel>
                          </Box>
                        )
                      }}
                    />
                  </Box>
              )
            }

          </Box>
          {Settings?.features?.leads && (
            // TODO: Remove className and add Typography
            <Box className="helper-text" sx={{ mb: 3, mt: 3 }}>
              {isAuthenticated ? (
                leadsNote
              ) : (
                <Field
                  name="leadsConsent"
                  label={leadsNote}
                  component={CheckboxField}
                />
              )}
            </Box>
          )}

            <Divider sx={{mt: 3, mb: 3}}/>
            <Button
              variant={'contained'}
              color={'primary'}
              type="submit"
              // disabled={elecContractation.cupsAvailability.invalid !== null}
              loading={submitting || validating}
            >
              {t('common:text.contractation_next')}
            </Button>
        </form>
      )}
    />
  );
};

export default FormAboutInstallation;
