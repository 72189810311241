import { BoundaryComponent } from "../BoundaryComponent";
import { AreaChart as AreaChartV1, BarChart as BarChartV1 } from "./v1";
import { AreaChart as AreaChartV2, BarChart as BarChartV2 } from "./v2";

const BarChart = (props) => {
  return BoundaryComponent(props, [BarChartV2, BarChartV1]);
}

const AreaChart = (props) => {
  return BoundaryComponent(props, [AreaChartV2, AreaChartV1]);
}

export { BarChart, AreaChart};
