import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreators from "../../actions/gasContractation";
import _ from "lodash";
import RichContent from "../RichContent";

import { LoadingAnimation } from "../LoadingAnimation";

import { Button } from "@mui/material"
import { i18n } from "../../config";

function mapStateToProps(state) {
  return {
    authType: state.auth.type,
    isAuthenticated: state.auth.isAuthenticated,
    ...state.gasContractation,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

const Confirmed = (props) => {

  const handleGoBack = () => {
    if (props.onGoBack) {
      props.onGoBack();
    }
  };

  return (
    <div>
      {_.get(props, "confirmResponse.result") ? (
        <RichContent
          content={props.confirmResponse.result.render.body}
          html={props.confirmResponse.result.render.format === "html"}
        />
      ) : (
        <LoadingAnimation />
      )}
      {_.get(props, "confirmResponse.result.error") && (
        <Button
          variant={'text'}
          onClick={handleGoBack}
          style={{ marginRight: 12 }}
        >
          {i18n.t('common:text.contractation_previous')}
        </Button>
      )}
      {_.get(props, "confirmResponse.status") === "OK" && (
        <div>
          {i18n.t('common:text.contractation_form_sent')}
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirmed);
