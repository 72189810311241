import { useEffect } from "react";
import useI18n from "@/hooks/useI18n";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface ILanguageProvider {
	path: string;
  children: React.ReactNode;
}

// This provider checks the url and the current cookies for authentication
export const LanguageProvider = ({
	path,
  children
}: ILanguageProvider) => {
	const params = useParams();	
	const location = useLocation();
	const navigate = useNavigate();
	const { i18n, t } = useTranslation();
  const { currentLang } = useI18n();

  useEffect(() => {

		if (path && i18n.exists(path)) {
			const shouldBePath = t(path.replace("{{", ":").replace("}}", ""), params);

			if ( shouldBePath !== location.pathname ) {
				navigate(shouldBePath);
			}
		}
  }, [currentLang.value]);

  return children
}