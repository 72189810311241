import Settings from "../../settings";

import { NavigateMenu, INavigateMenu } from "@gisce/oficina-virtual-components";
import { 
	HeaderListItems,
	MainListItems,
	BottomListItems
} from "./Drawer";
import MainItems from "./Header/MainItems";
import ExtraItems from "./Header/ExtraItems";
import { RootState } from "@/store";
import { useSelector } from "react-redux";

const NavigationMenu = () => {
	const { isRefreshing } = useSelector((store: RootState) => store.auth);

	const NavigateMenuProps: INavigateMenu = {
		orientation: Settings?.header?.variant,
		headerProps: {
			mainItems: {
				...(!isRefreshing && {
					upLg: <MainItems.UpLg />,
					upSm: <MainItems.UpSm />,
					upXs: <MainItems.UpXs />
				})
			},
			extraItems: {
				upLg: <ExtraItems.UpLg />,
				upSm: <ExtraItems.UpSm />,
				upXs: <ExtraItems.UpXs />,
			}
		},
		drawerProps: {
			headerChildren: <HeaderListItems />,
			items: <MainListItems />,
			bottomItems: <BottomListItems />
		}
	};

	return (
		// <header role="banner">
			<NavigateMenu {...NavigateMenuProps} />
		// </header>
	);
};

export default NavigationMenu;