import { RootState } from "@/store"
import { UserInfo as UserInfoComponent } from "@gisce/oficina-virtual-components"
import { useSelector } from "react-redux"

export const UserInfo = () => {
	const profile = useSelector((state: RootState) => state.profile);
	
	return profile?.data && <UserInfoComponent 
		avatarPosition="left" 
		email={profile.data.email}
		name={profile.data.name}
	/>
}