import { Helmet as HelmetComponent } from "react-helmet-async";
import { useTranslation } from "react-i18next";

export const Helmet = () => {
  const { t } = useTranslation();

	return (
		<HelmetComponent>
			<title>{t('common:text.title')}</title>
		</HelmetComponent>
	)
}