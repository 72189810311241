import {
  FETCH_CRM_CASE_LIST,
  FETCH_CRM_CASE_DETAIL,
  FETCH_CRM_CASE_SECTIONS,
  FETCH_CRM_CASE_CATEGORIES,
  ADD_CRM_CASE_MESSAGE,
  OPEN_CRM_CASE_MESSAGE,
} from "../constants";

import _ from "lodash";

const reducers = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CRM_CASE_LIST:
      return {
        ...state,
        message: action.payload.message,
        fetchedSectionCases: action.payload.fetchedSectionCases,
        items: action.payload.data,
      };
    case FETCH_CRM_CASE_DETAIL:
      return {
        ...state,
        message: action.payload.message,
        case: action.payload.data,
      };
    case FETCH_CRM_CASE_SECTIONS:
      return {
        ...state,
        sections: action.payload.data,
        fetchedSections: action.payload.fetchedSections,
        categories: [],
      };
    case FETCH_CRM_CASE_CATEGORIES:
      return {
        ...state,
        categories: action.payload.data,
      };
    case ADD_CRM_CASE_MESSAGE:
      const nMails = _.castArray(state.case.mails).concat(action.payload.data);
      return {
        ...state,
        case: {
          ...state.case,
          mails: nMails,
          answerOpen: false,
          attachments: action.payload.data.attachments,
        },
      };
    case OPEN_CRM_CASE_MESSAGE:
      return {
        ...state,
        case: {
          ...state.case,
          answerOpen: true,
        },
      };
    default:
      return state;
  }
};

export default reducers;