/* eslint max-len: 0, no-param-reassign: 0 */
import encHex from "crypto-js/enc-hex";
import sha256 from "crypto-js/sha256";
import queryString from "query-string";
import { camelCase, snakeCase } from "lodash";


const validRandomChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

export const hex2rgba = (hex, opacity = 1) => {
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity})`;
};

export function createConstants(...constants) {
    return constants.reduce((acc, constant) => {
        acc[constant] = constant;
        return acc;
    }, {});
}

export function createReducer(initialState, reducerMap) {
    return (state = initialState, action) => {
        const reducer = reducerMap[action.type];


        return reducer
            ? reducer(state, action.payload)
            : state;
    };
}

export function parseJSON(response) {
    return response.data;
}

export const objKeysToCase = (obj, caseType = "camel", stringToRemove = "") => {
    let convertedObj;
    if (Object.keys(obj).length) {
        convertedObj = {};
        let caseFn;
        switch (caseType) {
            case "snake":
                caseFn = snakeCase;
                break;
            case "camel":
            default:
                caseFn = camelCase;
                break;
        }

        Object.keys(obj).forEach((key) => {
            const trimmedKey = key.replace(stringToRemove, "");
            convertedObj[caseFn(trimmedKey)] = obj[key];
        });
    } else {
        convertedObj = obj;
    }
    return convertedObj;
};

export function fetchFromObject (obj, prop) {
    if(typeof obj === 'undefined') {
        return false;
    }

    var _index = prop.indexOf('.')
    if(_index > -1) {
        return fetchFromObject(obj[prop.substring(0, _index)], prop.substr(_index + 1));
    }

    return obj[prop];
}

function iterateJSONSchemaReferences(element, definitions){
    if (Object.keys(element).length) {
        for (let [key, value] of Object.entries(element)) {
            // iterate value if possible
            if (value != null && typeof value == "object" ) {
                iterateJSONSchemaReferences(value, definitions)
            }

            // if there is a reference
            if (key === "$ref") {
                const definition_reference = value.replace("#/definitions/", "")
                const matched_definition = definitions[definition_reference];

                //Skip processing if properties are not finded
                if (!("properties" in matched_definition))
                    continue;

                element.properties = matched_definition.properties;
                delete element["$ref"];
            }
        }
    }
}

export function processJSONSchemaReferences(schema) {
    const schema_template = {
        type: "object",
        properties: {},
        required: [],
    };

    let new_schema = Object.assign({}, schema_template)

    if ("$ref" in schema) {
        const definitions = schema.definitions;
        iterateJSONSchemaReferences(schema, definitions);

        new_schema.properties = Object.assign(new_schema.properties, definitions);
    } else {
        new_schema = schema;
    }
    return new_schema;
}


export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export function randomString(length = 40) {
    let result = '';
    for (let i = length; i > 0; --i) {
        result += validRandomChars[Math.floor(Math.random() * validRandomChars.length)];
    }
    return result;
}

export const generateHash = (str) => {
    return str ? sha256(str).toString(encHex) : null;
};

export const getUrlParams = () => {
  return queryString.parse(window.location.search);
}

export const addParamToResource = (paramName, paramValue, resource) => {
    const s = resource.split('?');
    let params = queryString.parse(s[1]);
    params[paramName] = paramValue;
    const newParamsStr = queryString.stringify(params);
    return `${s[0]}?${newParamsStr}`;
}

export const isDefined = (x) => typeof x !== "undefined" && x !== null;

export const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  };


