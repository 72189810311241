import { useState, useEffect } from "react";
import { Skeleton } from '@mui/material';

const useTable = ({ columns, loaded, data }) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    let contentRows;
    if (!loaded) {
      // S'ha de fer l'equivalent del computeRow
      contentRows = generateTableSkeleton(3);
    } else {
      contentRows = data?.map(item => computeRow(item));
    }
    setRows(contentRows);
  }, [loaded]);

  const computeRow = (data) => {
    let r = {}
    for (let column of columns) {
      r[column.key] = column.computeValue(data)
    }
    return r
  }

  const generateTableSkeleton = (rowsQuantity) => {
    const iterate = Array.from(Array(rowsQuantity));
    return iterate.map((row, i) => {
      let newSkeletonRow = { id: i };
      columns.map((column, j) => newSkeletonRow[column.key] = <Skeleton variant="text" key={j + "" + i} />);
      return newSkeletonRow;
    });
  }

  return {
    rows
  }
}

export default useTable;