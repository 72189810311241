import React from "react";
import PropTypes from "prop-types";
import { RichContent } from "@gisce/oficina-virtual-components";
import { Help } from "@mui/icons-material"

import { Paper, Alert } from "@mui/material"

const style = {
  container: {
    marginTop: '1rem',
    marginBottom: '2rem',
  },
  icon: {
    marginLeft: '1rem',
    marginRight: '1rem',
    position: 'relative',
  }
};

export default class HelpMessage extends React.Component {
  render() {
    return (
      <Paper elevation={5} style={style.container}>
        <Alert severity="info" icon={<Help fontSize="inherit"/>}>
          <RichContent style={{padding: 0}} content={this.props.message}/>
        </Alert>
      </Paper>
    );
  }
}

HelpMessage.propTypes = {
  message: PropTypes.string,
};
