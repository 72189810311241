import { useEffect, useState } from "react";

import { Grid, TextField, Autocomplete } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { useTranslation } from "react-i18next";
import useTypes from "./useTypes";

const DatePickerFilter = ({defaultInputType, resetValue, categoryName, handleFilterValueChange}) => {
  const { dateTypes: types } = useTypes();
  const [inputType, setInputType] = useState(types[defaultInputType]); 
  const [innerValue, setInnerValue] = useState(types[defaultInputType].defaultValue);

  const { i18n } = useTranslation();

  const handleValueChange = (newValue = innerValue, type = inputType) => {
    setInnerValue(newValue);
    handleFilterValueChange(newValue, type.render);
  }

  const handleInputTypeChange = (newInputType) => {
    if (Array.isArray(newInputType.defaultValue) !== Array.isArray(inputType.defaultValue)) {
      handleValueChange(newInputType.defaultValue, newInputType);
    } else {
      handleValueChange(innerValue, newInputType);
    }
    setInputType(newInputType);
  }

  useEffect(() => {
    handleValueChange();
  }, [categoryName]);

  useEffect(() => {
    handleValueChange(inputType.defaultValue);
  }, [resetValue]);

  const getNumericField = () => {
    switch (inputType?.input) {
      case "picker": 
        return <Grid item xs>
          <DatePicker
            disableToolbar
            slotProps={{ textField: { size: 'small', variant: 'outlined' } }}
            format="DD/MM/YYYY"
            id="date-picker-inline"
            label="Data"
            value={innerValue}
            sx={{width: "100%"}}
            onChange={(newValue) => handleValueChange(newValue, inputType)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>

      case "double-picker": 
        return <>
          <Grid item xs={12} sm={6}>
            <DatePicker
              disableToolbar
              slotProps={{ textField: { size: 'small', variant: 'outlined' } }}
              format="DD/MM/YYYY"
              id="date-picker-inline"
              label="Inici"
              value={innerValue[0]}
              onChange={(newVal) => handleValueChange([newVal, innerValue[1]])}
              sx={{width: "100%"}}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <DatePicker
              disableToolbar        
              slotProps={{ textField: { size: 'small', variant: 'outlined' } }}
              format="DD/MM/YYYY"
              id="date-picker-inline"
              label="Fi"
              value={innerValue[1]}
              onChange={(newVal) => handleValueChange([innerValue[0], newVal])}
              sx={{width: "100%"}}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
        </>
      
      default:
        return <></>;
    }
  }

  return  (
    <Grid item container xs spacing={1}>
      <Grid item xs={12} md={3}>
        <Autocomplete 
          options={Object.values(types)}
          getOptionLabel={(option) => option.title}
          // getOptionSelected={(option, selected) => option.title === selected.title}
          renderInput={(params) => <TextField {...params} label={"Tipus"} variant="outlined" size="small"/>}
          onChange={(event, newInputType) => handleInputTypeChange(newInputType)}
          disableClearable={inputType !== null}
          value={inputType}
        />
      </Grid>

      <Grid item container xs spacing={1}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
          {getNumericField()}
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
}

export default DatePickerFilter;