import {
  RECIEVE_BANNERS,
  FETCH_BANNERS,
  SET_BANNERS_ERRORS,
} from '../constants/index';
import {
  data_fetch_api_resource,
} from '../utils/http_functions';

export function recieveBanners(bannerCode, bannerValue) {
  return {
    type: RECIEVE_BANNERS,
    payload: {bannerCode, bannerValue}
  }
}

export function setBannersErrors(bannerCode, bannerError) {
  return {
    type: SET_BANNERS_ERRORS,
    payload: {bannerCode, bannerError}
  }
}

export const fetchBanners = (bannerCode) => async (dispatch, getState) => {
  const { token } = getState().auth;
  dispatch({ 
    type: FETCH_BANNERS,
    payload: {bannerCode}
  });

  return data_fetch_api_resource(token, `banners/${bannerCode}`, 2)
    .then( response => {
      dispatch(recieveBanners(bannerCode, response.data?.banner));
    })
    .catch( reason => {
      console.log('reason',reason)
      dispatch(setBannersErrors(bannerCode, reason.response.data))
    })
}