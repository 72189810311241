import {
  RECOVER_USER_REQUEST,
  RECOVER_USER_FAILURE
} from "@/constants";
import { ask_recover } from "@/utils/http_functions";
import { Dispatch } from "redux";

// TODO: Literalment mai s'ha fet servir això ni està implementat

export const recoverUser = (email: string) => {
  return (dispatch: Dispatch) => {
    dispatch(recoverUserRequest());
    return ask_recover(email)
    .then(({data}) => {
      try {
        // dispatch(loginUserSuccess(data.token));
      } catch (e) {
        alert(e);
        // dispatch(recoverUserFailure());
      }
    })
    .catch (error => {
      dispatch(recoverUserFailure(error));
    });
  };
}

export function recoverUserRequest() {
  return {
    type: RECOVER_USER_REQUEST,
  };
}

export function recoverUserFailure({ statusCode = 403, statusText = '', statusType = "error" }) {
  return {
    type: RECOVER_USER_FAILURE,
    payload: {
      statusCode,
      statusText, // "This service is not available right now. Try it in a few minutes please.",
      statusType
    },
  };
}