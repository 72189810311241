import { useContext } from "react";
import { MoveVirtualBatteryContext } from "./MoveVirtualBatteryContextProvider";

const useMoveVirtualBatteryContext = () => {
  const context = useContext(MoveVirtualBatteryContext);

  if (context === undefined) {
    throw new Error('useMoveVirtualBatteryContext must be used within a BateriaVirtualMoveContextProvider');
  }
  return context;
}


export default useMoveVirtualBatteryContext;
