import { useDispatch, useSelector } from "react-redux";
import { Field, Form } from "react-final-form";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { Button } from "@gisce/oficina-virtual-components";
import { 
  Home, AccountBalance, AccountCircle, 
  MoneyOff, PlaylistAddCheck, WbIncandescent, Whatshot 
} from '@mui/icons-material';
import _ from "lodash";
import { submitConfirmData as submitConfirmGasData } from "../../actions/gasContractation";
import Settings from "../../settings";
import Address from "../Address";
import CheckboxField from "../form-fields/CheckboxField";
import { useTranslation } from "react-i18next";


const Confirm = ({onSubmit, onGoBack}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const elecContractation = useSelector((state) => state.electricityContractation);
  const gasContractation = useSelector((state) => state.gasContractation);

  const handleSubmit = async (values) => {
    values = Object.assign({}, elecContractation, values)
    dispatch(submitConfirmGasData(values));

    await onSubmit(values);
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          promotionalCode: elecContractation.promotionalCode,
          acceptOVConditions: elecContractation.acceptOVConditions,
          acceptGdpr: elecContractation.acceptGdpr,
        }}
        validate={(values) => {
          const errors = {};

          if (!isAuthenticated) {
            if (!values.acceptOVConditions) {
              _.set(errors, "acceptOVConditions", t('common:text.required_field'));
            }
            if (!values.acceptGdpr) {
              _.set(errors, "acceptGdpr", t('common:text.required_field'));
            }
          }

          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>

            <Box style={{ marginTop: 15 }}>
              <Box display={"flex"} style={{margin: 0}} alignItems="center">
                <Home fontSize="medium" color="secondary" sx={{mr: 1}}/>
                <Typography variant="h6" style={{alignSelf: "center"}}>
                  {t('common:text.contractation_about_installation')}
                </Typography>
              </Box>
            </Box>

            <Grid container sx={{ml: {md: 5}, mr: {md: 5}}}>
              <Grid item xs={12} sm={3} md={2}>
                <Typography variant="body1" style={{fontWeight: "bold"}}>
                  {t('common:text.contractation_supply_type_validation')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={10}>
                  <Typography variant="body1">
                  {t(`common:text.contractation_${elecContractation.residenceType}_supply`)}
                </Typography>
              </Grid>
              {!_.isEmpty(elecContractation.address) &&
                <Grid container item xs={12}>
                  <Grid item xs={12} sm={3} md={2}>
                    <Typography variant="body1" style={{fontWeight: "bold"}}>
                      {t('common:text.contractation_address_validation')}
                    </Typography>
                  </Grid>
                    <Grid item xs={12} sm={9} md={10}>
                    <Address readonly value={elecContractation.address} />
                  </Grid>
                </Grid>
              }
              <Grid item container xs={12}>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body1" style={{fontWeight: "bold"}}>
                    {t('common:text.contractation_cnae_validation')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={10}>
                  {_.get(elecContractation, "cnae.descripcio", "?")}
                </Grid>
              </Grid>
            </Grid>

            <Box style={{ marginTop: 15 }}>
              <Box display={"flex"} style={{margin: 0}} alignItems="center">
                <AccountCircle fontSize="medium" color="secondary" sx={{mr: 1}}/>
                <Typography variant="h6" style={{alignSelf: "center"}}>
                  {t('common:text.contractation_about')}{" "}
                  {elecContractation.company
                    ? t('common:text.contractation_enterprise')
                    : t('common:text.contractation_you')}
                </Typography>
              </Box>

              <Grid container sx={{ml: {md: 5}, mr: {md: 5}}}>
                <Grid item xs={12} sm={3} md={2}>
                  <Typography variant="body1" style={{fontWeight: "bold"}}>
                    {!elecContractation.company && t('common:text.contractation_name_validation')}
                    {elecContractation.company && t('common:text.contractation_company_validation')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={10}>
                  <Typography variant="body1">
                    {!elecContractation.company && <>{elecContractation.surName1} {elecContractation.surName2}, {elecContractation.name}</>}
                    {elecContractation.company && elecContractation.name}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={3} md={2}>
                  <Typography variant="body1" style={{fontWeight: "bold"}}>
                    {t('common:text.contractation_dni_validation')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={10}>
                  <Typography variant="body1">
                    {_.get(elecContractation, "vat", "").replace("ES", "")}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={3} md={2}>
                  <Typography variant="body1" style={{fontWeight: "bold"}}>
                    {t('common:text.contractation_mobile_validation')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={10}>
                  <Typography variant="body1">
                    {elecContractation.mobile}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={3} md={2}>
                  <Typography variant="body1" style={{fontWeight: "bold"}}>
                    {t('common:text.contractation_email_validation')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={10}>
                  <Typography variant="body1">
                    {elecContractation.email}
                  </Typography>
                </Grid>

                {!_.isEmpty(elecContractation.invoiceAddress) &&
                  <Address readonly value={elecContractation.invoiceAddress} />}

                {elecContractation.company &&
                  <>
                    <Grid item xs={12} sm={3} md={2}>
                      <Typography variant="body1" style={{fontWeight: "bold"}}>
                        {t('common:text.contractation_legal_representative')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={9} md={10}>
                      <Typography variant="body1">
                        {elecContractation.surName1Representante}{" "}
                        {elecContractation.surName2Representante}, {elecContractation.nameRepresentante}{" "}
                        {elecContractation.vatRepresentante.replace("ES", "")}
                      </Typography>
                    </Grid>
                  </>
                }
              </Grid>
            </Box>


            {elecContractation.selectedProduct &&
              <Box style={{ marginTop: 15 }}>
                <Box display={"flex"} style={{margin: 0}} alignItems="center">
                  <WbIncandescent fontSize="medium" color="secondary" sx={{mr: 1}}/>
                  <Typography variant="h6" style={{alignSelf: "center"}}>
                    {t('common:text.contractation_about_contract')}
                  </Typography>
                </Box>

                <Grid container sx={{ml: {md: 5}, mr: {md: 5}}}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1" style={{fontWeight: "bold"}}>
                      {t('common:text.contractation_cups_validation')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body1">
                      {elecContractation.cups}
                    </Typography>
                  </Grid>

                  <>
                    <Grid item xs={12} sm={3} md={2}>
                      <Typography variant="body1" style={{fontWeight: "bold"}}>
                        {t('common:text.contractation_tariff')}:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={9} md={10}>
                      <Typography variant="body1">
                        {elecContractation.selectedProduct.name}
                      </Typography>
                    </Grid>
                  </>

                  {elecContractation.selfcons && <>
                    <Grid item xs={12} sm={3} md={2}>
                      <Typography variant="body1" style={{fontWeight: "bold"}}>
                        {t('common:text.contractation_selfcons')}:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={9} md={10}>
                      <Typography variant="body1">
                        {t('common:text.generic_yes')}
                      </Typography>
                    </Grid>
                  </>}
                </Grid>
              </Box>
            }

            {gasContractation.selectedProduct &&
              <Box style={{ marginTop: 15 }}>
                <Box display={"flex"} style={{margin: 0}} alignItems="center">
                  <Whatshot fontSize="medium" color="secondary" sx={{mr: 1}}/>
                  <Typography variant="h6" style={{alignSelf: "center"}}>
                    {t('common:text.contractation_about_gasContractation_contract')}
                  </Typography>
                </Box>

                <Grid container sx={{ml: {md: 5}, mr: {md: 5}}}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1" style={{fontWeight: "bold"}}>
                      {t('common:text.contractation_cups_validation')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body1">
                      {gasContractation.cups}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={3} md={2}>
                    <Typography variant="body1" style={{fontWeight: "bold"}}>
                      {t('common:text.contractation_tariff')}:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={9} md={10}>
                    <Typography variant="body1">
                      {gasContractation.selectedProduct.name}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            }
            
            {elecContractation.iban &&
              <Box style={{ marginTop: 15 }}>
                <Box display={"flex"} style={{margin: 0}} alignItems="center">
                  <AccountBalance fontSize="medium" color="secondary" sx={{mr: 1}}/>
                  <Typography variant="h6" style={{alignSelf: "center"}}>
                    {t('common:text.contractation_about_payment')}
                  </Typography>
                </Box>

                <Grid container sx={{ml: {md: 5}, mr: {md: 5}}}>
                  <Grid item xs={12} sm={3}>
                    <Typography variant="body1" style={{fontWeight: "bold"}}>
                      {t('common:text.contractation_iban_direct_debit')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Typography variant="body1">
                      {elecContractation.iban}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            }

            { Settings?.features?.promotionalCode && 
              elecContractation.promotionalCode.length > 0 &&

              <Box style={{ marginTop: 15 }}>
                <Box display={"flex"} style={{margin: 0}} alignItems="center">
                  <MoneyOff fontSize="medium" color="secondary" sx={{mr: 1}}/>
                  <Typography variant="h6" style={{alignSelf: "center"}}>
                    {t('common:text.contractation_promotional_code_applied_title')}
                  </Typography>
                </Box>

                <Grid container sx={{ml: {md: 5}, mr: {md: 5}}}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1" style={{fontWeight: "bold"}}>
                      {t('common:text.contractation_promotional_code')}:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    {elecContractation.promotionalCode.map(
                      promocode => <p>{promocode}</p>
                    )}
                  </Grid>
                </Grid>

              </Box>
            }

            {!isAuthenticated &&
              <Box style={{ marginTop: 15 }}>
                <Box display={"flex"} style={{margin: 0}} alignItems="center">
                  <PlaylistAddCheck fontSize="medium" color="secondary" sx={{mr: 1}}/>
                  <Typography variant="h6" style={{alignSelf: "center"}}>
                    {t('common:text.contractation_conditions')}
                  </Typography>
                </Box>

                <Grid container sx={{ml: {md: 5}, mr: {md: 5}}}>
                  <Grid item xs={12}>
                    <Field
                      name="acceptOVConditions"
                      label={t('common:text.contractation_ov_aceptation')}
                      component={CheckboxField}
                    />
                    <a
                      style={{ marginTop: 17 }}
                      target="_blank"
                      href={_.get(Settings, "links.conditions", "")}
                      rel="noreferrer"
                    >
                      {t('common:text.contractation_ov_conditions')}
                    </a>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="acceptGdpr"
                      label={t('common:text.contractation_privacy_policy_aceptation')}
                      component={CheckboxField}
                    />
                    <a
                      style={{ marginTop: 17 }}
                      target="_blank"
                      href={_.get(Settings, "links.gdpr", "")}
                      rel="noreferrer"
                    >
                      {t('common:text.contractation_privacy_policy')}
                    </a>
                  </Grid>
                </Grid>
              </Box>
            }

            <Divider sx={{mt: 3, mb: 3}}/>
            <Button
              variant={'text'}
              onClick={onGoBack}
              style={{ marginRight: 12 }}
              disabled={submitting}
            >
              {t('common:text.contractation_previous')}
            </Button>
            <Button
              type="submit"
              variant={'contained'}
              color={'primary'}
              loading={submitting}
            >
              {t('common:text.contractation_send')}
            </Button>
          </form>
        )}
      />
    </>
  );
};

export default Confirm;