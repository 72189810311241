import {
  RECIEVE_BANNERS, 
  FETCH_BANNERS,
  SET_BANNERS_ERRORS,
} from '../constants';
import { createReducer } from '../utils/misc';

const initialState = {
    errors: {},
    fetching: {},
    banners: {},
};

export default createReducer(initialState, {
  [RECIEVE_BANNERS]: (state, payload) => {
    const bannerCode = payload.bannerCode
    const bannerValue = payload.bannerValue
    const newState = Object.assign({}, state, {
      banners: {...state.banners}
    })
    newState.banners[bannerCode] = bannerValue;
    delete newState['fetching'][bannerCode]
    return newState
  },
  [FETCH_BANNERS]: (state, payload) => {
    const bannerCode = payload.bannerCode
    const newState = Object.assign({}, state, {
      fetching: {...state.fetching}
    })
    newState.fetching[bannerCode] = true;
    delete newState.banners[bannerCode];
    delete newState['errors'][bannerCode]
    return newState
  },
  [SET_BANNERS_ERRORS]: (state, payload) => {
    const bannerCode = payload.bannerCode
    const bannerError = payload.bannerError
    const newState = Object.assign({}, state, {
      errors: {...state.errors}
    })
    newState.errors[bannerCode] = bannerError
    delete newState['fetching'][bannerCode]
    return newState
  }
});
