import { 
	LOGIN_REFRESH_FAILURE, 
	LOGIN_REFRESH_REQUEST, 
	LOGIN_REFRESH_SUCCESS 
} from "@/constants";
import { 
	define_token, 
	undefine_token, 
	validate_token
} from "@/utils/http_functions";
import { Dispatch } from "redux";

export function loginRefreshRequest() {
	return {
			type: LOGIN_REFRESH_REQUEST
	};
}

export function loginRefreshSuccess(token: string) {
	define_token(token);
	
	return {
			type: LOGIN_REFRESH_SUCCESS,
			payload: {
				token,
			},
	};
}

export function loginRefreshFailure({statusCode = 403, statusText = '', statusType = "error"}) {
undefine_token();

return {
	type: LOGIN_REFRESH_FAILURE,
	payload: {
		statusCode,
		statusText, // ?? errorMessageHandler(status), TODO?
		statusType,
	},
};
}

export function loginRefresh(token: string) {
return (dispatch: Dispatch) => {
	dispatch(loginRefreshRequest());
	validate_token(token)
	.then(res => {
		if (res.status === 200) {
			dispatch(loginRefreshSuccess(token));
		} else {
			dispatch(loginRefreshFailure({statusCode: res.status, statusType: "warning"}));
		}
	})
	.catch(error => {
		// Token probably not valid or has expired so we remove it
		dispatch(loginRefreshFailure({statusCode: error.status, statusType: "warning"}));
	});
	}
}