import React from "react";
import GasProductSelect from "../GasProductSelect";

const GasProductSelectField = ({ input, meta, ...rest }) => {
  return (
    <div>
      <GasProductSelect
        {...input}
        {...rest}
      />
      {meta.error && meta.touched && (
        <span
          style={{
            fontSize: "12px",
            lineHeight: "12px",
            color: "rgb(244, 67, 54)",
          }}
        >
          {meta.error}
        </span>
      )}
    </div>
  );
};

export default GasProductSelectField;
