import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Paper, Typography, Divider } from "@mui/material";
import { Button } from "@gisce/oficina-virtual-components";
import { Field, Form } from "react-final-form";
import * as actionCreators from "../../actions/electricityContractation";
import Settings from "../../settings";
import AccessTariffSelectField from "../form-fields/AccessTariffSelectField";
import CheckboxField from "../form-fields/CheckboxField";
import ElectricityProductSelectField from "../form-fields/ElectricityProductSelectField";
import FileUploadField from "../form-fields/FileUploadField";
// import PowerField from "../form-fields/PowerField";
import RadioGroupField from "../form-fields/RadioGroupField";
import CouponField from "../form-fields/CouponField";
import { useTranslation } from "react-i18next";


const FormAboutContract = ({onGoBack, onSubmit}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const elecContractration = useSelector((state) => state.electricityContractation);
  const [tariffName, setTariffName] = useState(elecContractration?.accessTariffName ?? '');

  const handleSubmit = async (values) => {
    values = Object.assign({}, elecContractration, values)
    await onSubmit(values)
  };

  const handleAccessTariffSelectUpdate = async (value, tariff_name) => {
    dispatch(actionCreators.setAccessTariff(value, tariff_name));
    setTariffName(tariff_name);
  };

  const handlePowerUpdate = async (value) => {
    dispatch(actionCreators.setPower(value));
  };

  const handlePower2Update = async (value) => {
    dispatch(actionCreators.setPower2(value));
  };

  const handlePower3Update = async (value) => {
    dispatch(actionCreators.setPower3(value));
  };

  const handlePower4Update = async (value) => {
    dispatch(actionCreators.setPower4(value));
  };

  const handlePower5Update = async (value) => {
    dispatch(actionCreators.setPower5(value));
  };

  const handlePower6Update = async (value) => {
    dispatch(actionCreators.setPower6(value));
  };

  const handleSelfconsUpdate = async (value) => {
    dispatch(actionCreators.setSelfcons(value));
  };

  const handleSelfconsAttachmentUpdate = async (value) => {
    dispatch(actionCreators.setSelfconsAttachment(value));
  };

  const handleCouponChange = async (value) => {
    console.log(value);
    props.setCoupon(value);
  };

  const sixPowers = () => {
    //return (tariffName.charAt(0) === "3")
    return (elecContractration.power > 15 || elecContractration.power2 > 15)
  }

  const allPowersFull = () => {
    if (sixPowers()){
      return !!(elecContractration.power && elecContractration.power2 && elecContractration.power3 &&
                elecContractration.power4 && elecContractration.power5 && elecContractration.power6)
    } else {
      return !!(elecContractration.power && elecContractration.power2)
    }
  }

  return (
    <div>
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          power: elecContractration.power,
          power2: elecContractration.power2,
          power3: elecContractration.power3,
          power4: elecContractration.power4,
          power5: elecContractration.power5,
          power6: elecContractration.power6,
          accessTariff: elecContractration.accessTariff,
          selectedProduct: elecContractration.selectedProduct,
          selfcons: elecContractration.selfcons,
          selfconsAttachment: elecContractration.selfconsAttachment,
        }}
        validate={(values) => {
          const errors = {};

          if (!values.power) {
            errors.power = t('common:text.required_field');
          }

          if (!values.power2) {
            errors.power2 = t('common:text.required_field');
          }

          if (sixPowers() && !values.power3) {
            errors.power3 = t('common:text.required_field');
          }

          if (sixPowers() && !values.power4) {
            errors.power4 = t('common:text.required_field');
          }

          if (sixPowers() && !values.power5) {
            errors.power5 = t('common:text.required_field');
          }

          if (sixPowers() && !values.power6) {
            errors.power6 = t('common:text.required_field');
          }

          if (!Number.isInteger(values?.accessTariff)) {
            errors.accessTariff = t('common:text.required_field');
          }
          
          if (!values.selectedProduct) {
            errors.selectedProduct = t('common:text.required_field');
          }

          if (Settings?.contractation?.electricity?.radioGroupVariant && values.selfcons === null) {
            errors.selfcons = t('common:text.required_field');
          }

          if (Settings?.contractation?.electricity?.selfcons?.enabled && values.selfcons &&
                (Settings?.contractation?.electricity?.selfcons?.requireDocumentation ?? true) && !values.selfconsAttachment) {
            errors.selfconsAttachment = t('common:text.required_field');
          }

          console.log(errors);
          return errors;
        }}
        render={({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            {
              (Settings?.contractation?.electricity?.selfcons?.showSellMessage ?? true) &&
                <Typography style={{fontWeight: "bold"}}>
                  {t('common:text.contractation_selfcons_message')}
                </Typography>
            }
            <>
              <Grid container spacing={3}>
                {!elecContractration.sipsData &&
                  <Grid item sm={12} md={6}>
                    <Box sx={{ml: {md: 5}, mr: {md: 5}, mb: 2, mt: 2}}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <Field
                            name="power"
                            label={t('common:text.contractation_power')}
                            // component={PowerField}
                            onUpdate={handlePowerUpdate}
                            style={{width: "100%"}}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            name="power2"
                            label={t('common:text.contractation_power2')}
                            // component={PowerField}
                            onUpdate={handlePower2Update}
                            style={{width: "100%"}}
                          />
                        </Grid>
                        {sixPowers() &&
                          <Grid item xs={12} sm={6}>
                            <Field
                              name="power3"
                              label={t('common:text.contractation_power3')}
                              // component={PowerField}
                              onUpdate={handlePower3Update}
                              style={{width: "100%"}}
                            />
                          </Grid>
                        }
                        {sixPowers() &&
                          <Grid item xs={12} sm={6}>
                            <Field
                              name="power4"
                              label={t('common:text.contractation_power4')}
                              // component={PowerField}
                              onUpdate={handlePower4Update}
                              style={{width: "100%"}}
                            />
                          </Grid>
                        }
                        {sixPowers() &&
                          <Grid item xs={12} sm={6}>
                            <Field
                              name="power5"
                              label={t('common:text.contractation_power5')}
                              // component={PowerField}
                              onUpdate={handlePower5Update}
                              style={{width: "100%"}}
                            />
                          </Grid>
                        }
                        {sixPowers() &&
                          <Grid item xs={12} sm={6}>
                            <Field
                              name="power6"
                              label={t('common:text.contractation_power6')}
                              // component={PowerField}
                              onUpdate={handlePower6Update}
                              style={{width: "100%"}}
                            />
                          </Grid>
                        }
                        <Grid item xs={12} sm={6}>
                          <Field
                            name="accessTariff"
                            component={AccessTariffSelectField}
                            power={{
                              power: elecContractration.power, power2: elecContractration.power2, power3: elecContractration.power3,
                              power4: elecContractration.power4, power5: elecContractration.power5, power6: elecContractration.power6
                            }}
                            initialTariff={elecContractration.accessTariff}
                            onSelectUpdate={handleAccessTariffSelectUpdate}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                }
                { (Settings?.contractation?.electricity?.selfcons?.enabled ?? true) &&
                  <Grid item sm={12} md={6}>
                    <Paper style={{padding: 15}}>
                      { Settings?.contractation?.electricity?.radioGroupVariant ?
                        <Field
                          name="selfcons"
                          label={<Typography variant="h6" color="primary">{t('common:text.contractation_selfcons')}</Typography>}
                          component={RadioGroupField}
                          onUpdate={handleSelfconsUpdate}
                          radioElements={
                            [
                              {label: t("common:text.generic_yes"), val: true, style: {width: "25%"}},
                              {label: t("common:text.generic_no"), val: false, style: {width: "25%"}}
                            ]
                          }
                          row
                        />
                      :
                        <Field
                          name="selfcons"
                          label={<Typography variant="h6" color="primary">{t('common:text.contractation_selfcons')}</Typography>}
                          component={CheckboxField}
                          onUpdate={handleSelfconsUpdate}
                        />
                      }
                      {values.selfcons && (
                        <Field
                          name="selfconsAttachment"
                          component={FileUploadField}
                          onUpdate={handleSelfconsAttachmentUpdate}
                          min={1}
                          max={3}
                          label={t('common:text.contractation_selfcons_document')}
                          hint={t('common:text.contractation_selfcons_helper')}
                          anotherLabel={t('common:text.contractation_selfcons_add')}
                          removeLabel={t('common:text.remove')}
                        />
                      )}
                    </Paper>
                  </Grid>
                }
              </Grid>
            </>
            {elecContractration.accessTariff && allPowersFull() &&(
              <>
                {Settings?.features?.promotionalCode && 
                  <>
                    <Box m={1} style={{marginLeft: 0}} color="textSecondary">
                      <Typography variant="h6" color="primary">
                        {t('common:text.contractation_promotional_code')}
                      </Typography>
                      <Typography variant="body2" style={{marginBottom: 15}}>
                        {t('common:text.contractation_promotional_detail')}
                      </Typography>
                    </Box>

                    <Field
                      name="promocode"
                      component={CouponField}
                      onChange={handleCouponChange}
                    />
                  </>
                }

                <Box m={1} style={{marginLeft: 0}} color="textSecondary">
                  <Typography variant="h6" color="primary">
                    {t('common:text.contractation_new_pricelist')}
                  </Typography>
                  <Typography variant="body2" style={{marginBottom: 15}}>
                    {t('common:text.contractation_current_pressure_warning')}
                  </Typography>
                </Box>
                <Box sx={{ml: {md: 5}, mr: {md: 5}}}>
                  <Field
                  name="selectedProduct"
                  component={ElectricityProductSelectField}
                  />
                </Box>
              </>
            )}

            <Divider sx={{mt: 3, mb: 3}}/>
            <Button
              variant={'text'}
              onClick={onGoBack}
              style={{ marginRight: 12 }}
              disabled={submitting}
            >
              {t('common:text.contractation_previous')}
            </Button>
            <Button
              type="submit"
              color={'primary'}
              variant={'contained'}
              loading={submitting}
            >
              {t('common:text.contractation_next')}
            </Button>
          </form>
        )}
      />
    </div>
  );
};

export default FormAboutContract;
