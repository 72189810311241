import { useState } from 'react';
import Cookies from "../../utils/cookies";
import Settings from "../../settings";
import { CookieAcceptDialog, CookiePersonalizeDialog } from "@gisce/oficina-virtual-components";
import { Box } from "@mui/material";

const CookieDialog = () => {
  const [showPreferences, setShowPreferences] = useState(false);
  const [showBanner, setShowBanner] = useState(!Cookies.hasAcceptedCookies());

  const acceptedCookiesMap = {
    consent: Cookies.acceptConsentCookies,
    preferences: Cookies.acceptPreferencesCookies,
    statistics: Cookies.acceptStatisticCookies,
    marketing: Cookies.acceptMarketingCookies,
  };

  const acceptCookies = (acceptedCookies) => {
    setShowBanner(false);
    if (acceptedCookies === "all") {
      Cookies.acceptAllCookies();
    } else if (typeof acceptedCookies === "object") {
      for (const [key, value] of Object.entries(acceptedCookies)) {
        if(value) {
          acceptedCookiesMap[key]();
        }
      }
      Cookies.acceptCookies();
    }
  };

  const personalizeCookies = () => {
    setShowPreferences(true);
  };

  return (
    <Box sx={{position: "fixed", bottom: 0, width: "100%", zIndex: 10 }}>
      {showBanner && !showPreferences && <CookieAcceptDialog {...{acceptCookies, personalizeCookies, cookiePolicyHref: Settings.links.cookies}}/>}
      {showBanner && showPreferences && <CookiePersonalizeDialog {...{acceptCookies, cookiePolicyHref: Settings.links.cookies}}/>}
    </Box>
  );
}

export default CookieDialog;