import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import ErrorMessage from "../ErrorMessage";

import { Button, MenuItem, FormControl, Select, InputLabel,
  Dialog, DialogContent, DialogTitle, CircularProgress } from '@mui/material';

import {
  data_update_api_resource,
  data_fetch_api_resource,
} from "../../utils/http_functions";
import { i18n, dayjs } from "../../config";
import { locale_code } from "../../constants/i18n";

class CustomizeHappyHours extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startHour: null,
      endHour: null,

      selectedPeriod: 0,
      dialogOpen: false,
      error: false,
    };
    dayjs.locale(locale_code);
  }

  fetchActual = async () => {
    const response = await data_fetch_api_resource(
      null,
      `contract/${this.props.contractId}/happy-hours/`
    );

    if (response && !_.isEmpty(response.data)) {
      const { data } = response;
      this.setState({
        startHour: data.startHour,
        endHour: data.endHour,
      });
    }
  };

  handleRequestClose = async () => {
    this.setState({ dialogOpen: false });
  };

  handleRequestOpen = async () => {
    // Fetch available options
    this.setState({ error: false });
    try {
      const response = await data_fetch_api_resource(
        null,
        `contract/${this.props.contractId}/available-happy-hours/`
      );

      if (response && !_.isEmpty(response.data)) {
        const periods = response.data;
        this.setState({
          periods,
        });
      }
    } catch (error) {
      this.setState({ error: true });
      // TODO: Add sentry
    }

    this.setState({ dialogOpen: true });
  };

  handleChangePeriod = (event, value) => {
    this.setState({ selectedPeriod: event.target.value });
  };

  handleUpdatePeriod = async () => {
    this.setState({ error: false, sending: true });
    const { periods, selectedPeriod } = this.state;
    console.log(
      "Selected value: " +
        selectedPeriod +
        " - " +
        JSON.stringify(periods[selectedPeriod])
    );
    const selectedPeriodObj = periods[selectedPeriod];
    try {
      await data_update_api_resource(
        null,
        `contract/${this.props.contractId}/happy-hours/`,
        periods[selectedPeriod]
      );
      this.setState({ sending: false });
      this.fetchActual();
      if (this.props.onUpdated) {
        this.props.onUpdated(selectedPeriodObj);
      }
    } catch (error) {
      // TODO: Add sentry
      this.setState({ error: true });
    } finally {
      this.setState({ dialogOpen: false });
    }
  };

  componentDidMount() {
    this.fetchActual();
  }

  formatPeriod = (startHour, endHour) => {
    let period = "";
    if (startHour !== null) {
      period = `${String(startHour).padStart(2, '0')}h - ${String(endHour).padStart(2, '0')}h`;
    }
    return period;
  };

  render() {
    const {
      startHour,
      endHour,
      dialogOpen,
      periods,
      error,
    } = this.state;

    if (typeof startHour == "undefined" || startHour === null || startHour === false) {
      return null;
    }

    let period = this.formatPeriod(startHour, endHour);
    let sending = false;

    let periodEntries = [];
    if (!_.isEmpty(periods)) {
      periodEntries = periods.map((p, index) => {
        return (
          <MenuItem
            key={index}
            value={index}
          >
            {this.formatPeriod(
              p.startHour,
              p.endHour,
            )}
          </MenuItem>
        );
      });
    }

    return (
      <span>
        {period}
        <Button
          style={{ marginLeft: "1rem" }}
          color={'primary'}
          variant={'text'}
          onClick={this.handleRequestOpen}
        >
          {i18n.t('common:text.contract_modification')}
        </Button>
        <Dialog
          open={dialogOpen}
          disableBackdropClick={true}
          onClose={this.handleRequestClose}
          scroll={'body'}
        >
          <DialogTitle>
            {i18n.t('common:text.customize_happy_hours_title')}
          </DialogTitle>
          <DialogContent>
            <div>
              <FormControl>
                <InputLabel shrink htmlFor="">
                  {i18n.t('common:text.customize_happy_hours_label')}
                </InputLabel>
                <Select
                  onChange={this.handleChangePeriod}
                  value={this.state.selectedPeriod || 0}
                  style={{ width: "100%", maxWidth: "300px" }}
                  autoWidth={true}
                >
                  {periodEntries}
                </Select>
              </FormControl>
            </div>
            <div>{error && <ErrorMessage />}</div>
            <div style={{ marginTop: "1rem" }}>
              <Button
                style={{ marginRight: "1rem" }}
                variant={'contained'}
                onClick={this.handleRequestClose}
                disabled={sending}
              >
                {i18n.t('common:text.customize_happy_hours_close')}
              </Button>
              <Button
                variant={'contained'}
                color={'primary'}
                onClick={this.handleUpdatePeriod}
                disabled={sending}
              >
                {sending ? <CircularProgress size={25} /> : null}
                {i18n.t('common:text.customize_happy_hours_update')}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </span>
    );
  }
}

export default CustomizeHappyHours;

CustomizeHappyHours.defaultProps = {
  readonly: false,
};

CustomizeHappyHours.propTypes = {
  readonly: PropTypes.bool,
  contractId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
