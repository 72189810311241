import { useSelector } from "react-redux";
import { BatteryDistributionPreview } from "../../BatteryDistributionPreview";
import VirtualBatteryPercentageSlider from "../../VirtualBatteryPercentageSlider";
import useMoveVirtualBatteryContext from "../useMoveVirtualBatteryContext";
import { Typography } from "@mui/material";
import { i18n } from "../../../../config";

export const MoveVirtualBatteryStep2 = ({ nextStepCallback }) => {
  const {
    selectedBatteryId,
    newPercentage,
    setNewPercentage,
    selectedBatteryDistribution,
  } = useMoveVirtualBatteryContext();
  const { waitingChangeRequest } = useSelector((state) => state.bateriaVirtual);


  return (
    <>
      <VirtualBatteryPercentageSlider
        value={newPercentage}
        aria-labelledby="discrete-slider"
        step={1}
        min={0}
        max={100}
        onChange={(event, newValue) => setNewPercentage(newValue)}
        disabled={waitingChangeRequest}
      />

      {selectedBatteryDistribution && (
        <>
          <Typography variant="h6">
            {i18n.t("common:text.battery_distribution_destination_preview", {"batteryName": selectedBatteryDistribution.batteryName})}:
          </Typography>
          <BatteryDistributionPreview
            currentBatteryDistribution={selectedBatteryDistribution}
            newPercentage={newPercentage}
            selectedBatteryId={selectedBatteryId}
            expectedAction="move"
          />
        </>
      )}
    </>
  );
};

export default MoveVirtualBatteryStep2;
