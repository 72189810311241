import { useLocation } from "react-router-dom";

import "../settings/validators";
import HelpDialog from "./HelpDialog";
import NavigationMenu from "./NavigationMenu/NavigationMenu";

import CookieDialog from './CookieDialog';

import { Outlet } from "react-router-dom";
import Settings from "../settings";
import { AuthTimeout } from "./AuthTimeout";
import { Helmet } from "./Helmet";

const RootPage = () => {

  return (
    <>
      <AuthTimeout />
			<NavigationMenu />

			<section id="app">
				<Helmet />

        <Outlet />
				{ Settings?.helpDialog?.enabled && (
					<HelpDialog />
				)}

				<CookieDialog />
			</section>
    </>
  );
};

export default RootPage;

