import { LogoBar } from "@gisce/oficina-virtual-components"
import { UserInfo } from "@/components/User/UserInfo";
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux";
import { RootState } from "@/store";

export const HeaderListItems = () => {
	const { t } = useTranslation();
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
	
	return <>
		{isAuthenticated && <UserInfo />}
	</>
}