const useVirtualBattery = () => {

  const sortDistribution = ({distribution, sortBy="contractName"}) => {
    return distribution?.sort((a, b) => {
      if (a[sortBy] < b[sortBy]) return -1;
      if (a[sortBy] > b[sortBy]) return 1;
      return 0;
    }) ?? distribution;
  }

  const getAllBateriaVirtualsFromContracts = ({ contracts, excludeContractName }) => {
    let all = [];

    for (const contract of contracts) {
      if (contract.name != excludeContractName) {
        for (const bateriaVirtual of contract.bateria_virtual) {
          all.push(bateriaVirtual);
        }
      }
    }
    return all;
  };

  const getBatteriesDistribution = ({ contracts, excludeContractName, excludeBatteryId }) => {
    let groupedBatteryDistribution = {};

    for (const contract of contracts) {
      if (contract.name != excludeContractName) {
        for (const bateriaVirtual of contract.bateria_virtual) {
          const batteryDistribution = {
            "contractId": contract.id,
            "pes": bateriaVirtual.pes,
            "pesPercentage": bateriaVirtual.pes_percentatge,
            "contractName": contract.name
          }
          if (bateriaVirtual.bateria.id in groupedBatteryDistribution) {
            groupedBatteryDistribution[bateriaVirtual.bateria.id].distribution.push(batteryDistribution);
          } else {
            groupedBatteryDistribution[bateriaVirtual.bateria.id] = {
              batteryId: bateriaVirtual.bateria.id,
              batteryName: bateriaVirtual.bateria.name,
              distribution: [ batteryDistribution ]
            };
          }
        }
      }
    }

    if (excludeBatteryId in groupedBatteryDistribution) {
      delete groupedBatteryDistribution[excludeBatteryId];
    }

    // INFO: Sort the distributions (is not optimized, the stress, sorry, right now I don't care if goes slow, you can fix it)
    for (const batteryDistribution of Object.values(groupedBatteryDistribution)) {
      batteryDistribution.distribution = sortDistribution({ distribution: batteryDistribution.distribution });
    }
     return groupedBatteryDistribution;
  };


  const getBatteryDistribution = ({contracts, batteryId}) => {
    const allDistributted = getBatteriesDistribution({contracts});
    return allDistributted[batteryId];
  }



  return {
    getAllBateriaVirtualsFromContracts,
    getBatteriesDistribution,
    getBatteryDistribution,
    sortDistribution
  };
};

export default useVirtualBattery;
