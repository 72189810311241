import Settings from "../settings";
import {
  data_fetch_api_resource,
  data_create_api_resource,
} from "../utils/http_functions";
import { parseJSON } from "../utils/misc";
import {
  SUBMIT_ABOUT_INSTALLATION_DATA,
  STORE_SIPS_DATA,
  STORE_SIPS_GAS_DATA,
  STORE_CUPS_DATA,
  STORE_RESIDENCE_TYPE_DATA,
  STORE_UPLOADED_INVOICES_DATA,
  SUBMIT_ABOUT_YOU_DATA,
  SET_POWER,
  SET_POWER2,
  SET_POWER3,
  SET_POWER4,
  SET_POWER5,
  SET_POWER6,
  SET_ACTIVE_CONTRACT,
  SET_SELFCONS,
  SET_SELFCONS_ATTACHMENT,
  SET_ACCESS_TARIFF,
  SET_CUPS_VALID,
  SET_CUPS_INVALID,
  SET_COUPON,
  SET_COUPON_VALID,
  SET_COUPON_INVALID,
  SET_COUPON_VALID_REQUEST,
  SUBMIT_ABOUT_CONTRACT_DATA,
  SUBMIT_ABOUT_GAS_CONTRACT_DATA,
  SUBMIT_PAYMENT_DATA,
  SUBMIT_CONFIRM_DATA,
  SELECT_PRODUCT,
  FETCH_AVAILABLE_PRODUCTS_REQUEST,
  RECEIVE_AVAILABLE_PRODUCTS,
  NEW_ELECTRICITY_CONTRACT_CONFIRM_REQUEST,
  NEW_ELECTRICITY_CONTRACT_CONFIRM_RECEIVE,
  FETCH_CONTRACTATION_CONFIG_REQUEST,
  RECEIVE_CONTRACTATION_CONFIG,
  SET_LEAD_ID,
  SET_LEAD_STEP_CURRENT,
  SET_LEAD_INDEX_COMPLETED,
  SET_LEAD_ERRORS,
  SET_LEAD_SUBMIT_REQUEST,
} from "../constants";
import queryString from "query-string";
import * as Sentry from "@sentry/react";
import { i18n } from "../config";
import _ from "lodash";

// ----------------------------------------------------------------------------
// Electricity Contractation Form
// ----------------------------------------------------------------------------
export const setLeadSubmitting = () => {
  return {
    type: SET_LEAD_SUBMIT_REQUEST
  }
}

export const setLeadId = (leadId) => {
  return {
    type: SET_LEAD_ID,
    payload: {"leadId": leadId}
  }
}

export const setLeadStepCompleted = (stepIndex) => {
  return {
    type: SET_LEAD_INDEX_COMPLETED,
    payload: {
      stepIndex
    }
  }
}

export const setLeadCurrentStep = ({index, name}) => {
  return {
    type: SET_LEAD_STEP_CURRENT,
    payload: { index, name }
  }
}

export const sendElectricityLeads = (data, {checkSolvencia, checkDNI, nextStep, currentStep, gasData}) => {
  return async (dispatch) => {
    dispatch(setLeadSubmitting());
    const response = await data_create_api_resource(null, "leads", {
      "FormConfirm": data
    }).catch((e) => {
      Sentry.captureException(e);
    });

    const newLeadErrors = { badScore: null, dniInvalid: null, genericError: null };
    if (response?.status === 200 && response.data?.lead_id) {
      dispatch(setLeadId(response.data.lead_id));

      if (gasData) {
        gasData.electricityLeadId = response.data.lead_id;
        dispatch(sendGasLeads({
          "FormConfirm": gasData
        }));
      }

      // CHECKING LEAD ERRORS
      if (checkSolvencia) Object.assign(newLeadErrors, await checkLeadScore(null, response.data.lead_id));
      if (checkDNI) Object.assign(newLeadErrors, await checkLeadDNI(null, response.data.lead_id));
      dispatch(setLeadsErrors(newLeadErrors));
      if ((!!nextStep.index && !!nextStep.name) && (!newLeadErrors.badScore && !newLeadErrors.dniInvalid)) {
        dispatch(setLeadStepCompleted(currentStep));
        dispatch(setLeadCurrentStep(nextStep));
      }
    } else {
      newLeadErrors["genericError"] = true
      dispatch(setLeadsErrors(newLeadErrors));
    }
  };
};

export const sendGasLeads = (data) => {
  return async (dispatch) => {
    await data_create_api_resource(null, "gas/leads", data, 2).catch((e) => {
      Sentry.captureException(e);
    });
  }
};

export const setLeadsErrors = (data) => {
  return {
    type: SET_LEAD_ERRORS,
    payload: data
  };
};

export const resetLeadsErrors = () => {
  return async (dispatch) => {
    dispatch(setLeadsErrors({
      badScore: null,
      dniInvalid: null,
    }))
  }
};

export const checkLeadScore = async (token, lead_id) => {
  const response = await data_fetch_api_resource(token, `leads/solvencia/${lead_id}`)
    .catch((e) => {
      Sentry.captureException(e);
    });
  if (response?.status === 200) {
    return {badScore: !response.data['scoring']};
  } 
  return {badScore: true};
};

export const checkLeadDNI = async (token, lead_id) => {
  const response = await data_fetch_api_resource(token, `leads/dni-validation/${lead_id}`)
    .catch((e) => {
      Sentry.captureException(e);
    });
  if (response?.status === 200) {
    return {dniInvalid: !response.data['dni_valid']};
  } 
  return {dniInvalid: true};
};

export const setAboutInstallationData = (data) => {
  
  let { leadsConsent, cups, address, residenceType, cnae, invoice, comerOrigin, activeContract } = data;
  if (residenceType || cnae === null) {
    cnae = { 
      id: 986, 
      name: "9820", 
      descripcio: "9820: Actividades de los hogares como productores de servicios para uso propio" 
    }
  }
  return {
    type: SUBMIT_ABOUT_INSTALLATION_DATA,
    payload: {
      leadsConsent,
      cups,
      address,
      residenceType,
      cnae,
      invoice,
      comerOrigin,
      activeContract
    },
  };
}

export const setAboutYouData = (data) => {
  return {
    type: SUBMIT_ABOUT_YOU_DATA,
    payload: data,
  };
}

export const setPower = (value) => {
  return {
    type: SET_POWER,
    payload: {
      power: value,
    }
  };
};

export const setPower2 = (value) => {
  return {
    type: SET_POWER2,
    payload: {
      power: value,
    }
  };
};

export const setPower3 = (value) => {
  return {
    type: SET_POWER3,
    payload: {
      power: value,
    }
  };
};

export const setPower4 = (value) => {
  return {
    type: SET_POWER4,
    payload: {
      power: value,
    }
  };
};

export const setPower5 = (value) => {
  return {
    type: SET_POWER5,
    payload: {
      power: value,
    }
  };
};

export const setPower6 = (value) => {
  return {
    type: SET_POWER6,
    payload: {
      power: value,
    }
  };
};

export const setActiveContract = (value) => {
  return {
    type: SET_ACTIVE_CONTRACT,
    payload: {
      activeContract: value,
    }
  };
};

export const setSelfcons = (value) => {
  return {
    type: SET_SELFCONS,
    payload: {
      selfcons: value,
    }
  };
};

export const updateResidenceType = (value) => {
  return {
    type: STORE_RESIDENCE_TYPE_DATA,
    payload: {
      residenceType: value
    }
  }
};

export const updateUploadedFiles = (value) => {
  return {
    type: STORE_UPLOADED_INVOICES_DATA,
    payload: {
      invoice: value
    }
  }
};

export const setSelfconsAttachment = (value) => {
  return {
    type: SET_SELFCONS_ATTACHMENT,
    payload: {
      selfconsAttachment: value,
    }
  };
};

export const setCoupon = (value) => {
  return {
    type: SET_COUPON,
    payload: {
      coupon: value,
      alreadyApplied: false
    }
  };
};

export const setAccessTariff = (value, name) => {
  return {
    type: SET_ACCESS_TARIFF,
    payload: {
      accessTariff: value,
      accessTariffName: name
    }
  };
};

export const setAboutContractData = ({
  power,
  power2,
  power3,
  power4,
  power5,
  power6,
  accessTariff,
  selectedProduct,
  selfcons,
  selfconsAttachment,
}) => {
  return {
    type: SUBMIT_ABOUT_CONTRACT_DATA,
    payload: {
      power,
      power2,
      power3,
      power4,
      power5,
      power6,
      accessTariff,
      selectedProduct,
      selfcons,
      selfconsAttachment,
    },
  };
};

export const submitAboutGasContractData = ({
  cupsGas,
  comerGasOrigin,
  selectedGasProduct,
}) => {
  return {
    type: SUBMIT_ABOUT_GAS_CONTRACT_DATA,
    payload: {
      cupsGas,
      comerGasOrigin,
      selectedGasProduct,
    },
  };
};

export const setPaymentData = ({ iban, isOwner }) => {
  return {
    type: SUBMIT_PAYMENT_DATA,
    payload: {
      iban,
      isOwner,
    },
  };
};

export const setConfirmData = ({
  promotionalCode,
  acceptOVConditions,
  acceptGdpr,
}) => {
  return {
    type: SUBMIT_CONFIRM_DATA,
    payload: {
      promotionalCode,
      acceptOVConditions,
      acceptGdpr,
    },
  };
};

// ----------------------------------------------------------------------------
// Contractation config (prefilled fields)
// ----------------------------------------------------------------------------

export const fetchContractationConfigRequest = () => {
  return {
    type: FETCH_CONTRACTATION_CONFIG_REQUEST,
  };
};

export const receiveContractationConfig = (response) => {
  return {
    type: RECEIVE_CONTRACTATION_CONFIG,
    payload: {
      contractationConfig: response,
    },
  };
};

export const fetchContractationConfig = () => {
  return async (dispatch) => {
    dispatch(fetchContractationConfigRequest());
    try {
      const json = await data_fetch_api_resource(null, `contractacio/config`);
      const response = await parseJSON(json);
      dispatch(receiveContractationConfig(response));
    } catch (e) {
      Sentry.captureException(e);
      if (
        Settings.electricityContractation &&
        Settings.newContract.authenticate &&
        e.status === 401
      ) {
        // dispatch(logoutAndRedirect(e));
      } else {
        dispatch(receiveContractationConfig(null));
      }
    }
  };
};

// ----------------------------------------------------------------------------
// CUPS Information
// ----------------------------------------------------------------------------

export const storeCUPSData = (data) => {
  return {
    type: STORE_CUPS_DATA,
    payload: {
      cups: data
    }
  }
}

export const setCupsAvailability = (type, data) => {
  return {
    type: type,
    payload: {
      ...data
    },
  };
}

export const validateCUPSAvailability = (values, keepSIPSData = false) => {
  return async (dispatch) => {
    await data_fetch_api_resource(
      null,
      `contractacio/config/cups/${values.cups}`
    ).then((result) => {
      let response = null;
      if (result && result.status === 200) {
        if (!_.get(result, 'data.cups_availability.available', false)) {
          dispatch(
            setCupsAvailability(SET_CUPS_INVALID, {
              invalid: true,
              reason: _.get(result, 'data.cups_availability.reason', 'Invalid CUPS')
            })
          );
        } else {
          dispatch(setCupsAvailability(SET_CUPS_VALID, {invalid: null, reason: ''}));
          if(keepSIPSData){
            dispatch(storeSIPSData({
              ...result.data,
            }));
          }
        }
      } else {
        response = i18n.t('common:text.cannot_validate_async');
      }

      return (response && { cups: response }) || null;
    })
  }
};

export const validateCoupon = (coupons) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_COUPON_VALID_REQUEST
    });
    // Converteix els cupons en un array si no ho són i treu-ne els duplicats
    const iteratableCoupons = [...new Set(Array.isArray(coupons) ? coupons : [coupons])]; 
    iteratableCoupons.forEach(async coupon => {
      if (!getState().electricityContractation.promotionalCode.includes(coupon)) {
        await data_fetch_api_resource(
          null,
          `leads/validate-coupon/${coupon}/`
        ).then((result) => {
          result.data?.valid ? 
          dispatch({type: SET_COUPON_VALID, payload: {code: coupon}})
          :
          dispatch({type: SET_COUPON_INVALID})
        });
      } else {
        dispatch({
          type: SET_COUPON_INVALID,
          payload: {
            alreadyApplied: true
          }
        })
      }
    });
  }
};

export const storeSIPSData = (data) => {
  return {
    type: STORE_SIPS_DATA,
    payload: {
      sipsData: data,
    },
  };
};

// ----------------------------------------------------------------------------
// Products
// ----------------------------------------------------------------------------

export const selectProduct = (product) => {
  return {
    type: SELECT_PRODUCT,
    payload: {
      product,
    },
  };
};

export const fetchAvailableProductsRequest = () => {
  return {
    type: FETCH_AVAILABLE_PRODUCTS_REQUEST,
  };
};

const getFixedProductNameByParam = () => {
  const urlParams = window.location.search;
  let fixedProductName;
  if (urlParams) {
    // Get URL parameters object
    const values = queryString.parse(urlParams);
    fixedProductName = values.tarifa;
  }
  return fixedProductName;
};

const filterAvailableProductsByUrlParam = (currentAvailableProducts) => {
  // If we receive a product name by URL parameter we will only show that
  // product.
  // If we don't have any product with that name, we won't show any
  // selectable product.
  const fixedProductName = getFixedProductNameByParam();
  if (!fixedProductName) {
    return currentAvailableProducts;
  }
  let filteredAvailableProducts;
  let productMatch = currentAvailableProducts.find(
    (t) => t.name === fixedProductName
  );
  if (productMatch) {
    // We only show one selectable product.
    filteredAvailableProducts = [productMatch];
  } else {
    // We don't show any product
    filteredAvailableProducts = [];
  }
  return filteredAvailableProducts;
};

export const receiveAvailableProducts = (response) => {
  const filteredProducts = filterAvailableProductsByUrlParam(response);
  return {
    type: RECEIVE_AVAILABLE_PRODUCTS,
    payload: {
      availableProducts: filteredProducts,
    },
  };
};

export const fetchAvailableProducts = (
  power,
  accessTariff,
  selfConsumption,
  cups,
  promotionalCode=false
) => {
  return async (dispatch) => {
    dispatch(fetchAvailableProductsRequest());
    try {
      const json = await data_fetch_api_resource(
        null,
        `pricelist?power=${power}&atr=${accessTariff.name || accessTariff}&selfcons=${selfConsumption || false}&cups=${cups}&promocode=${promotionalCode}`
      );
      const response = await parseJSON(json);
      dispatch(receiveAvailableProducts(response));
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
      if (
        Settings.electricityContractation &&
        Settings.newContract.authenticate &&
        e.status === 401
      ) {
        // dispatch(logoutAndRedirect(e));
      } else {
        dispatch(receiveAvailableProducts([]));
      }
    }
  };
};

export const confirmNewContractRequest = () => {
  return {
    type: NEW_ELECTRICITY_CONTRACT_CONFIRM_REQUEST,
  };
};

export const receiveConfirmNewContractResponse = (response) => {
  return {
    type: NEW_ELECTRICITY_CONTRACT_CONFIRM_RECEIVE,
    payload: {
      confirmResponse: response,
    },
  };
};

export const confirmNewContract = (postData, {currentStep, nextStep}) => {
  return async (dispatch) => {
    dispatch(confirmNewContractRequest());
    try {
      const json = await data_create_api_resource(
        null,
        "leads",
        postData
      );
      const response = await parseJSON(json);
      dispatch(receiveConfirmNewContractResponse(response));
      if (response.status === "OK") {
        dispatch(setLeadStepCompleted(currentStep));
        dispatch(setLeadCurrentStep(nextStep));
      }
    } catch (e) {
      Sentry.captureException(e);
      if (
        Settings.electricityContractation &&
        Settings.newContract.authenticate &&
        e.status === 401
      ) {
        // dispatch(logoutAndRedirect(e));
      } else {
        dispatch(receiveConfirmNewContractResponse({
          result: {
            error: true,
            render: {
              format: 'markdown',
              body: i18n.t('common:text.contractation_connectivity_error')
            }
          }
        }));
      }
    }      
  };
};
