import { Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import useTypes from "./useTypes";

const StringFilter = ({defaultInputType, resetValue, categoryName, handleFilterValueChange, handleCreate}) => {
  const { stringTypes: types } = useTypes();
  const [inputType, setInputType] = useState(types[defaultInputType]);
  const [innerValue, setInnerValue] = useState(types[defaultInputType].defaultValue);

  const handleValueChange = (newValue = innerValue, type = inputType) => {
    setInnerValue(newValue);
    handleFilterValueChange(newValue, type.render);
  }

  useEffect(() => {
    handleValueChange(inputType.defaultValue);
  }, [categoryName, resetValue]);

  return !resetValue ? (
    <Grid item xs>
      <TextField 
        label={inputType.title}
        fullWidth
        onChange={(event) => handleValueChange(event.target.value)}
        variant="outlined"
        size="small"
        value={innerValue}
        onKeyUp={(e) => {e.key === "Enter" && handleCreate()}}
      />
    </Grid>
  ) : <></>;
}

export default StringFilter;