import { Back } from "@gisce/oficina-virtual-components";
import { CouponsView } from "./CouponsView";
import { Stack, Box, PaperProps } from '@mui/material';
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import Settings from "@/settings";

interface ILayoutProvider {
	path: string;
  children: React.ReactNode;
}

// This provider checks the url and the current cookies for authentication
export const LayoutProvider = ({
	path,
  children
}: ILayoutProvider) => {
  const showCoupons = useSelector((state: RootState) => state.settings.data?.showCoupons);

  const customWrapperProps: PaperProps = {
    elevation: 5
  }

  const locationPaperOptions = {
    sm: {
      breakpoint:'sm', 
      maxWidth: 500,
      upBreakpointSx: {
        marginTop: 5,
        marginBottom: 3,
      }
    },
    publicityBanner: {
      downBreakpointSx: {
        padding: 3,
      },
      upBreakpointSx: {
        marginTop: 3,
      }
    },
    lg: {
      upBreakpointSx: {
        marginTop: 4,
        marginBottom: 3,
      }
    }
  }

  const hidePaper = [
    'common:url.altHome'
  ];

  const displayPaper = !(hidePaper.indexOf(path) > -1);

  const hideStackPadding = [
    'common:url.error'
  ];

  const displayStackPadding = !(hideStackPadding.indexOf(path) > -1);

  const smContainers = [
    'common:url.activate',
    'common:url.login',
    'common:url.changePassword',
    'common:url.resetPassword',
  ];

  const getPaperOptions = () => {
    const smPapers = [
      'common:url.activate',
      'common:url.login',
      'common:url.changePassword',
      'common:url.resetPassword',
    ];

    if (smPapers.indexOf(path) > -1) {
      return(locationPaperOptions.sm);
    } else {
      return(locationPaperOptions.lg);
    }
  }

  const isSmallContainer = smContainers.indexOf(path) > -1;

  const showCouponsPaths = [
    'common:url.contracts',
    'common:url.invoices'
  ];

  const displayCoupons = showCouponsPaths.indexOf(path) > -1 && showCoupons && !Settings.features?.disableCoupons;

  return <>
		{displayCoupons && <Back {...locationPaperOptions["publicityBanner"]} wrapperProps={customWrapperProps}><CouponsView /></Back>}
		{displayPaper && (
			<Back {...getPaperOptions()} wrapperProps={customWrapperProps}>
				{isSmallContainer ?
					<Box p={4}>
						{children}
					</Box>
				:
					<Box 
						sx={{
              ...(displayStackPadding && {padding: 4})
            }}
					>
						{children}
					</Box>
				}
			</Back>
		)}
		{!displayPaper && children}
	</>

	
}