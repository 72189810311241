import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from "../../config";
import Settings, { features } from "../../settings";

const LogoImg = () => {
  return <img
    src="/images/logoBar.png"
    style={{
      maxHeight: "90%",
    }}
    className={Settings?.header?.showUserDataMobile ? "hide-user-data" : "show-user-data"}
    alt="logo"
  />
}

export const LogoBar = (props) => {

  if (i18n.exists("common:links.logo")) {
    return (
      <a href={i18n.t("common:links.logo")} className="logo-bar">
        <LogoImg />
      </a>
    );
  } else {
    return (
      <div className="logo-bar">
        <LogoImg />
      </div>
    )
  }

};

LogoBar.propTypes = {
    openLinkInNewTab: PropTypes.bool,
};
