import { FC, useState } from "react";
import { useEffect } from "react";
import Settings from "../../settings";
import { useSelector } from "react-redux";
import { Button } from "@gisce/oficina-virtual-components";
import useServerElements from "./useServerElements";
import { useNavigate } from "react-router-dom";
import { RootState } from "@/store";
import { useTranslation } from "react-i18next";
import { INavigationLinkInfo } from "./types";

interface IInnerWrapper {
  children: React.ReactNode,
  linkInfo: INavigationLinkInfo;
  onClick: () => void;
  [key: string]: any; // Accepts additional props
}
interface IDinamicServerNavigationProps {
  clickableComponent?: FC<IInnerWrapper>;
  clickableProps?: object;
}

/**
 * Fetches all available navigation routes for the current user.
 * Each route navigates to a path relative to the specified base path (default is '/dynamic') 
 * appended with the received button path, for example: '/dynamic/<received-button-path>'.
 * 
 * Props:
 *   - ClickableComponent (default: 'Button'): Component used to render each clickable navigation button.
 *   - Props for ClickableComponent: Additional props to be passed to the ClickableComponent.
 *
**/
const DinamicServerNavigation: FC<IDinamicServerNavigationProps> = ({
  clickableComponent: ClickableComponent,
  clickableProps,
}) => {
  const { fetchServerActions } = useServerElements();
  const [links, setLink] = useState<INavigationLinkInfo[]>([]);
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (!ClickableComponent)
    ClickableComponent = (({children, ...rest}) => <Button variant="contained" {...rest}>{children}</Button>) as FC<IInnerWrapper>;
  if (!clickableProps)
    clickableProps = {};

  useEffect(() => {
    if (isAuthenticated && !links.length) {
      fetchServerActions().then((response) => {
        setLink(response as INavigationLinkInfo[]);
      })
    } else {
      setLink([]);
    }
  }, [isAuthenticated]);
  

  const drawedMenuItems = links.map((l) => {
    return (
      <ClickableComponent
        key={l.id}
        {...clickableProps}
        onClick={() => navigate(t("common:url.dynamic", {
          routePath: l.routePath.replace("/", ""), pageId: l.id
        }))}
        title={l.btnDescription}
        linkInfo={l}
      >
        {l.btnTitle}
      </ClickableComponent>
    )
  })

  return drawedMenuItems;
}

export default DinamicServerNavigation;
