import {
  Popover, Switch, FormControlLabel,
  Badge, FormControl, InputLabel, Select,
  Card, CardActions, CardMedia, TextField,
  MenuItem, Box, Typography, IconButton,
  Paper,
  SelectChangeEvent
} from "@mui/material";
import { Help } from "@mui/icons-material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import theme from "../OverrideableContent/theme";
import { IApplianceBase } from "./custom/config";

export interface IAppliance extends IApplianceBase {
  enabled: boolean;
  num: number;
  power: number;
  totalPower: number;
}

interface IApplianceProps extends IApplianceBase {
  area: number;
  cofSimul: number;
  onChange: (props: IAppliance) => void;
}

const Appliance: FC<IApplianceProps> = ({
  options, areaDependent, area=0, 
  base, name, img, cofSimulDependent, 
  cofSimul, onChange, hint, 
  optionsLabel, multiple, multipleLabel
}) => {
  const { i18n, t } = useTranslation();

  const [enabled, setEnabled] = useState(false);
  const [num, setNum] = useState<number>(areaDependent ? area : 1);
  const [power, setPower] = useState<number>(options?.[0]?.power ?? 0);
  const [totalPower, setTotalPower] = useState(0);
  const [popOverOpen, setPopOverOpen] = useState(false);
  const [popOverAnchor, setPopOverAchor] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (areaDependent && area) {
      setNum(area);
    }
  }, [areaDependent, area]);

  useEffect(() => {
    if (enabled) {
      recalcTotalPower();
    } else if (totalPower !== 0) {
      setTotalPower(0);
    }
  }, [areaDependent, area, num, power, enabled, cofSimul]);

  useEffect(() => {
    onChange({enabled, num, power, name, img, totalPower, multiple});
  }, [enabled, totalPower])

  const recalcTotalPower = () => {
    const newTotalPower = Math.round((base || 0) + (
      num * power * 100 * (cofSimulDependent ? cofSimul : 1))
    ) / 100;
    setTotalPower(newTotalPower);
  }

  const handleChangeNum = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNum(Number(event.target.value));
  }

  const handleChangePower = (power: SelectChangeEvent) => {
    setPower(Number(power.target.value));
  }

  const handleToggle = (value: boolean) => {
    setEnabled(value);
  }

  return (
    <Card
      style={{
        opacity: enabled ? 1 : 0.5,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box p={2}>
        <Typography variant="h6">{t(name)}</Typography>
      </Box>
      <CardMedia sx={{ margin: theme.spacing(4, 4, 0, 4), flexGrow: 2 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <div style={{ alignSelf: "flex-end" }}>
            <FormControlLabel
              labelPlacement="start"
              label="" //TODO: Check this
              style={{ marginLeft: 0, marginRight: 0 }}
              control={
                <Switch
                  checked={enabled}
                  onChange={(event, value) => handleToggle(value)}
                />
              }
            />
            {hint && (
              <IconButton
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  setPopOverOpen(true);
                  setPopOverAchor(event.currentTarget);
                }}
              ><Help /></IconButton>
            )}
            {hint && (
              <Popover
                anchorEl={popOverAnchor}
                open={popOverOpen}
                onClose={() => setPopOverOpen(false)}
              >
                <Paper sx={{ padding: 2, maxWidth: 300 }}>
                  {t(hint)}
                </Paper>
              </Popover>
            )}
          </div>
          <div style={{alignSelf: 'flex-end'}}>
            {enabled && optionsLabel && (
              <FormControl style={{ width: 150 }}>
                <InputLabel shrink htmlFor="">
                  {optionsLabel}
                </InputLabel>
                <Select
                  onChange={handleChangePower}
                  value={power.toString()}
                >
                  {options?.map(opt => <MenuItem 
                    key={opt.name} value={opt.power}>{opt.name}</MenuItem>)}
                </Select>
              </FormControl>
            )}
            {enabled && (
              <div>
                {multiple && (
                  <TextField
                    label={multipleLabel || t("common:text.contracts_quantity")}
                    type="number"
                    style={{ width: 150 }}
                    InputProps={{
                      readOnly: !enabled,
                      inputProps: {
                        min: 1
                      }
                    }}
                    value={num}
                    onChange={handleChangeNum}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </CardMedia>
      <CardActions sx={{ flexGrow: 2 }}>
        <Badge
          badgeContent={`${totalPower.toLocaleString(i18n.language)} kW`}
          color={"primary"}
          sx={{ alignSelf: "flex-end" }}
        >
          <img
            src={img}
            style={{ width: 100 }}
            onClick={() => handleToggle(!enabled)}
          />
        </Badge>
      </CardActions>
    </Card>
  );
}

export default Appliance;
