import { Box, Chip, Grid, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { i18n } from "../../../../config";
import BatteryDistributionCard from "./BatteryDistributionCard";

export const BatteryDistributionAfter = ({ batteryDistribution }) => {
  return (
    <Grid container style={{ position: "relative" }}>
      {/* {( */}
      {/*   <Box */}
      {/*     style={{ */}
      {/*       position: "absolute", */}
      {/*       right: "0px", */}
      {/*       top: "0", */}
      {/*     }} */}
      {/*   > */}
      {/*     <Chip */}
      {/*       icon={<CheckCircleIcon />} */}
      {/*       label={i18n.t("common:text.battery_origin_warning_after")} */}
      {/*       color="primary" */}
      {/*     /> */}
      {/*   </Box> */}
      {/* )} */}

      <BatteryDistributionCard batteryDistribution={batteryDistribution} />
    </Grid>
  );
};

export default BatteryDistributionAfter;
